import React from "react";
import queryString from "query-string";
import { userService } from "services/user.service";

class BookmarkRedirect extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    userService.isLoggedIn().then(loggedIn => {
      if (loggedIn) {
        const values = queryString.parse(this.props.location.search);
        const qsValues = toLowerCaseKeys(values);

        if (qsValues.topfolder != null) {
          let topFolder = atob(qsValues.topfolder);
          topFolder = topFolder
            .replace("\\\\", "")
            .split("\\")
            .join("/");
          delete qsValues.topfolder;
          delete qsValues.session;
          const newQS = queryString.stringify(toUpperCaseKeys(qsValues));
          this.props.history.push("/displays/" + topFolder + "?" + newQS);
        } else {
          if (qsValues.path != null) {
            let target = atob(qsValues.target);
            target = target
              .replace("\\\\", "")
              .split("\\")
              .join("/");
            delete qsValues.session;
            delete qsValues.type;
            delete qsValues.path;
            delete qsValues.target;
            const newQS = queryString.stringify(toUpperCaseKeys(qsValues));
            this.props.history.push("/display/" + target + "?" + newQS);
          }
        }
      } else {
        const path = this.props.location.pathname;
        const values = queryString.parse(this.props.location.search);
        const qsValues = toLowerCaseKeys(values);
        delete qsValues.session;
        const newQS = queryString.stringify(toUpperCaseKeys(qsValues));
        this.props.history.push("/signin?ReturnUrl=" + path + "?" + newQS);
      }

      function toLowerCaseKeys(obj) {
        return Object.keys(obj).reduce(function(accum, key) {
          accum[key.toLowerCase()] = obj[key];
          return accum;
        }, {});
      }

      function toUpperCaseKeys(obj) {
        return Object.keys(obj).reduce(function(accum, key) {
          accum[key.toUpperCase()] = obj[key];
          return accum;
        }, {});
      }
    });
  }

  render() {
    return <span />;
  }
}

export default BookmarkRedirect;
