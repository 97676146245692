const serverError = "SERVER_ERROR";
const unauthorizedError = "UNAUTHORIZED_ERROR";
const updateUnauthorizedDisplayed = "UPDATE_UNAUTHORIZED_DISPLAYED";
const updateServerErrorDisplayed = "UPDATE_SERVER_ERROR_DISPLAYED";

const initialState = {
  serverErrors: [],
  lastServerErrorDisplayed: null,
  unauthorizedErrors: 0,
  lastUnauthorizedDisplayed: null
};

export const actionCreators = {
  serverError: text => ({ type: serverError, text }),
  unauthorizedError: () => ({ type: unauthorizedError }),
  updateUnauthorizedDisplayed: () => ({ type: updateUnauthorizedDisplayed }),
  updateServerErrorDisplayed: () => ({ type: updateServerErrorDisplayed })
};

export const reducer = (state, action) => {
  state = state || initialState;

  if (action.type === serverError) {
    var errors = state.serverErrors.slice(0);
    errors.push(action.text + " - Check Server Logs for more details.");
    return { ...state, serverErrors: errors };
  }

  if (action.type === unauthorizedError) {
    return { ...state, unauthorizedErrors: state.unauthorizedErrors + 1 };
  }
  if (action.type === updateUnauthorizedDisplayed) {
    return { ...state, lastUnauthorizedDisplayed: Date.now() };
  }
  if (action.type === updateServerErrorDisplayed) {
    return { ...state, lastServerErrorDisplayed: Date.now() };
  }

  return state;
};
