import { authHeader } from "helpers";
import { stringify } from "query-string";
import { fetchService } from "services/fetch.service";
import { userService } from "services/user.service";
const baseUrl = process.env.PUBLIC_URL + "/api";


export class displayService {
    constructor() {
        this.isRestricted = false;
    }

    setRestricted(restrictedType, restrictedId) {
        this.isRestricted = true;
        this.restrictedType = restrictedType;
        this.restrictedId = restrictedId;
        return this;
    }
    pathInboundFilter(path) {
        if (path !== undefined && path !== "") {
            return path.replace("‰", "%");
        } else {
            return path;
        }
    }

    async getDisplay(sessionId, displayId, width, height) {

        const params = {
            sessionId: sessionId,
            displayId: displayId ? displayId : sessionId,
            width: width,
            height: height
        };


        const requestOptions = {
            method: "Get",
            headers: await authHeader()
        };

        return fetchService
            .fetchWithErrorHandling(
                `${baseUrl}/display/GetFullFrameAfterRefresh?${stringify(params)}`,
                requestOptions
            )
            .then(handleResponse);

        function handleResponse(response) {
            if (response === "error") {
                return Promise.resolve(false);
            }
            return response.arrayBuffer().then(arrayBuffer => {
                if (arrayBuffer) {
                    var b64encoded = btoa(
                        new Uint8Array(arrayBuffer).reduce(function (data, byte) {
                            return data + String.fromCharCode(byte);
                        }, "")
                    );
                    var mimetype = "image/png"; // or whatever your image mime type is
                    return "data:" + mimetype + ";base64," + b64encoded;
                }
            }); // Note: not oReq.responseText
        }
    }
    async getLatestVerionNumber(sessionId, displayId, width, height) {
        let params = {
            sessionId: sessionId,
            displayId: displayId ? displayId : sessionId,
            width: width,
            height: height
        };
        const requestOptions = {
            method: "Get",
            headers: await authHeader()
        };

        return await fetchService
            .fetchWithErrorHandling(
                `${baseUrl}/display/GetLatestVersionNumber?${stringify(params)}`,
                requestOptions
            )
            .then(handleResponse);

        function handleResponse(response) {
            if (response === "error") {
                userService.logout();
                return Promise.resolve(false);
            }
            return response.json().then(json => {
                return json; 
            });
        }
    }

    async clearImageVersionUrl(displayId) {
        let params = {
            displayId: displayId 
        };
        const requestOptions = {
            method: "Get",
            headers: await authHeader()
        };

        return await fetchService
            .fetchWithErrorHandling(
                `${baseUrl}/display/ClearImageVersionUrl?${stringify(params)}`,
                requestOptions
            )
            .then(handleResponse);

        function handleResponse(response) {
            if (response === "error") {
                return Promise.resolve(false);
            }
            return response.text().then(text => {
                return text;
            });
        }
    }
    async performMouseDown(x,y,displayId) {
        let params = {
            x: x,
            y: y,
            displayId: displayId
        };
        const requestOptions = {
            method: "Get",
            headers: await authHeader()
        };

        return await fetchService
            .fetchWithErrorHandling(
                `${baseUrl}/display/PerformMouseDown?${stringify(params)}`,
                requestOptions
            )
            .then(handleResponse);

        function handleResponse(response) {
            if (response === "error") {
                return Promise.resolve(false);
            }
            return response.text().then(text => {
                return text;
            });
        }
    }
    async initDisplay(filename, width, height, sessionId) {
        filename = this.pathInboundFilter(filename);
        const requestOptions = {
           method: "Get",
           headers: await authHeader()
        };

        if (!this.isRestricted) {
           let params = {
               fileName: filename,
               width: width,
               height: height,
               sessionId: sessionId
           };

           return await fetchService
               .fetchWithErrorHandling(
                   `${baseUrl}/display/InitDisplay?${stringify(params)}`,
                   requestOptions,
                   false
               )
               .then(handleResponse);
        }
        if (this.restrictedType === "File") {
           let params = {
               restrictedFileId: this.restrictedId,
               width: width,
               height: height,
               sessionId: sessionId
           };
           return await fetchService
               .fetchWithErrorHandling(
                   `${baseUrl}/display/InitRestrictedDisplayFromFile?${stringify(
                       params
                   )}`,
                   requestOptions,
                   false
               )
               .then(handleResponse);
        }
        if (this.restrictedType === "Folder") {
           let params = {
               restrictedFolderId: this.restrictedId,
               relativePath: filename,
               width: width,
               height: height,
               sessionId: sessionId
           };
           return await fetchService
               .fetchWithErrorHandling(
                   `${baseUrl}/display/InitRestrictedDisplayFromFolder?${stringify(
                       params
                   )}`,
                   requestOptions,
                   false
               )
               .then(handleResponse);
        }

        function handleResponse(response) {
           if (response === "error") {
               userService.logout();
           }
           return response.text().then(text => {
               const data = text;
               if (!response.ok) {                    
                   const error = (data && data.message) || response.statusText;
                   return Promise.reject(error);
               }

               return data;
           });
        }
    }
    async initClone(tags, width, height, sessionId) {
        const params = {
            tags: tags,
            width: width,
            height: height,
            sessionId: sessionId
        };

        const requestOptions = {
            method: "Get",
            headers: await authHeader()
        };
        return fetchService
            .fetchWithErrorHandling(
                `${baseUrl}/display/InitClone?${stringify(params)}`,
                requestOptions
            )
            .then(handleResponse);
        function handleResponse(response) {
            return response.text().then(text => {
                const data = text;
                if (!response.ok) {
                    const error = (data && data.message) || response.statusText;
                    return Promise.reject(error);
                }

                return data;
            });
        }
    }
    async performMouseClick(x, y, sessionId, displayId) {
        let params = {
            x: x,
            y: y,
            sessionId: sessionId,
            displayId: displayId ? displayId : sessionId,
        };

        const requestOptions = {
            method: "Get",
            headers: await authHeader()
        };
        return fetchService
            .fetchWithErrorHandling(
                `${baseUrl}/display/PerformMouseClick?${stringify(params)}`,
                requestOptions
            )
            .then(handleResponse);
        function handleResponse(response) {
            return response.text().then(text => {
                const data = text;
                if (!response.ok) {
                    const error = (data && data.message) || response.statusText;
                    return Promise.reject(error);
                }

                return data;
            });
        }
    }
    async GetTagValuesAtPosition(x, y, sessionId, displayId) {

        let params = {
            x: x,
            y: y,
            sessionId: sessionId,
            displayId: displayId ? displayId : sessionId,
        };

        const requestOptions = {
            method: "Get",
            headers: await authHeader()
        };

        return fetchService.fetchWithErrorHandling(
            `${baseUrl}/display/GetTagValuesAtPosition?${stringify(params)}`,
            requestOptions).then(handleResponse);

        function handleResponse(response) {
            return response.text().then(text => {
                const data = text;
                if (!response.ok) {
                    const error = (data && data.message) || response.statusText;
                    return Promise.reject(error);
                }
                return data;
            });
        }

    }
    async EditTrendLayout(x, y, rows, cols, sessionId, displayId) {

        let params = {
            x: x,
            y: y,
            rows: rows,
            cols: cols,
            sessionId: sessionId,
            displayId: displayId ? displayId : sessionId,
        };

        const requestOptions = {
            method: "Get",
            headers: await authHeader()
        };

        return fetchService.fetchWithErrorHandling(
            `${baseUrl}/display/EditTrendLayout?${stringify(params)}`,
            requestOptions).then(handleResponse);

        function handleResponse(response) {
            return response.text().then(text => {
                const data = text;
                if (!response.ok) {
                    const error = (data && data.message) || response.statusText;
                    return Promise.reject(error);
                }
                return data;
            });
        }
    }
    async ExportTagData(start, end, step, tags, includeUTag, includeValue, includeQuality, includeTimestamp, sessionId, displayId) {
        let params = {
            tags: tags,
            start: start,
            end: end,
            step: step,
            includeUTag: includeUTag,
            includeValue: includeValue,
            includeQuality: includeQuality,
            includeTimestamp: includeTimestamp,
            sessionId: sessionId,
            displayId: displayId ? displayId : sessionId,
        };

        const requestOptions = {
            method: "Get",
            headers: await authHeader()
        };

        return fetchService.fetchWithErrorHandling(
                `${baseUrl}/display/ExportTagData?${stringify(params)}`,
            requestOptions).then(handleResponse);

        function handleResponse(response) {
            return response.text().then(text => {
                const data = text;
                if (!response.ok) {
                    const error = (data && data.message) || response.statusText;
                    return Promise.reject(error);
                }

                return data;
            });
        }
    }
    async ModifyTrendTags(x, y, tag, remove, sessionId, displayId) {
        let params = {
            x: x,
            y: y,
            tag: tag,
            remove: remove,
            sessionId: sessionId,
            displayId: displayId ? displayId : sessionId,
        };

        const requestOptions = {
            method: "Get",
            headers: await authHeader()
        };
        return fetchService
            .fetchWithErrorHandling(
                `${baseUrl}/display/ModifyTrendTags?${stringify(params)}`,
                requestOptions
            )
            .then(handleResponse);
        function handleResponse(response) {
            return response.text().then(text => {
                const data = text;
                if (!response.ok) {
                    const error = (data && data.message) || response.statusText;
                    return Promise.reject(error);
                }

                return data;
            });
        }
    }
    async GetTagInfo(tag, sessionId, displayId) {
        let params = {
            tag: tag,
            sessionId: sessionId,
            displayId: displayId ? displayId : sessionId,
        };

        const requestOptions = {
            method: "Get",
            headers: await authHeader()
        };
        return fetchService
            .fetchWithErrorHandling(
                `${baseUrl}/display/GetTagInfo?${stringify(params)}`,
                requestOptions
            )
            .then(handleResponse);
        function handleResponse(response) {
            return response.text().then(text => {
                const data = text;
                if (!response.ok) {
                    const error = (data && data.message) || response.statusText;
                    return Promise.reject(error);
                }

                return data;
            });
        }
    }
    async MouseWheelZoom(x, y, zoom, sessionId, displayId) {
        let params = {
            x: x,
            y: y,
            amount: zoom,
            sessionId: sessionId,
            displayId: displayId ? displayId : sessionId,
        };

        const requestOptions = {
            method: "Get",
            headers: await authHeader()
        };
        return fetchService
            .fetchWithErrorHandling(
                `${baseUrl}/display/MouseWheelZoom?${stringify(params)}`,
                requestOptions
            )
            .then(handleResponse);
        function handleResponse(response) {
            return response.text().then(text => {
                const data = text;
                if (!response.ok) {
                    const error = (data && data.message) || response.statusText;
                    return Promise.reject(error);
                }

                return data;
            });
        }
    }
    async Pan(x1, y1, x2, y2, sessionId, displayId) {

        let params = {
            x1: x1,
            y1: y1,
            x2: x2,
            y2: y2,
            sessionId: sessionId,
            displayId: displayId ? displayId : sessionId,
        };

        const requestOptions = {
            method: "Get",
            headers: await authHeader()
        };

        return fetchService
            .fetchWithErrorHandling(
                `${baseUrl}/display/Pan?${stringify(params)}`,
                requestOptions
            )
            .then(handleResponse);
        function handleResponse(response) {
            return response.text().then(text => {
                const data = text;
                if (!response.ok) {
                    const error = (data && data.message) || response.statusText;
                    return Promise.reject(error);
                }

                return data;
            });
        }
    }
    async Zoom(x1, y1, x2, y2, sessionId, displayId) {
        let params = {
            x1: x1,
            y1: y1,
            x2: x2,
            y2: y2,
            sessionId: sessionId,
            displayId: displayId ? displayId : sessionId,
        };

        const requestOptions = {
            method: "Get",
            headers: await authHeader()
        };
        return fetchService
            .fetchWithErrorHandling(
                `${baseUrl}/display/Zoom?${stringify(params)}`,
                requestOptions
            )
            .then(handleResponse);
        function handleResponse(response) {
            return response.text().then(text => {
                const data = text;
                if (!response.ok) {
                    const error = (data && data.message) || response.statusText;
                    return Promise.reject(error);
                }

                return data;
            });
        }
    }
    async Sync(x, y, sessionId, displayId) {
        let params = {
            x: x,
            y: y,
            sessionId: sessionId,
            displayId: displayId ? displayId : sessionId,
        };

        const requestOptions = {
            method: "Get",
            headers: await authHeader()
        };
        return fetchService
            .fetchWithErrorHandling(
                `${baseUrl}/display/Sync?${stringify(params)}`,
                requestOptions
            )
            .then(handleResponse);
        function handleResponse(response) {
            return response.text().then(text => {
                const data = text;
                if (!response.ok) {
                    const error = (data && data.message) || response.statusText;
                    return Promise.reject(error);
                }

                return data;
            });
        }

    }
    async ToggleAutoScaleAll(x, y, sessionId, displayId) {
        let params = {
            x: x,
            y: y,
            sessionId: sessionId,
            displayId: displayId ? displayId : sessionId,
        };

        const requestOptions = {
            method: "Get",
            headers: await authHeader()
        };
        return fetchService
            .fetchWithErrorHandling(
                `${baseUrl}/display/ToggleAutoScaleAll?${stringify(params)}`,
                requestOptions
            )
            .then(handleResponse);
        function handleResponse(response) {
            return response.text().then(text => {
                const data = text;
                if (!response.ok) {
                    const error = (data && data.message) || response.statusText;
                    return Promise.reject(error);
                }

                return data;
            });
        }
    }
    async ToggleValueTextAll(x, y, sessionId, displayId) {
        let params = {
            x: x,
            y: y,
            sessionId: sessionId,
            displayId: displayId ? displayId : sessionId,
        };

        const requestOptions = {
            method: "Get",
            headers: await authHeader()
        };
        return fetchService
            .fetchWithErrorHandling(
                `${baseUrl}/display/ToggleValueTextAll?${stringify(params)}`,
                requestOptions
            )
            .then(handleResponse);
        function handleResponse(response) {
            return response.text().then(text => {
                const data = text;
                if (!response.ok) {
                    const error = (data && data.message) || response.statusText;
                    return Promise.reject(error);
                }

                return data;
            });
        }
    }
    async ToggleShowDotsAll(x, y, sessionId, displayId) {
        let params = {
            x: x,
            y: y,
            sessionId: sessionId,
            displayId: displayId ? displayId : sessionId,
        };

        const requestOptions = {
            method: "Get",
            headers: await authHeader()
        };
        return fetchService
            .fetchWithErrorHandling(
                `${baseUrl}/display/ToggleShowDotsAll?${stringify(params)}`,
                requestOptions
            )
            .then(handleResponse);
        function handleResponse(response) {
            return response.text().then(text => {
                const data = text;
                if (!response.ok) {
                    const error = (data && data.message) || response.statusText;
                    return Promise.reject(error);
                }

                return data;
            });
        }
    }
    async ToggleBadQualityAll(x, y, sessionId, displayId) {
        let params = {
            x: x,
            y: y,
            sessionId: sessionId,
            displayId: displayId ? displayId : sessionId,
        };

        const requestOptions = {
            method: "Get",
            headers: await authHeader()
        };
        return fetchService
            .fetchWithErrorHandling(
                `${baseUrl}/display/ToggleBadQualityAll?${stringify(params)}`,
                requestOptions
            )
            .then(handleResponse);
        function handleResponse(response) {
            return response.text().then(text => {
                const data = text;
                if (!response.ok) {
                    const error = (data && data.message) || response.statusText;
                    return Promise.reject(error);
                }

                return data;
            });
        }
    }
    async ToggleQuickStatistics(x, y, sessionId, displayId) {
        let params = {
            x: x,
            y: y,
            sessionId: sessionId,
            displayId: displayId ? displayId : sessionId,
        };

        const requestOptions = {
            method: "Get",
            headers: await authHeader()
        };
        return fetchService
            .fetchWithErrorHandling(
                `${baseUrl}/display/ToggleQuickStatistics?${stringify(params)}`,
                requestOptions
            )
            .then(handleResponse);
        function handleResponse(response) {
            return response.text().then(text => {
                const data = text;
                if (!response.ok) {
                    const error = (data && data.message) || response.statusText;
                    return Promise.reject(error);
                }

                return data;
            });
        }
    }
    async AutoSync(x, y, sessionId, displayId) {
        let params = {
            x: x,
            y: y,
            sessionId: sessionId,
            displayId: displayId ? displayId : sessionId,
        };

        const requestOptions = {
            method: "Get",
            headers: await authHeader()
        };
        return fetchService
            .fetchWithErrorHandling(
                `${baseUrl}/display/AutoSync?${stringify(params)}`,
                requestOptions
            )
            .then(handleResponse);
        function handleResponse(response) {
            return response.text().then(text => {
                const data = text;
                if (!response.ok) {
                    const error = (data && data.message) || response.statusText;
                    return Promise.reject(error);
                }

                return data;
            });
        }
    }
    async CreateStickyMouse(x, y, sessionId, displayId) {
        let params = {
            x: x,
            y: y,
            sessionId: sessionId,
            displayId: displayId ? displayId : sessionId,
        };

        const requestOptions = {
            method: "Get",
            headers: await authHeader()
        };
        return fetchService
            .fetchWithErrorHandling(
                `${baseUrl}/display/CreateStickyMouse?${stringify(params)}`,
                requestOptions
            )
            .then(handleResponse);
        function handleResponse(response) {
            return response.text().then(text => {
                const data = text;
                if (!response.ok) {
                    const error = (data && data.message) || response.statusText;
                    return Promise.reject(error);
                }

                return data;
            });
        }
    }
    async HitTest(x, y, sessionId, displayId) {
        let params = {
            x: x,
            y: y,
            sessionId: sessionId,
            displayId: displayId ? displayId : sessionId,
        };

        const requestOptions = {
            method: "Get",
            headers: await authHeader()
        };
        return fetchService
            .fetchWithErrorHandling(
                `${baseUrl}/display/HitTest?${stringify(params)}`,
                requestOptions
            )
            .then(handleResponse);
        function handleResponse(response) {
            return response.text().then(text => {
                const data = text;
                if (!response.ok) {
                    const error = (data && data.message) || response.statusText;
                    return Promise.reject(error);
                }

                return data;
            });
        }
    }
    async SetTimespan(x, y, sessionId, displayId, timespan) {
        let params = {
            x: x,
            y: y,
            sessionId: sessionId,
            displayId: displayId ? displayId : sessionId,
            timespan: timespan,
        };

        const requestOptions = {
            method: "Get",
            headers: await authHeader()
        };
        return fetchService
            .fetchWithErrorHandling(
                `${baseUrl}/display/SetTimespan?${stringify(params)}`,
                requestOptions
            )
            .then(handleResponse);
        function handleResponse(response) {
            return response.text().then(text => {
                const data = text;
                if (!response.ok) {
                    const error = (data && data.message) || response.statusText;
                    return Promise.reject(error);
                }

                return data;
            });
        }

    }
    async SetDateRange(x, y, sessionId, displayId, start, end) {
        let params = {
            x: x,
            y: y,
            sessionId: sessionId,
            displayId: displayId ? displayId : sessionId,
            start: start,
            end: end
        };

        const requestOptions = {
            method: "Get",
            headers: await authHeader()
        };
        return fetchService
            .fetchWithErrorHandling(
                `${baseUrl}/display/SetDateRange?${stringify(params)}`,
                requestOptions
            )
            .then(handleResponse);

        function handleResponse(response) {
            return response.text().then(text => {
                const data = text;
                if (!response.ok) {
                    const error = (data && data.message) || response.statusText;
                    return Promise.reject(error);
                }

                return data;
            });
        }

    }
    
    async GetPVLaunchGuid(filename, sessionId, displayId) {

        let params = {
            filename: filename,
            sessionId: sessionId,
            displayId: displayId ? displayId : sessionId,
        };

        const requestOptions = {
            method: "Get",
            headers: await authHeader()
        };

        return fetchService.fetchWithErrorHandling(
            `${baseUrl}/display/GetPVLaunchGuid?${stringify(params)}`, requestOptions)
            .then(handleResponse);

        function handleResponse(response) {
            return response.text().then(text => {
                const data = text;
                if (!response.ok) {
                    const error = (data && data.message) || response.statusText;
                    return Promise.reject(error);
                }

                return data;
            });
        }
    }
}
