import React from "react";
import { connect } from "react-redux";

class OnlyLoggedOut extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      children:
        !this.props.user &&
        this.props.serverSettings &&
        !this.props.serverSettings.hasDefaultLogin
          ? this.props.children
          : ""
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.user !== this.props.user) {
      this.setState({
        children:
          !this.props.user &&
          this.props.serverSettings &&
          !this.props.serverSettings.hasDefaultLogin
            ? this.props.children
            : ""
      });
    }
  }

  render() {
    return <React.Fragment>{this.state.children}</React.Fragment>;
  }
}

export default connect(store => {
  const { user, serverSettings } = store;
  return { ...user, ...serverSettings };
})(OnlyLoggedOut);
