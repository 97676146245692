import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import tagBrowserPageStyle from "assets/jss/material-kit-react/views/tagBrowserPage.jsx";
import { tagService } from "services/tag.service";
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import TagGrid from "components/TagGrid/TagGrid.jsx"
import TagBrowserSection from "components/TagBrowserSection/TagBrowserSection.jsx"
import { TagBrowserTabs } from "helpers/enums.js"
import { userService } from "services/user.service";
import { withTranslation } from "react-i18next";

class TagBrowserPage extends React.Component {

    constructor(props) {
        super(props);

        this.tagService = new tagService();

        this.state = {
            selectedTab: TagBrowserTabs.Source,
            location: '',
            source: '',
            sourceName: '',
            filterColumn: true,
            filterRegex: false,
            isBusy: false,
            tags: [],
            selectedTags: [],
            pageSize: 100,
            moreAvailable: false,
            id: 0,
            locations: [],
            locationsLoading: true,
            sources: [],
            sourcesLoading: true,
        }
    }

    async componentDidMount()
    {
        setInterval(async () => {
            var loggedIn = await userService.isLoggedIn();
            if (!loggedIn) {
                window.close();
            }
        }, 1000);

        this.setState({
            locationsLoading: true,
        }, async (s) => {

            var result = await this.tagService.getLocations();
            var olocations = JSON.parse(result);
            var locations = [];

            var dLocation = undefined;
            for (var location of olocations) {
                var name = location;
                if (location.endsWith("|**")) {
                    name = location.replace("|**", "");
                    dLocation = name;
                }

                locations.push(name);
            }

            this.setState({
                location: dLocation,
                locations: locations,
                locationsLoading: false,
                sourcesLoading: true,
            }, async (s) => {

                if (dLocation) {
                    var result = await this.tagService.getSources(dLocation);
                    var sources = JSON.parse(result);

                    this.setState({
                        sources: sources,
                        sourcesLoading: false,
                    });
                }
            });

            this.setState({ loaded: true });

        });
    }

    locationChanged = async function (location) {
        this.setState({
            location: location,
            source: undefined,
        },
         async (s) => {

             this.setState({
                 sourcesLoading: true,
             }, async (s) => {
                 var result = await this.tagService.getSources(location);
                 var sources = JSON.parse(result);

                 this.setState({
                     sources: sources,
                     sourcesLoading: false,
                 });
             });

            this.updateTags(location)
        });
    }.bind(this);

    sourceChanged = async function (source, sourceName) {
        this.setState({
            source: source,
            sourceName: sourceName,
        }, (s) => this.updateTags(this.state.location, source));

    }.bind(this);

    onFilterChecked = function (e) {
        this.setState({ [e.name]: e.checked });
    }.bind(this);

    updateTags = async function (location, source, search) {

        this.setState({
            isBusy: true,
            tags:[]
        }, async (s) => {

            var result = null;
            switch (this.state.selectedTab) {
                case TagBrowserTabs.Master:
                    if (location) {
                        result = await this.getTags(location, "", 0, this.state.pageSize, search, false);
                    }
                    break;
                case TagBrowserTabs.Source:
                    if (location && source) {
                        result = await this.getTags(location, source, 0, this.state.pageSize, search, false);
                    }
                    break;
            }

            var tags = (result && result.tags) ? result.tags.filter(t => t != null) : [];

            this.setState({
                tags: result ? tags : [],
                skip: result ? tags.length : 0,
                moreAvailable: result ? result.tags.length == this.state.pageSize : false,
                id: result ? result.id : 0,
                isBusy: false
            });
        });

    }.bind(this);

    getTags = async function (location, source, skip, take, search, useRegex) {
        var tags = [];

        var id = 0;

        try {
            var response = await this.tagService.getTags(location, source, skip, take, search, useRegex, id);
            if (response) {
                var result = JSON.parse(response);
                tags = tags.concat(result.tags);
                skip = skip + result.tags.length;
                id = result.id;
            }
        } catch (ex) {
            console.log(ex);
        }
        
        return {
            tags: tags,
            id: id,
            skip: skip
        };

    }.bind(this);

    onScrollEnd = async function (e) {

        if (this.state.moreAvailable) {

            this.setState({
                isBusy: true
            }, async (s) => {

                var result = null;
                if (this.state.selectedTab == TagBrowserTabs.Master) {
                    if (this.state.location) {
                        result = await this.getTags(
                            this.state.location, "", this.state.skip, this.state.pageSize, "", false);
                    }
                }

                this.setState({
                    tags: result ? this.state.tags.concat(result.tags) : this.state.tags,
                    skip: result ? result.skip : 0,
                    moreAvailable: result ? (result.tags.length == this.state.pageSize) : false,
                    id: result ? result.id : 0,
                    isBusy: false
                });
            });
        }

    }.bind(this);

    onTagSelectionChanged = function (tag, selected) {
        if (selected) {
            this.state.selectedTags.push(tag);
        } else {
            const index = this.state.selectedTags.indexOf(tag);
            if (index > -1) {
                this.state.selectedTags.splice(index, 1);
            }
        }

        this.setState({
            selectedTags: this.state.selectedTags
        });
    }.bind(this);

    onRowDoubleClicked = function (row) {

        var trendInfo = {            
            tags: [{
                uTag: row.uTag,
                plotMin: row.plotMin,
                plotMax: row.plotMax,
                autoScale: true
            }],
        }

        var json = JSON.stringify(trendInfo);
        var escaped = escape(json);
        var base64 = btoa(escaped);
        var encoded = encodeURI(base64);

        window.open(`clone?tags=${encoded}`, '_blank', 'noreferrer');

    }.bind(this);

    tabSelected = function (key) {

        this.setState({
            selectedTab: key,
            tags: [],
            selectedTags: [],
            skip: 0,
            id: 0,
        }, (s) => this.updateTags(this.state.location, this.state.source));

    }.bind(this);

    onRefresh = function () {

        this.updateTags(
            this.state.location,
            this.state.source,
            this.state.search);

    }.bind(this);

    onSearch = function (data) {

        this.setState({ search: data });
        this.onRefresh();

    }.bind(this);

    render() {

        const { t, classes } = this.props;
        return (

            <div className={classes.root}>

                <div id="drag" className={classes.drag} />

                <Tabs className="mb-3"
                    heightAdjustMode="fill"
                    defaultActiveKey={TagBrowserTabs.Source}
                    onSelect={this.tabSelected}> 

                    <Tab className={classes.contentArea}
                        eventKey={TagBrowserTabs.Master}
                        title={t(TagBrowserTabs.Master)}>
                        <div className={classes.tabContent}>

                            <TagBrowserSection className={classes.section}
                                sources={this.state.sources}
                                locations={this.state.locations}
                                location={this.state.location}
                                source={this.state.source}
                                locationsLoading={this.state.locationsLoading}
                                sourcesLoading={this.state.sourcesLoading}
                                tagService={this.tagService}
                                selectedTab={this.state.selectedTab}
                                filterColumn={this.state.filterColumn}
                                filterRegex={this.state.filterRegex}
                                locationChanged={this.locationChanged}
                                sourceChanged={this.sourceChanged}
                                onFilterChecked={this.onFilterChecked}
                            />

                        </div>
                    </Tab>

                    <Tab className={classes.contentArea}
                        eventKey={TagBrowserTabs.Source}
                        title={t(TagBrowserTabs.Source)}>
                        <div className={classes.tabContent}>

                            <TagBrowserSection className={classes.section}
                                sources={this.state.sources}
                                locations={this.state.locations}
                                location={this.state.location}
                                source={this.state.source}
                                locationsLoading={this.state.locationsLoading}
                                sourcesLoading={this.state.sourcesLoading}
                                tagService={this.tagService}
                                selectedTab={this.state.selectedTab}
                                filterColumn={this.state.filterColumn}
                                filterRegex={this.state.filterRegex}
                                locationChanged={this.locationChanged}
                                sourceChanged={this.sourceChanged}
                                onFilterChecked={this.onFilterChecked}
                            />

                        </div>
                    </Tab>

                    <Tab className={classes.contentArea}
                        eventKey={TagBrowserTabs.Recent}
                        title={t(TagBrowserTabs.Recent)}
                        disabled>
                    </Tab>

                </Tabs>

                <TagGrid className={classes.contentArea}
                    selectedTab={this.state.selectedTab}
                    location={this.state.location}
                    source={this.state.sourceName}
                    onRefresh={this.onRefresh}
                    tags={this.state.tags}
                    isBusy={this.state.isBusy}
                    selectionChanged={this.onTagSelectionChanged}
                    onRowDoubleClicked={this.onRowDoubleClicked}
                    onScrollEnd={this.onScrollEnd}
                    onSearch={this.onSearch}
                />

            </div>
        );
    };
}

export default withStyles(tagBrowserPageStyle)(withTranslation()(TagBrowserPage));