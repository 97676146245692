import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import embeddedPageStyle from "assets/jss/material-kit-react/views/embeddedPage.jsx";

class EmbeddedLayout extends React.Component {
  componentDidMount() {
    document.body.style.height = "100%";
    document.documentElement.style.height = "100%";
    document.getElementById("root").style.height = "100%";
  }
  render() {
    const { classes, ...rest } = this.props;
    return <React.Fragment>{this.props.children}</React.Fragment>;
  }
}
export default withStyles(embeddedPageStyle)(EmbeddedLayout);
