import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import NavMenu from "./NavMenu";
import image from "assets/img/Splash_Background_Picture.png";

import displayListPageStyle from "assets/jss/material-kit-react/views/displayListPage.jsx";

import GridContainer from "components/Grid/GridContainer.jsx";

class Layout extends React.Component {
    constructor(props) {
        super(props);
        const { classes } = this.props;
        document.body.className = classes.body;
        document.documentElement.className = classes.html;
        document.getElementById("root").className = classes.root;

    }
    componentDidMount() {
        const { classes } = this.props;
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
       
    }
    render() {
        const { classes, ...rest } = this.props;
        return (
            <React.Fragment>

                {!this.props.isTagBrowserPage && (
                    <div
                        className={classes.pageHeader}
                        style={{
                            backgroundImage: "url(" + image + ")",
                            backgroundSize: "cover",
                            backgroundPosition: "top center"
                        }}>

                        <NavMenu isDisplayPage={this.props.isDisplayPage} />

                        {
                            !this.props.isDisplayPage && (
                                <div className={classes.container}>
                                    <GridContainer justify="center">
                                        {this.props.children}
                                    </GridContainer>
                                </div>)
                        }

                        {
                            this.props.isDisplayPage && (
                                <div className={classes.displayContainer}>
                                    <GridContainer justify="flex-start">
                                        {this.props.children}
                                    </GridContainer>
                                </div>)
                        }

                    </div>
                )}


                {this.props.isTagBrowserPage && (
                    <div>
                        <GridContainer justify="center">
                            {this.props.children}
                        </GridContainer>
                    </div>
                )}

            </React.Fragment>
        );
    }
}
export default withStyles(displayListPageStyle)(Layout);
