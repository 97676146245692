import React from "react";
import { authHeader } from "helpers";
import { stringify } from "query-string";
import { userService } from "services/user.service";
import { fetchService } from "services/fetch.service";
import { storagePrefix } from "helpers";
const baseUrl = process.env.PUBLIC_URL + "/api";

export const displayListService = {
  getDisplayList,
  getRestrictedDisplayList,
  setupBreadcrumbs,
  getMockBreadcrumbs,
  isEqual,
  logout,
  getDisplayListRootPath,
  getDisplayRootPath,
  setupRestrictedBreadcrumbs
};

function getDisplayListRootPath() {
  if (userService.isRestricted()) {
    return `/RestrictedDisplays/`;
  } else {
    return `/Displays/`;
  }
}

function getDisplayRootPath() {
  if (userService.isRestricted()) {
    return `/RestrictedDisplay/`;
  } else {
    return `/Display/`;
  }
}

function pathInboundFilter(path) {
  if (path !== undefined && path !== "") {
    return path.replace("‰", "%");
  } else {
    return path;
  }
}
//function pathOutboundFilter(path) {
//  if (path !== undefined && path !== "") {
//    return path.replace("%", "‰");
//  } else {
//    return path;
//  }
//}
async function getDisplayList(path, isFromNetwork) {
  path = pathInboundFilter(path);
  let params = {
      path: path,
      isFromNetworkPath: isFromNetwork
  };
  //send request with path
  const ahdr = await authHeader();
  const requestOptions = {
    method: "GET",
    headers: ahdr
    };

  let displayInfoPromise = fetchService
    .fetchWithErrorHandling(
      `${baseUrl}/DisplayList/GetDisplayList?${stringify(params)}`,
      requestOptions
    )
    .then(handleResponse);
  let displayInfo = await displayInfoPromise.then(function(result) {
    if (result) {
      const displayList = processFolderList(result);
      return displayList;
    }
  });
  return displayInfo;
}

async function getRestrictedDisplayList(restrictedId, path) {
  path = pathInboundFilter(path);
  const ahdr = await authHeader();
  const requestOptions = {
    method: "GET",
    headers: ahdr
  };
  var displayInfoPromise;

  if (path === "" || path === "/" || path === undefined) {
    displayInfoPromise = fetchService
      .fetchWithErrorHandling(
        `${baseUrl}/DisplayList/GetRestrictedDisplayListRoot`,
        requestOptions
      )
      .then(handleResponse);
  } else {
    let params = {
      restrictedFolderId: restrictedId,
      relativePath: path
    };
    displayInfoPromise = fetchService
      .fetchWithErrorHandling(
        `${baseUrl}/DisplayList/GetRestrictedDisplayList?${stringify(params)}`,
        requestOptions
      )
      .then(handleResponse);
  }

  //send request with path

  let displayInfo = await displayInfoPromise.then(function(result) {
    if (result) {
      const displayList = processFolderList(result);
      return displayList;
    }
  });
  return displayInfo;
}

function processFolderList(folderList) {
  if (folderList != null) {
    var arr = [];
    folderList.forEach(function(item) {
      var icon;
      switch (item.fileType) {
        case "Folder":
          icon = <img src={require("../assets/img/icons/Folder.png")} alt="Folder Icon"/>;
          break;
        case "Graphic":
          if (item.shortcutTarget == null || item.shortcutTarget === "") {
            icon = (
              <img src={require("../assets/img/icons/PARCgraphics.ico")} alt="PARCgraphics Icon" />
            );
          } else {
            icon = (
              <img src={require("../assets/img/icons/GraphicShortcut.png")} alt="Graphics Shortcut"/>
            );
          }
          break;
        case "Display":
          if (item.shortcutTarget == null || item.shortcutTarget === "") {
            icon = <img src={require("../assets/img/icons/Trend.png")} alt="Trend Icon"/>;
          } else {
            icon = (
              <img src={require("../assets/img/icons/TrendShortcut.png")} alt="Trend Shortcut Icon" />
            );
          }
          break;
      }

      arr.push({
        value: item,
        primaryText: <span>{item.description}</span>,
        leftIcon: icon
      });
    });
    return arr;
  }
}

async function setupBreadcrumbs(path) {
  path = pathInboundFilter(path);
  if (path == null) {
    return [];
  }
  var rootPath = await userService.getRootPath();
  let display_path = path.toLowerCase();
  display_path = display_path.split("/");
  let breadcrumbs = await display_path

    .map((url, i) => {
      let fileName = url;
      //if (fileName.includes(".")) {
      //    fileName = url.split('.').slice(0, -1).join('.');
      //}
      if (fileName.includes("?")) {
        fileName = url.substring(0, url.indexOf("?"));
      }
      return {
        label: (
          decodeURIComponent(fileName.charAt(0).toUpperCase() + fileName.substring(1))
        ).replace("‰", "%"),
        uri: `/displays/${display_path.slice(0, i + 1).join("/")}`.replace(
          "%",
          "‰"
        )
      };
    })
    .filter(obj => {
      return (
        !`/displays/${rootPath}`.includes(obj.uri) ||
        `/displays/${rootPath}` === obj.uri + "/"
      );
    });

  breadcrumbs[0].label = "Home";
  const lastBreadcrumbIdx = breadcrumbs.length - 1;
  breadcrumbs[lastBreadcrumbIdx].uri = { javascript: void 0 };
  return breadcrumbs;
}

async function setupRestrictedBreadcrumbs(path, restrictedId) {
  path = pathInboundFilter(path);
  var homeCrumb = { label: "Home", uri: "/" };
  var breadcrumbs = [];
  if (path != null) {
    let display_path = path.toLowerCase();
    display_path = display_path.split("/");

    breadcrumbs = await display_path.map((url, i) => {
      let fileName = url;

      if (fileName.includes("?")) {
        fileName = url.substring(0, url.indexOf("?"));
      }
      return {
        label: fileName.charAt(0).toUpperCase() + fileName.substring(1),
        uri: `/restricteddisplays/${restrictedId}/${display_path
          .slice(0, i + 1)
          .join("/")}`
      };
    });
  }

  breadcrumbs.unshift(homeCrumb);

  const lastBreadcrumbIdx = breadcrumbs.length - 1;
  breadcrumbs[lastBreadcrumbIdx].uri = { javascript: void 0 };
  return breadcrumbs;
}

function isEqual(value, other) {
  // Get the value type
  var type = Object.prototype.toString.call(value);

  // If the two objects are not the same type, return false
  if (type !== Object.prototype.toString.call(other)) return false;

  // If items are not an object or array, return false
  if (["[object Array]", "[object Object]"].indexOf(type) < 0) return false;

  // Compare the length of the length of the two items
  var valueLen =
    type === "[object Array]" ? value.length : Object.keys(value).length;
  var otherLen =
    type === "[object Array]" ? other.length : Object.keys(other).length;
  if (valueLen !== otherLen) return false;

  // Compare two items
  var compare = function(item1, item2) {
    // Get the object type
    var itemType = Object.prototype.toString.call(item1);

    // If an object or array, compare recursively
    if (["[object Array]", "[object Object]"].indexOf(itemType) >= 0) {
      if (!isEqual(item1, item2)) return false;
    }

    // Otherwise, do a simple comparison
    else {
      // If the two items are not the same type, return false
      if (itemType !== Object.prototype.toString.call(item2)) return false;

      // Else if it's a function, convert to a string and compare
      // Otherwise, just compare
      if (itemType === "[object Function]") {
        if (item1.toString() !== item2.toString()) return false;
      } else {
        if (item1 !== item2) return false;
      }
    }
  };

  // Compare properties
  if (type === "[object Array]") {
    for (var i = 0; i < valueLen; i++) {
      if (compare(value[i], other[i]) === false) return false;
    }
  } else {
    for (var key in value) {
      if (value.hasOwnProperty(key)) {
        if (compare(value[key], other[key]) === false) return false;
      }
    }
  }

  // If nothing failed, return true
  return true;
}

function getMockBreadcrumbs() {
  const breadcrumbSteps = [
    {
      label: "SignIn",
      uri: "/signin"
    },
    {
      label: "Displays",
      uri: "javascript: void(0)",
      currentStep: "true"
    }
  ];

  return breadcrumbSteps;
}

function logout() {
  // remove user from local storage to log user out
    localStorage.removeItem(storagePrefix() + "user");
}

function handleResponse(response) {
    if (response === "error") {
        userService.logout();
    } else {
        return response.text().then(text => {
            const data = text !== "error" && JSON.parse(text);

            return data;
        });
    }
}
