import React from "react";

import DisplayList from "components/DisplayList/DisplayList.jsx";
import withStyles from "@material-ui/core/styles/withStyles";

import displayListPageStyle from "assets/jss/material-kit-react/views/displayListPage.jsx";

import { userService } from "services/user.service";
import { displayListService } from "services/index.js";

const styles = {
  body_header: {
    margin: "0px",
    padding: "10px"
  },
  responsive_toggler: {
    width: 250,
    paddingTop: 15
  }
};

class RestrictedDisplaysPage extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden",
      listInfo: this.props.match.params.listinfo,
      id: this.props.match.params.id,
      folderList: [{ value: "", primaryText: "" }],
      inProgress: true
    };

    this.children = props.children;
    this.refreshDisplayListInfo = this.refreshDisplayListInfo.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    setTimeout(
      function() {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );

    this.refreshDisplayListInfo();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.match.params.listinfo != this.props.match.params.listinfo ||
      prevProps.match.params.id != this.props.match.params.id
    ) {
      this.setState({ listInfo: this.props.match.params.listinfo });
      this.setState({ id: this.props.match.params.id });
    }
    if (
      prevState.listInfo !== this.state.listInfo ||
      prevState.id !== this.state.id
    ) {
      this.refreshDisplayListInfo();
    }
  }

  refreshDisplayListInfo() {
    this.setState({ inProgress: true });
    displayListService
      .getRestrictedDisplayList(this.state.id, this.state.listInfo)
      .then(result => {
        this.setState({ folderList: result, inProgress: false });
        document.getElementById("Card").scrollTo(0, 0);
      });
  }
  handleChange = (event, index) => {
    var newLocation = index.name;
    var restrictedId = index.restrictedId;

    if (index.restrictedType === "Folder" && index.fileType === "Folder") {
      if (this.state.id == null) {
        newLocation =
          displayListService.getDisplayListRootPath() +
          restrictedId +
          "/" +
          index.name;
      } else {
        if (!this.props.location.pathname.endsWith("/")) {
          newLocation = this.props.location.pathname + "/" + index.name;
        } else {
          newLocation = this.props.location.pathname + index.name;
        }
      }

      //  this.setState({ listInfo: this.state.listInfo + "/" + index.name });
    }
    if (index.restrictedType === "Folder" && index.fileType !== "Folder") {
      newLocation =
        displayListService.getDisplayRootPath() +
        "Folder/" +
        index.restrictedId +
        "/" +
        this.state.listInfo +
        "/" +
        index.name;
    }

    if (index.restrictedType === "File") {
      newLocation =
        displayListService.getDisplayRootPath() +
        "File/" +
        index.restrictedId +
        "/" +
        index.name;
    }

    this.props.history.push(encodeURI(newLocation));
  };

  render() {
    const { classes, browser, ...rest } = this.props;
    const index = this.props.location ? this.props.location.pathname : "/";

    return (
      <DisplayList
        {...this.props}
        handleChange={this.handleChange}
        folderList={this.state.folderList}
        inProgress={this.state.inProgress}
      />
    );
  }
}

export default withStyles(displayListPageStyle)(RestrictedDisplaysPage);
