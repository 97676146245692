import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import displayPageStyle from "assets/jss/material-kit-react/views/displayPage.jsx";
import { displayListService } from "services/index.js";
import queryString from "query-string";
import { contextMenu } from 'react-contexify';
import Hammer from 'react-hammerjs';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TagInfo from 'views/TagInfo/TagInfo.jsx';
import ExportView from 'views/Export/Export.jsx';
import { userService } from "services/user.service";
import { withTranslation } from "react-i18next";
import 'font-awesome/css/font-awesome.min.css';
import { Menu, Item, Separator, Submenu, IconFont, theme, animation } from 'react-contexify';
import 'react-contexify/dist/ReactContexify.min.css';
import { instance as DisplayMenuInstance } from 'components/DisplayMenu/DisplayMenu.jsx';
import { instance as DisplayPathBreadCrumbs } from 'components/DisplayPathBreadCrumbs/DisplayPathBreadCrumbs.jsx';

export let instance = null;
const HitTestTypes = {
    "WPFTrend": "WPFTrend",
    "TagInfoGrid": "ucTagInfoGrid",
    "HiddenCombo": "HiddenCombo",
    "DateTextBlock": "DateTextBlock",
    "Button": "Button",
    "PVTextBlock": "PVTextBlock",
    "TextBlock": "TextBlock",
    "Label": "Label",
    "TextBox": "TextBox",
    "LogicControl": "LogicControl",
    "GridPanel": "GridPanel",
    "ErrorCloseButton": "ErrorStatusBarCloseButton",
    "ErrorMoreDetails": "ErrorStatusBarMoreDetails",
};

const DragTypes = {
    "None": 0,
    "TrendZoom": 1,
    "Tag": 3,
};

class Display extends React.Component {

    constructor(props) {
        super(props);

        instance = this;
        const qobj = queryString.parse(this.props.location.search);
        const queryObj = queryString.parse(this.props.location.search.toLowerCase());

        this.state = {
            cardAnimaton: "cardHidden",
            currentVersion: -1,
            inProgress: true,
            canvasLoading: false,
            displayInitialized: false,
            isBusy: false,
            rootPath: this.props.user.rootPath,
            filepath: this.props.match.params.filepath,
            tagNames: qobj.tags,
            breadcrumbList: [{ label: "", uri: "javascript: void(0)" }],
            embedded: queryObj.embedded == null ? false : queryObj.embedded,
            hideBreadcrumbs: this.props.serverSettings.hideClientBreadcrumbs,
            updateRate: (queryObj.updaterate == null || queryObj.updaterate < 1) ? 0.5 : queryObj.updaterate,
            faultUpdateRate: 20,
            isFaulted: false,
            showTagValue: false,
            lmap: new Map(),
            tmap: new Map(),
            isHitTesting: false,
            dragType: DragTypes.None,
            zoomLevel: 0,
            selectedTags: [],
            selectedTag: undefined,
            displayWidth: window.innerWidth,
            displayHeight: this.props.serverSettings.hideClientBreadcrumbs ? window.innerHeight : window.innerHeight - 25,
            isSmartMouseEnabled: false,
            isTouchDevice: 'ontouchstart' in document.documentElement,
            scale: 1,
            isPinching: false,
            isPanning: false,
            displayType: 0,
            isSettingTimespan: false,
            isSettingDateRange: false,
            launchInPARCviewEnabled: this.props.serverSettings.launchInPARCviewEnabled,
            passTagToProtocolHandler: this.props.serverSettings.passTagToProtocolHandler,
            isClientSideGraphicMode: this.props.serverSettings.clientSideGraphicMode,
            size: this.getInitialSize(this.props.serverSettings.clientSideGraphicMode)  
        };
        
        if (this.props.serverSettings != null && this.props.serverSettings.isEmbedded) {
            this.state.embedded = this.props.serverSettings.isEmbedded;
        };

        this.displayService = this.props.displayService;
        this.children = props.children;
        this.refreshBreadCrumbs = this.refreshBreadCrumbs.bind(this);

        this.image = new Image();
        this.image.onload = function () {
            this.loadImage();
        }.bind(this);

        this.setTimespanDialogIsOpen = false;
        this.setDateRangeDialogIsOpen = false;
        this.setAddTagDialogIsOpen = false;
        this.setRemoveTagDialogIsOpen = false;
        this.setTagInfoDialogIsOpen = false;
        this.setExportDialogIsOpen = false;
        this.timespan = '8H';
        this.flag = false;
        this.canvas = null;
        this.context = null;
        this.prevX = 0;
        this.currX = 0;
        this.prevY = 0;
        this.currY = 0;
        this.hasStartEllipse = false;
        this.PerformedMouseDown = false;
        this.uTagInfo = {
            sourceInfo: {},
            processUnit: {},
            processInfo: {}
        };
    }

    getInitialSize = function (isClientSideMode) {
        const adjustment = isClientSideMode ? 10 : 0;
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;

        // Determine if the breadcrumbs should be hidden, to adjust screenHeight.
        const hideBreadcrumbs = this.props.serverSettings.hideClientBreadcrumbs;
        const breadcrumbsHeight = hideBreadcrumbs ? 0 : 25;
        const screenHeight = (windowHeight - breadcrumbsHeight) + adjustment;

        return {
            screenWidth: windowWidth + adjustment,
            screenHeight: screenHeight,
            windowWidth: windowWidth,
            windowHeight: windowHeight - breadcrumbsHeight
        };
    }

    getSize = function () {
        var dpr = window.devicePixelRatio || 1;
        //var dpr = (window.devicePixelRatio > 1) ? 3.3 : 1;
        return {
            screenWidth: Math.floor(window.innerWidth * dpr),
            screenHeight: this.state.hideBreadcrumbs ? Math.floor(window.innerHeight * dpr) : Math.floor((window.innerHeight - 25) * dpr),
            //screenWidth: window.innerWidth,
            //screenHeight: this.state.hideBreadcrumbs ? window.innerHeight : (window.innerHeight - 25),
            windowWidth: window.innerWidth,
            windowHeight: this.state.hideBreadcrumbs ? window.innerHeight : (window.innerHeight - 25)
        }

    }

    loadImage = function () {
        var canvas = document.getElementById("displayCanvas");
        if (canvas) {
            var context = canvas.getContext("2d");
            if (context != null) {
                const {size} = this.state

                var dpr = window.devicePixelRatio || 1;

                canvas.width = size.screenWidth;
                canvas.height = size.screenHeight;

                if (this.state.isClientSideGraphicMode && !this.state.isTouchDevice) {
                    canvas.style.width = `${size.screenWidth}px`;
                    canvas.style.height = `${size.screenHeight}px`;
                    this.acanvas.style.width = `${size.screenWidth}px`;
                    this.acanvas.style.height = `${size.screenHeight}px`;
                    this.acanvas.width = size.screenWidth;
                    this.acanvas.height = size.screenHeight;
                } else {
                    canvas.style.width = `${size.windowWidth}px`;
                    canvas.style.height = `${size.windowHeight}px`;
                }


                context.imageSmoothingEnabled = (dpr == 1);
                context.drawImage(this.image, 0, 0, size.screenWidth, size.screenHeight);
            }
        }
        this.setState({ canvasLoading: false });
    }

    redrawSelections = function () {
        if (this.state.tmap && this.state.tmap.size > 0) {
            for (let rect of this.state.tmap.values()) {
                this.drawRect(rect.x, rect.y, rect.w, rect.h, false);
            }
        }
    }.bind(this);

    onHandleContextMenu = async function (e) {

        this.setState({ isBusy: true });

        e.persist();

        var pos = getMousePos(e);
        this.RClickPosition = pos;

        var info = await this.getHitTestInfo(pos);
        if (info) {

            this.drawTagValue(undefined, undefined);

            var file = undefined;
            if (this.state.launchInPARCviewEnabled && !this.state.isTouchDevice) {
                if (this.state.passTagToProtocolHandler) {
                    var tag = (info.uTags && info.uTags.length > 0) ? info.uTags[0] : "";
                    if (tag) {
                        file = tag.uTag;
                        this.state.selectedTag = tag;
                    }
                } else {
                    file = this.state.filepath;
                }
            }

            DisplayMenuInstance.show(file, info, e);
            this.setState({ isContextMenuOpen: true });
        }

        this.setState({ isBusy: false });

    }.bind(this);

    componentWillUnmount() {
        this.mounted = false;
    }

    componentDidMount() {
        this.mounted = true;

        this.setState(displayState => ({
            ...displayState,
            size: this.getSize()
        }))

        if (this.state.isClientSideGraphicMode) {
            if (!this.state.isTouchDevice) {
                this.setOverflowValue();
            }
        }
        setTimeout(
            function () {
                this.setState({ cardAnimaton: "" });
            }.bind(this),
            700
        );

        window.addEventListener("keydown", this.doKeyDown, false);
        window.addEventListener("keyup", this.doKeyUp, false);

        var canvas = document.getElementById("displayCanvas");
        if (canvas) {
            this.canvas = canvas;
            this.context = canvas.getContext("2d");
        }

        var annotationCanvas = document.getElementById("annotationCanvas");
        if (annotationCanvas) {

            this.acanvas = annotationCanvas;
            this.acontext = this.acanvas.getContext("2d");

            this.acanvas.addEventListener("mousedown", this.doMouseDown, false);
            this.acanvas.addEventListener("mouseup", this.doMouseUp, false);
            this.acanvas.addEventListener("mousemove", this.doMouseMove, false);
            this.acanvas.addEventListener("dblclick", this.doMouseDoubleClick, false);
            this.acanvas.addEventListener("mouseout", this.doMouseOut, false);
            this.acanvas.addEventListener("wheel", this.doMouseWheel, false);
            this.acanvas.addEventListener("touchstart", this.onTouchStart, false);
            this.acanvas.addEventListener("touchend", this.onTouchEnd, false);

            this.acanvas.oncontextmenu = (e) => e.preventDefault();
        }

        if (this.state.tagNames) {
            this.loadImageForClone();
        } else {
            this.loadImageForDisplay();
        }

        this.beginChecking();
        this.refreshBreadCrumbs(window.location.href);

        var resize;
        window.onresize = function () {
            clearTimeout(resize);
            resize = setTimeout(this.onResize, 300);
        }.bind(this);
    }

    onResize = async function () {
        if (!this.state.isClientSideGraphicMode) {
            try {
                //if (this.state.isSettingDateRange || this.state.isSettingTimespan) {
                //    return;
                //}
                if (this.state.displayInitialized) {

                    this.setState({
                        inProgress: true,
                        isBusy: true
                    });

                    this.setState(displayState => ({
                        ...displayState,
                        size: this.getSize()
                    }));

                    const { size } = this.state

                    var versionObj = await this.displayService.getLatestVerionNumber(
                        this.props.sessionId,
                        this.state.displayId,
                        size.screenWidth,
                        size.screenHeight)

                    if (versionObj.version === false) {
                        this.setState({ isFaulted: true });
                    } else {
                        this.setState({ isFaulted: false });
                    }

                    if (versionObj.version !== this.state.currentVersion && this.state.canvasLoading === false) {
                        this.setState({ currentVersion: versionObj.version });
                    }

                    this.setState({
                        lmap: new Map(),
                        inProgress: this.state.canvasLoading,
                        isBusy: false,
                        displayWidth: size.windowWidth,
                        displayHeight: size.windowHeight
                    });
                }

            } catch (ex) {
                debugger;
            }
        } else {
            this.setOverflowValue();
            this.loadImage();
        }
    }.bind(this);

    setOverflowValue = function () {        
        const zoomLevel = Math.round(window.devicePixelRatio * 100);
        
        if (zoomLevel !== 100) {
            document.body.style.overflow = 'auto';
        } else {
            document.body.style.overflow = 'hidden';
        }
    }

    beginChecking = async function () {
        if (!this.state.isBusy
            && this.state.displayInitialized
            && !this.state.isSettingDateRange
            && !this.state.isSettingTimespan) {

            this.setState({ isBusy: true });

            try {

                const {size} = this.state

                var versionObj = await this.displayService.getLatestVerionNumber(
                    this.props.sessionId,
                    this.state.displayId,
                    size.screenWidth,
                    size.screenHeight)

                if (versionObj.version === false) {
                    this.setState({ isFaulted: true });
                } else {
                    this.setState({ isFaulted: false });
                }

                if (versionObj.version !== this.state.currentVersion
                    && this.state.canvasLoading === false) {
                    this.setState({ currentVersion: versionObj.version });
                }

                if (versionObj.launchNewWindowUrl) {
                    await this.openGraphicWindow(versionObj.launchNewWindowUrl, true);
                    await this.displayService.clearImageVersionUrl(this.displayId);
                }

            } catch (ex) {
                debugger;
            }

            setTimeout(
                function () {
                    if (this.mounted) {
                        this.setState({ isBusy: false });
                        this.beginChecking();
                    }
                }.bind(this),
                this.state.isFaulted
                    ? this.state.faultUpdateRate * 1000
                    : this.state.updateRate * 1000
            );
        } else {
            setTimeout(
                function () {
                    if (this.mounted) {
                        this.beginChecking();
                    }
                }.bind(this),
                1000
            );
        }
    }.bind(this);

    loadImageForDisplay = function () {
        this.setState({ canvasLoading: true }, () => {

            const {size} = this.state
            if (this.state.displayInitialized === false) {
                this.setState({ isBusy: true });

                this.displayService.initDisplay(
                    this.state.filepath,
                    size.screenWidth,
                    size.screenHeight,
                    this.props.sessionId
                )
                    .then((r) => {
                        var info = JSON.parse(r);

                        if (info) {

                            this.setState({
                                displayInitialized: true,
                                displayId: info.id.replace(/"/g, ""),
                                displayWidth: size.windowWidth,
                                displayHeight: size.windowHeight,
                                displayType: info.type,
                            });


                            this.displayService
                                .getDisplay(this.props.sessionId, this.state.displayId, size.screenWidth, size.screenHeight)
                                .then(srcValue => {
                                    this.image.src = srcValue;
                                })
                                .catch()
                                .then(() => {
                                    this.setState({
                                        canvasLoading: false,
                                        isBusy: false
                                    });
                                });
                        }

                    })
                    .catch()
                    .then(() => this.setState({ canvasLoading: false }));
            } else {
                this.displayService
                    .getDisplay(this.props.sessionId, this.state.displayId, size.screenWidth, size.screenHeight)
                    .then(srcValue => {
                        this.image.src = srcValue;
                        return srcValue === 'data:image/png;base64,';
                    })
                    .catch()
                    .then((v) => {
                        this.setState({
                            inProgress: v,
                            canvasLoading: false,
                            isBusy: false
                        });
                    });
            }
        });
    };

    loadImageForClone = function () {

        this.setState({ canvasLoading: true }, () => {

            const {size} = this.state
            if (this.state.displayInitialized === false) {

                this.setState({ isBusy: true });
                this.displayService.initClone(
                    this.state.tagNames,
                    window.innerHeight,
                    window.innerHeight,
                    this.props.sessionId)
                    .then((id) => {

                        this.setState({
                            displayInitialized: true,
                            displayId: id.replace(/"/g, ""),
                            displayWidth: size.windowWidth,
                            displayHeight: size.windowHeight,
                            displayType: 2,
                        });

                        this.displayService
                            .getDisplay(this.props.sessionId, this.state.displayId, size.screenWidth, size.screenHeight)
                            .then(srcValue => {
                                this.image.src = srcValue;
                            })
                            .catch()
                            .then(() => {
                                this.setState({
                                    canvasLoading: false,
                                    isBusy: false
                                });
                            });
                    })
                    .catch()
                    .then(() => this.setState({ canvasLoading: false }));

            } else {

                this.displayService
                    .getDisplay(this.props.sessionId, this.state.displayId, size.screenWidth, size.screenHeight)
                    .then(srcValue => { this.image.src = srcValue; })
                    .catch()
                    .then(() => {
                        this.setState({
                            inProgress: false,
                            canvasLoading: false,
                            isBusy: false
                        });
                    });
            }
        });
    }.bind(this);

    refreshBreadCrumbs(url) {
        displayListService
            .setupBreadcrumbs(url, "display")
            .then(result => this.setState({ breadcrumbList: result }));
    }

    openWindow = function (url, target, title, width, height) {
        const y = window.top.outerHeight / 2 + window.top.screenY - (height / 2);
        const x = window.top.outerWidth / 2 + window.top.screenX - (width / 2);
        var tbw = window.open(url, target, `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=yes, copyhistory=no, width=${width}, height=${height}, top=${y}, left=${x}`);
        tbw.onload = function () { setTimeout(function () { tbw.document.title = title; }, 500); }
        return true;
    }.bind(this);

    launchTagBrowser = async function () {

        var loggedIn = await userService.isLoggedIn();
        if (loggedIn) {
            return this.openWindow(`tags`, '_blank', 'Tag Browser', 800, 600);
        }

    }.bind(this);

    onToggleQuickStatistics = function () {
        this.setState({ isBusy: true });
        this.displayService
            .ToggleQuickStatistics(this.RClickPosition.x, this.RClickPosition.y, this.props.sessionId, this.state.displayId)
            .catch();
    }.bind(this);

    onToggleAutoScaleAll = function () {
        this.setState({ isBusy: true });
        this.displayService
            .ToggleAutoScaleAll(this.RClickPosition.x, this.RClickPosition.y, this.props.sessionId, this.state.displayId)
            .catch();
    }.bind(this);

    onToggleValueTextAll = function () {
        this.setState({ isBusy: true });
        this.displayService
            .ToggleValueTextAll(this.RClickPosition.x, this.RClickPosition.y, this.props.sessionId, this.state.displayId)
            .catch();
    }.bind(this);

    onToggleShowDotsAll = function () {
        this.setState({ isBusy: true });
        this.displayService
            .ToggleShowDotsAll(this.RClickPosition.x, this.RClickPosition.y, this.props.sessionId, this.state.displayId)
            .catch();
    }.bind(this);

    onToggleBadQualityAll = function () {
        this.setState({ isBusy: true });
        this.displayService
            .ToggleBadQualityAll(this.RClickPosition.x, this.RClickPosition.y, this.props.sessionId, this.state.displayId)
            .catch();
    }.bind(this);

    onToggleSmartMouse = function () {
        this.setState({
            isBusy: true,
            isSmartMouseEnabled: !this.state.isSmartMouseEnabled
        });
    }.bind(this);

    onSync = function () {
        this.setState({ isBusy: true });
        this.displayService
            .Sync(this.RClickPosition.x, this.RClickPosition.y, this.props.sessionId, this.state.displayId)
            .catch();
    }.bind(this);

    onAutoSync = function (event) {
        this.displayService
            .AutoSync(this.RClickPosition.x, this.RClickPosition.y, this.props.sessionId, this.state.displayId)
            .catch();
    }.bind(this);


    onSync = function () {
        this.setState({ isBusy: true });
        this.displayService
            .Sync(this.RClickPosition.x, this.RClickPosition.y, this.props.sessionId, this.state.displayId)
            .catch();
    }.bind(this);

    onEditTrendLayout = function (rows, cols) {
        this.setState({ isBusy: true });
        this.displayService
            .EditTrendLayout(this.RClickPosition.x, this.RClickPosition.y, rows, cols, this.props.sessionId, this.state.displayId)
            .catch();
    }

    onClone = async function (event) {

        try {

            var sinfo = await this.getHitTestInfo(this.RClickPosition);
            if (sinfo) {

                this.setState({ isBusy: true });

                if (sinfo.uTags) {
                    if (!this.state.tmap) {
                        this.setState({ tmap: new Map() });
                    }

                    for (var index in sinfo.uTags) {
                        var t = sinfo.uTags[index];
                        if (!this.objectPropInMapKey(this.state.tmap, 'uTag', t.uTag)) {

                            var rect = sinfo.boundingRect;
                            this.state.tmap.set(t, {
                                x: parseFloat(rect.x),
                                y: parseFloat(rect.y),
                                w: parseFloat(rect.width),
                                h: parseFloat(rect.height),
                            });
                        }
                    }

                    var tags = [];
                    this.state.tmap.forEach((value, key) => {
                        tags.push({
                            uTag: key.uTag,
                            start: key.start,
                            end: key.end,
                            plotMin: key.plotMin,
                            plotMax: key.plotMax,
                            valueMask: key.valueMask,
                            color: key.color,
                            autoScale: key.autoScale,
                            alarmBlinkOn: key.alarmBlinkOn,
                            description: key.description,
                        });
                    });

                    var trendInfo = {
                        trendGridLabelContent: sinfo.trendGridLabelContent,
                        trendGridLabelBackgroundColor: sinfo.trendGridLabelBackgroundColor,
                        trendHeaderBackgroundColor: sinfo.trendHeaderBackgroundColor,
                        showHeader: sinfo.showHeader,
                        showLabel: sinfo.showLabel,
                        startTime: sinfo.start,
                        endTime: sinfo.end,
                        tags: tags,
                    }

                    var json = JSON.stringify(trendInfo);
                    var escaped = escape(json);
                    var base64 = btoa(escaped);
                    var encoded = encodeURI(base64);
                    this.clearTags();

                    window.open(`clone?tags=${encoded}`, '_blank', 'noreferrer');
                }
            }
        }
        catch (ex) {
            console.error(ex);
        }
        finally {
            this.setState({
                isBusy: false
            });
        }
    }.bind(this);

    onSaveAs = function (event) {
        var canvas = document.getElementById("displayCanvas");
        if (canvas) {
            var link = document.getElementById('link');
            link.setAttribute('download', `${new Date().toISOString()}.png`);
            link.setAttribute('href', canvas.toDataURL("image/png").replace("image/png", "image/octet-stream"));
            link.click();
        }
    }.bind(this);

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((this.state.currentVersion !== prevState.currentVersion || this.state.filepath !== prevState.filepath)
            && this.state.canvasLoading === false) {

            if (this.state.tagNames) {
                this.loadImageForClone();
            } else {
                this.loadImageForDisplay();
            }

        }
    }

    handleTimespanClickOpen = function () {

        this.setState({
            isSettingTimespan: true
        }, () => {
            this.setTimespanDialogIsOpen = true;
        });

    }.bind(this);

    handleTimespanCancel = function () {
        this.setState({
            isSettingTimespan: false
        }, () => {
            this.setTimespanDialogIsOpen = false;
        });
    }.bind(this);

    handleTimespanOK = function () {
        this.setTimespanDialogIsOpen = false;
        this.onSetTimespan(this.timespan);
    }.bind(this);

    handleTimespanChanged = function (event) {
        this.timespan = event.target.value;
    }.bind(this);

    onSetTimespan = async function (timespan) {

        var result = await this.displayService.SetTimespan(
            this.state.selectInfo.start.x,
            this.state.selectInfo.start.y,
            this.props.sessionId,
            this.state.displayId,
            timespan);

        this.setState({
            selectInfo: null,
            isSettingTimespan: false
        });

    }.bind(this);

    onAddTag = function (e) {
        this.setAddTagDialogIsOpen = true;
    }.bind(this);

    handleAddTagCancel = function (e) {
        this.setAddTagDialogIsOpen = false;
    }.bind(this);

    handleAddTagOK = async function (e) {
        var ttatb = document.getElementById("tagToAdd");
        if (ttatb) {
            var tagToAdd = ttatb.value;
            if (tagToAdd) {

                this.setState({
                    isBusy: true,
                    inProgress: true
                });

                var result = await this.displayService.ModifyTrendTags(
                    this.RClickPosition.x,
                    this.RClickPosition.y,
                    tagToAdd,
                    false,
                    this.props.sessionId,
                    this.state.displayId);

                this.clearTrendCaches();

                this.setState({
                    isBusy: false,
                    inProgress: false
                });
            }
        }
        this.setAddTagDialogIsOpen = false;
    }.bind(this);

    onRemoveTag = async function (e) {
        this.setRemoveTagDialogIsOpen = true;
        var info = await this.getHitTestInfo(this.RClickPosition);
        if (info) {
            this.setState({
                selectedTags: info.trendUTags,
                selectedTag: undefined
            });
        }

    }.bind(this);

    handleRemoveTagCancel = function (e) {
        this.setRemoveTagDialogIsOpen = false;
    }.bind(this);

    handleRemoveTagOK = async function (e) {
        var ttrtb = document.getElementById("tagToRemove");
        if (ttrtb) {
            var tagToRemove = ttrtb.value;
            if (tagToRemove) {

                this.setState({
                    isBusy: true,
                    inProgress: true
                });

                this.setRemoveTagDialogIsOpen = false;
                var result = await this.displayService.ModifyTrendTags(
                    this.RClickPosition.x,
                    this.RClickPosition.y,
                    tagToRemove,
                    true,
                    this.props.sessionId,
                    this.state.displayId);

                this.clearTrendCaches();

                this.setState({
                    isBusy: false,
                    inProgress: false,
                    selectedTags: [],
                    selectedTag: undefined
                });
            }
        }

    }.bind(this);

    clearTrendCaches = function () {
        for (let key of this.state.lmap.keys()) {
            var value = this.state.lmap.get(key);
            if (value.type == HitTestTypes.GridPanel || value.type == HitTestTypes.WPFTrend) {
                this.state.lmap.delete(key);
            }
        }
    }.bind(this);

    onSelectedTagToRemoveChanged = function (e) {
        this.setState({
            selectedTag: e.target.value
        });
    }.bind(this);

    handleDateRangeClickOpen = function () {

        this.setState({
            isSettingDateRange: true
        }, () => {
            this.setDateRangeDialogIsOpen = true;
        });

    }.bind(this);

    handleDateRangeCancel = function () {

        this.setState({
            isSettingDateRange: false
        }, () => {
            this.setDateRangeDialogIsOpen = false;
        });

    }.bind(this);

    handleDateRangeStartChanged = function (event) {
        this.dateRangeStart = event.target.value;
    }.bind(this);

    handleDateRangeEndChanged = function (event) {
        this.dateRangeEnd = event.target.value;
    }.bind(this);

    handleDateRangeOK = function () {
        this.setDateRangeDialogIsOpen = false;
        this.onSetDateRange(this.dateRangeStart, this.dateRangeEnd);
    }.bind(this);

    onSetDateRange = async function (start, end) {
        var s = new Date(start);
        var e = new Date(end);
        if (isNaN(s) || s <= 0 || isNaN(e) || e <= 0) {
            return;
        }

        var x = 0;
        var y = 0;
        if (this.state.selectInfo != null) {
            x = this.state.selectInfo.start.x;
            y = this.state.selectInfo.start.y;
        }

        try {
            var result = await this.displayService.SetDateRange(
                x,
                y,
                this.props.sessionId,
                this.state.displayId,
                start,
                end);
        }
        catch (ex) {
            console.log(ex);
        }
        finally {
            this.setState({
                selectInfo: null,
                isSettingDateRange: false,
            });
        }

    }.bind(this);

    isMobile = function () {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile/i.test(navigator.userAgent);
    }.bind(this);

    doMouseWheelZoom = async function (pos) {
        if (this.state.zoomLevel != 0) {

            this.setState({
                isPinching: true,
                inProgress: true,
                isBusy: true,
            });

            if (this.zoomHandle) {
                clearTimeout(this.zoomHandle);
            }

            this.zoomHandle = setTimeout(() => this.setState({ inProgress: false }), 2000);

            try {
                await this.displayService.MouseWheelZoom(pos.x, pos.y, this.state.zoomLevel, this.props.sessionId, this.state.displayId);
                this.state.lmap = new Map();
                this.acanvas.style.cursor = "default";
            }
            catch (ex) {
                console.log(ex);
            }
            finally {
                this.setState({
                    isPinching: false,
                    isBusy: false,
                    zoomLevel: 0
                });
            }
        }

    }.bind(this);

    doMouseWheel = function (e) {
        this.acanvas.style.cursor = "default";
        if (!this.state.isClientSideGraphicMode) {
            if (e.ctrlKey) {

                e.preventDefault();

                var step = (e.wheelDelta > 0) ? 1 : -1;
                var amount = this.state.zoomLevel + step;
                this.setState({ zoomLevel: amount });

                if (this.wheeltimeout !== undefined) {
                    clearTimeout(this.wheeltimeout);
                }

                var pos = getMousePos(e);
                this.wheeltimeout = setTimeout(() => this.doMouseWheelZoom(pos), 500);

                if (e.wheelDelta > 0) {
                    this.acanvas.style.cursor = "zoom-in";
                } else {
                    this.acanvas.style.cursor = "zoom-out";
                }

            }
        }
    }.bind(this);

    doMouseOut = function (event) {
        if (this.state.dragType != DragTypes.None) {
            if (this.acontext) {
                this.acontext.clearRect(0, 0, this.acanvas.width, this.acanvas.height);
            }

            this.acanvas.style.cursor = "default";
            this.state.selectInfo = null;
            this.setState({
                isBusy: false,
                dragType: DragTypes.None
            });
        }
    }.bind(this);

    removeRect = function (rect) {
        if (this.acontext) {
            this.acontext.clearRect(rect.x, rect.y, rect.w, rect.h);
        }
    }

    clearTags = function () {
        this.setState({ tmap: new Map() });
        if (this.acontext) {
            this.acontext.clearRect(0, 0, this.acanvas.width, this.acanvas.height);
        }
    }.bind(this);

    objectPropInMapKey = function (map, prop, val) {
        if (map.size > 0) {
            for (let key of map.keys()) {
                if (key[prop] === val) {
                    return true;
                }
            }
        }
        return false;
    }.bind(this);

    canTrend = function (info) {
        var result = false;
        if (info.uTags) {
            result = (info.uTags.length > 0);
        }
        return result;
    }.bind(this);

    drawRect = function (x, y, width, height, clear) {
        if (this.acontext) {

            if (clear) {
                this.acontext.clearRect(0, 0, this.acanvas.width, this.acanvas.height);
            }

            this.acontext.beginPath();
            this.acontext.fillStyle = 'rgba(117, 123, 153, 0.8)';
            this.acontext.strokeStyle = 'red';
            this.acontext.rect(x, y, width, height);
            this.acontext.fill();
            this.acontext.stroke();
        }
    }.bind(this);

    drawTagValue = function (pos, i) {
        if (this.acontext && this.state.dragType == DragTypes.None) {

            this.acontext.clearRect(0, 0, this.acanvas.width, this.acanvas.height);
            this.redrawSelections();
            if (i && i.uTags && i.uTags.length == 1 && i.type != HitTestTypes.GridPanel) {

                var tag = i.uTags[0];

                this.acontext.beginPath();
                this.acontext.fillStyle = 'rgba(254,255,156, 1)';
                this.acontext.strokeStyle = 'black';

                var width = 250;
                if (tag.description.length > 25 || tag.uTag.length > 25) {

                    const maxLength = 25;
                    var uTagDelta = (tag.uTag.length - maxLength);
                    var uDescriptionDelta = (tag.description.length - maxLength);
                    var delta = Math.max(uTagDelta, uDescriptionDelta) * 7;
                    width += delta;
                }

                this.acontext.rect(pos.x, pos.y, width, 130);

                this.acontext.fill();
                this.acontext.stroke();

                this.acontext.fillStyle = "black";
                this.acontext.textAlign = "left";

                this.acontext.font = "800 9px Comic Sans MS";
                this.acontext.fillText(`UTag:`, pos.x + 5, pos.y + 20);
                this.acontext.fillText(`Description:`, pos.x + 5, pos.y + 40);
                this.acontext.fillText(`Units:`, pos.x + 5, pos.y + 60);
                this.acontext.fillText(`Timestamp:`, pos.x + 5, pos.y + 80);
                this.acontext.fillText(`Value:`, pos.x + 5, pos.y + 100);
                this.acontext.fillText(`Quality:`, pos.x + 5, pos.y + 120);

                this.acontext.font = "normal 10px Comic Sans MS";
                this.acontext.fillText(tag.uTag, pos.x + 60, pos.y + 20);
                this.acontext.fillText(tag.description, pos.x + 60, pos.y + 40);
                this.acontext.fillText(tag.units, pos.x + 60, pos.y + 60);
                this.acontext.fillText(tag.timestamp, pos.x + 60, pos.y + 80);
                this.acontext.fillText(tag.value, pos.x + 60, pos.y + 100);
                this.acontext.fillText(tag.quality, pos.x + 60, pos.y + 120);
            }
        }
    }.bind(this);

    drawSmartMouse = function (info, x, force) {

        var context = this.acontext;
        if (context) {

            var rect = info.boundingRect;
            var y = parseFloat(rect.y);
            var h = parseFloat(rect.height);

            context.clearRect(0, 0, this.acanvas.width, this.acanvas.height);

            context.beginPath();
            context.strokeStyle = 'white';
            context.lineWidth = 3;
            context.moveTo(x, y);
            context.lineTo(x, y + h);
            context.stroke();

            var start = new Date(info.start);
            var end = new Date(info.end);

            const xOffset = x - info.boundingRect.x;

            for (let trend of info.trends) {

                var ts = new Date(trend.start);
                var te = new Date(trend.end);

                if (!force) {

                    if (start.getDate() != ts.getDate()
                        || start.getHours() != ts.getHours()
                        || end.getDate() != te.getDate()
                        || end.getHours() != te.getHours()) {
                        continue;
                    }
                }

                var trect = trend.boundingRect;

                var ty = parseFloat(trect.y);
                var th = parseFloat(trect.height);

                var finalX = xOffset + trect.x;

                context.beginPath();
                context.strokeStyle = 'white';
                context.lineWidth = 3;
                context.moveTo(finalX, ty);
                context.lineTo(finalX, ty + th);
                context.stroke();
            }
        }
    }.bind(this);

    drawSmartMouseToolTip = function (info, pos, data) {

        var context = this.acontext;
        if (context && this.state.dragType == DragTypes.None) {

            var values = JSON.parse(data);
            if (values.length > 0) {

                context.beginPath();
                context.lineWidth = 1;

                var width = 0;
                var height = 17.5;

                for (let v of values) {
                    //var len = v.uTag.lastIndexOf('.');
                    //var tag = v.uTag.substring(len + 1);
                    //var line = `${tag}:   ${v.value}`;
                    var line = `${v.value}: ${v.description}`;
                    if (info.runDisplayType === 'Batch' || info.runDisplayType === 'Compare') {
                        height = height + 17.5;
                        line = `${v.value}: ${v.timestamp} ${v.description}`;
                    }

                    width = Math.max(line.length, width);

                    if (v.limits.length > 0) {
                        for (let l of v.limits) {
                            line = `${l.type === 1 ? 'Control' : 'Spec    '}    L: ${l.min ? l.min : '--'}    T: ${l.target ? l.target : '--'}    U: ${l.max ? l.max : '--'}`;
                            width = Math.max(line.length * .65, width);
                            height = height + 19;
                        }
                    }


                    height = height + 17.5;
                }

                width = Math.max(values[0].timestamp.length, width);

                width = width * 7;
                var posX = pos.x + 5;
                if (posX + width > window.innerWidth) {
                    posX = (window.innerWidth - width);
                }

                context.fillStyle = 'black';
                context.strokeStyle = 'white';
                context.rect(posX, pos.y, width, height + 6);
                context.fill();
                context.stroke();

                context.textAlign = "left";
                context.font = "normal 10px Comic Sans MS";

                var yOffset = 16;

                context.fillStyle = 'white';

                //var dt = new Date(values[0].timestamp);
                //context.fillText(dt.toLocaleString(), posX + 9, pos.y + yOffset);

                if (info.runDisplayType === 'Batch' || info.runDisplayType === 'Compare') {
                    context.fillText(`Relative Time: ${values[0].axisTimestamp}`, posX + 9, pos.y + yOffset);
                }
                else {
                    context.fillText(values[0].axisTimestamp, posX + 9, pos.y + yOffset);
                }

                var yOffset = 32;
                values.reverse();
                for (let v of values) {

                    //var len = v.uTag.lastIndexOf('.');
                    //var tag = v.uTag.substring(len + 1);
                    //var line = `${tag}:   ${v.value}`;

                    if (info.runDisplayType === 'Batch' || info.runDisplayType === 'Compare') {
                        var match = info.uTags.filter((t) => t.uTag == v.uTag);
                        if (match) {
                            context.fillStyle = this.toColor(match[0].color);
                            context.fillText(`${v.description}`, posX + 9, pos.y + yOffset);
                            yOffset = yOffset + 16;

                            line = `  ${v.value}: ${v.timestamp}`;
                            context.fillStyle = 'white';
                            context.fillText(line, posX + 9, pos.y + yOffset);
                            yOffset = yOffset + 16;
                        }
                    }
                    else {
                        var line = `${v.value}:   ${v.description}`;
                        var match = info.uTags.filter((t) => t.uTag == v.uTag);
                        if (match) {
                            context.fillStyle = this.toColor(match[0].color);
                            context.fillText(line, posX + 9, pos.y + yOffset);
                            yOffset = yOffset + 16;
                        }
                    }
                }

                for (let v of values) {

                    if (values.indexOf(v) == 0) {
                        yOffset = yOffset + 6;
                    }

                    if (v.limits.length > 0) {
                        for (let l of v.limits) {
                            var match = info.uTags.filter((t) => t.uTag == v.uTag);
                            line = `${l.type === 1 ? 'Control' : 'Spec    '}    L: ${l.min ? l.min : '--'}    T: ${l.target ? l.target : '--'}    U: ${l.max ? l.max : '--'}`;
                            context.fillStyle = this.toColor(match[0].color);
                            context.fillText(line, posX + 9, pos.y + yOffset);
                            yOffset = yOffset + 16;
                        }
                    }
                }


            }
        }

    }.bind(this);

    toColor = function (num) {
        num >>>= 0;
        var b = num & 0xFF,
            g = (num & 0xFF00) >>> 8,
            r = (num & 0xFF0000) >>> 16,
            a = ((num & 0xFF000000) >>> 24) / 255;
        return "rgba(" + [r, g, b, a].join(",") + ")";

    }.bind(this);

    onShowTagInfo = async function (info) {

        var hitTestInfo = await this.getHitTestInfo(this.RClickPosition);
        if (hitTestInfo) {
            this.uTagInfo = hitTestInfo.uTags[0];
            this.setTagInfoDialogIsOpen = true;
        }

    }.bind(this);

    handleCloseTagInfoDialog = function (e) {
        this.setTagInfoDialogIsOpen = false;
    }.bind(this);

    onToggleTagValue = async function (info) {
        this.drawTagValue(undefined, undefined);
        this.setState({
            showTagValue: !this.state.showTagValue
        });
    }.bind(this);

    getInfoAtPosition = function (pos) {
        if (this.state.lmap.size > 0) {
            var matches = [];
            for (let key of this.state.lmap.keys()) {

                var xmin = key.x;
                var xmax = key.x + key.w;
                var ymin = key.y;
                var ymax = key.y + key.h;

                var inX = pos.x >= xmin && pos.x <= xmax;
                var inY = pos.y >= ymin && pos.y <= ymax;

                if (inX && inY) {
                    matches.push({
                        key: key,
                        val: this.state.lmap.get(key),
                    });
                }
            }

            if (matches.length > 0) {
                var sorted = matches.sort((a, b) => {
                    var asize = a.key.w * a.key.h;
                    var bsize = b.key.w * b.key.h;
                    return (asize < bsize);
                });

                return sorted[0].val;
            }
        }
    }.bind(this);

    removeHitTestInfoFromCache = async function (pos) {

        for (let key of this.state.lmap.keys()) {
            var xmin = key.x;
            var xmax = key.x + key.w;
            var ymin = key.y;
            var ymax = key.y + key.h;

            var inX = pos.x >= xmin && pos.x <= xmax;
            var inY = pos.y >= ymin && pos.y <= ymax;

            if (inX && inY) {
                var info = this.state.lmap.get(key);
                this.state.lmap.delete(key);
            }
        }

    }.bind(this);

    getHitTestInfo = async function (pos) {

        this.setState({ isHitTesting: true });

        var now = new Date();
        var info = this.getInfoAtPosition(pos);

        if (!info) {
            var data = await this.displayService.HitTest(pos.x, pos.y, this.props.sessionId, this.state.displayId);
            if (data) {
                info = JSON.parse(data);
                if (info && info.boundingRect && info.isEnabled) {

                    info.last = now;

                    var rect = info.boundingRect;
                    var key = {
                        x: parseFloat(rect.x),
                        y: parseFloat(rect.y),
                        w: parseFloat(rect.width),
                        h: parseFloat(rect.height)
                    };

                    this.state.lmap.set(key, info);
                }
            }
        }

        this.setState({ isHitTesting: false });

        return info;

    }.bind(this);

    detectMouseStopped = async function (pos, shiftKey) {

        if (this.state.dragType == DragTypes.TrendZoom) {
            return;
        }

        var info = await this.getHitTestInfo(pos);
        if (info != null) {

            if ((this.state.isSmartMouseEnabled || shiftKey) && info.type === HitTestTypes.WPFTrend) {

                var values = await this.displayService.GetTagValuesAtPosition(pos.x, pos.y, this.props.sessionId, this.state.displayId);
                this.setState({ isHitTesting: true }, async () => {

                    var data = await this.displayService.HitTest(pos.x, pos.y, this.props.sessionId, this.state.displayId);
                    if (data) {
                        info = JSON.parse(data);

                        var context = this.acontext;
                        if (context) {
                            context.clearRect(0, 0, this.acanvas.width, this.acanvas.height);
                        }

                        this.drawSmartMouse(info, pos.x, true);
                        this.drawSmartMouseToolTip(info, pos, values);
                    }

                    this.setState({ isHitTesting: false });
                });

            } else if (this.state.showTagValue && info.type !== HitTestTypes.WPFTrend) {
                this.drawTagValue(pos, info);
            } else if (info.type !== HitTestTypes.WPFTrend) {
                this.acanvas.style.cursor = "help";
            }
        }

    }.bind(this);

    sleep = function (ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }.bind(this);

    doMouseMove = async function (e) {

        var pos = getMousePos(e);

        if (this.timeout !== undefined) {
            clearTimeout(this.timeout);
        }

        this.timeout = setTimeout(() => this.detectMouseStopped(pos, e.shiftKey), 300);

        if (e.which == 1 && this.state.selectInfo) {

            if (this.state.isContextMenuOpen) {
                this.setState({ isContextMenuOpen: false });
                return;
            }

            if (this.state.selectInfo.type === HitTestTypes.WPFTrend && e.shiftKey) {

                this.setState({
                    dragType: DragTypes.TrendZoom,
                    isBusy: false
                }, () => {
                    var width = (pos.x - this.state.selectInfo.start.x);
                    if (this.acontext) {

                        this.acanvas.style.cursor = "ew-resize";
                        this.acontext.clearRect(0, 0, this.acanvas.width, this.acanvas.height);
                        this.acontext.beginPath();
                        this.acontext.fillStyle = 'rgba(36, 167, 255, 0.3)';
                        this.acontext.strokeStyle = 'blue';

                        this.acontext.rect(
                            this.state.selectInfo.start.x,
                            this.state.selectInfo.rect.y,
                            width,
                            this.state.selectInfo.rect.h);

                        this.acontext.fill();
                        this.acontext.stroke();
                    }
                });

                return;
            }
        }

        this.drawTagValue(undefined, undefined);
        if (this.state.dragType !== DragTypes.TrendZoom) {

            var info = await this.getInfoAtPosition(pos, 10);
            if (info) {
                if (this.state.isSmartMouseEnabled || e.shiftKey) {
                    this.drawSmartMouse(info, pos.x, false);
                }
            }
        }

        this.acanvas.style.cursor = "default";

    }.bind(this);

    doMouseDown = async function (e) {
        if (this.state.isTouchDevice) {
            return false;
        }

        if (this.state.isPinching || this.state.isPanning) {
            return false;
        }

        this.acanvas.style.cursor = "default";

        if (e.which == 1) {
            var pos = getMousePos(e);
            if (this.state.isContextMenuOpen) {
                this.setState({
                    isContextMenuOpen: false
                });
                return;
            }

            this.setState({
                isBusy: true
            });

            try {

                this.setState({
                    selectInfo: {
                        isEnabled: false,
                        showNew: false,
                        display: "",
                        type: "",
                        start: pos,
                        rect: { x: 0, y: 0, w: 0, h: 0 },
                    }
                });

                var info = await this.getHitTestInfo(pos);
                if (!info) {
                    this.clearTags();
                    return;
                }

                var rect = info.boundingRect;

                this.setState({
                    selectInfo: {
                        isEnabled: info.isEnabled,
                        showNew: info.showNew,
                        display: info.displayPath,
                        type: info.type,
                        uTags: info.uTags,
                        start: pos,
                        rect: {
                            x: parseFloat(rect.x),
                            y: parseFloat(rect.y),
                            w: parseFloat(rect.width),
                            h: parseFloat(rect.height)
                        },
                    }
                });

                if (info.isEnabled) {

                    if (e.ctrlKey) {

                        if (this.canTrend(info) && info.type != HitTestTypes.GridPanel) {
                            var highlight = false;

                            if (!this.state.tmap) {
                                this.setState({
                                    tmap: new Map()
                                });
                            }

                            for (var index in info.uTags) {
                                var t = info.uTags[index];
                                if (!this.objectPropInMapKey(this.state.tmap, 'uTag', t.uTag)) {
                                    highlight = true;
                                    this.state.tmap.set(t, {
                                        x: this.state.selectInfo.rect.x,
                                        y: this.state.selectInfo.rect.y,
                                        w: this.state.selectInfo.rect.w,
                                        h: this.state.selectInfo.rect.h,
                                    });
                                }
                            }

                            if (highlight) {
                                this.drawRect(this.state.selectInfo.rect.x,
                                    this.state.selectInfo.rect.y,
                                    this.state.selectInfo.rect.w,
                                    this.state.selectInfo.rect.h,
                                    false);

                                if (this.state.showTagValue) {
                                    this.drawTagValue(pos, info);
                                }
                            }
                        }
                    }
                    else {

                        this.clearTags();
                        this.setState({ lmap: new Map() })
                        if (info.type == HitTestTypes.HiddenCombo) {
                            this.handleTimespanClickOpen();
                        }
                        else if (info.type == HitTestTypes.DateTextBlock) {
                            var dtStart = new Date(info.start);
                            this.dateRangeStart = dtStart.toLocaleString();

                            var dtEnd = new Date(info.end);
                            this.dateRangeEnd = dtEnd.toLocaleString();

                            this.handleDateRangeClickOpen();
                        }
                        else if (info.type == HitTestTypes.Button) {
                            this.drawRect(
                                this.state.selectInfo.rect.x,
                                this.state.selectInfo.rect.y,
                                this.state.selectInfo.rect.w,
                                this.state.selectInfo.rect.h,
                                true);
                        }
                    }
                }
            }
            catch (err) {
                console.log(e);
            }
            finally {
                await this.displayService.performMouseDown(pos.x, pos.y, this.state.displayId);
                this.setState({
                    isBusy: false
                });
            }
        }
    }.bind(this);

    doMouseUp = async function (e) {        
        if (this.state.isTouchDevice) {
            return false;
        }
        this.acanvas.style.cursor = "default";
        if (e.which == 1) {
            var end = getMousePos(e);

            const timeout = 5000;
            var started = new Date();

            while (this.state.isHitTesting && (new Date() - started) < timeout) {
                await this.sleep(5);
            }
            if (this.state.selectInfo) {

                if (this.state.selectInfo.type == HitTestTypes.ErrorCloseButton) {
                    this.state.lmap = new Map();
                }
                if (this.state.dragType != DragTypes.None) {

                    var start = this.state.selectInfo.start;
                    var st = start;
                    var et = end;
                    if (start.x > end.x) {
                        st = end;
                        et = start;
                    }

                    this.setState({ isBusy: true });

                    switch (this.state.dragType) {
                        case DragTypes.TrendZoom:
                            await this.displayService.Zoom(st.x, st.y, et.x, et.y, this.props.sessionId, this.state.displayId);
                            this.acontext.clearRect(0, 0, this.acanvas.width, this.acanvas.height);
                            break;
                    }

                    this.setState({
                        isBusy: false,
                        dragType: DragTypes.None,
                        selectInfo: null,
                    });
                }
                else {
                    var display = this.state.selectInfo.display;
                    if (display) {
                        await this.openGraphicWindow(display, this.state.selectInfo.showNew)
                    }
                    else {
                        if (this.state.selectInfo.type != HitTestTypes.ErrorMoreDetails) {
                            await this.displayService.performMouseClick(end.x, end.y, this.props.sessionId, this.state.displayId);
                        }

                        if (!e.ctrlKey) {
                            this.acontext.clearRect(0, 0, this.acanvas.width, this.acanvas.height);
                        }
                    }
                    if (this.state.selectInfo.type === HitTestTypes.WPFTrend && e.shiftKey) {

                        this.setState({
                            isBusy: true,
                            inProgress: true
                        });

                        var created = await this.displayService.CreateStickyMouse(
                            end.x,
                            end.y,
                            this.props.sessionId,
                            this.state.displayId);

                        this.setState({
                            isBusy: false,
                            inProgress: false
                        });

                        return;
                    }

                    this.setState({ selectInfo: null });
                }
            }
        }

        return false;

    }.bind(this);

    openGraphicWindow = async function (displayPath, showNew) {
        try {

            var target = showNew ? "_blank" : "_self";

            if (displayPath.includes("://")) {
                window.open(displayPath, target, 'noreferrer, location=1');
            } else {
                var nfile = displayPath.substring(displayPath.lastIndexOf('/') + 1);
                var npath = displayPath.substr(0, displayPath.lastIndexOf('/') + 1);

                var current = this.state.filepath;
                var domain = current.substring(0, current.indexOf('/'));

                var start = current.indexOf('/');
                var end = current.lastIndexOf('/') + 1;

                var cpath = current.substr(start, end - start);
                var part = npath ? npath : cpath;

                var subfolder = window.location.pathname.substring(
                    0, window.location.pathname.indexOf(`/${domain}`));

                var path = `${subfolder}/${domain}${part}${encodeURIComponent(nfile)}`;
                if (nfile.includes("%")) {
                    path = `${subfolder}/${domain}${part}${nfile}`
                }

                if (displayPath.includes(':\\') || displayPath.includes(':/')) {
                    path = `${subfolder}/${part}${nfile}`
                }

                //var path = `/display/${domain}${part}${encodeURIComponent(nfile)}`;
                if (path) {
                    window.open(path, target, 'noreferrer');
                }
            }

            this.acontext.clearRect(0, 0, this.acanvas.width, this.acanvas.height);

        } catch (ex) {
            console.error(ex);
        }
    }

    doMouseDoubleClick = async function (event) {

        var pos = getMousePos(event);
        this.setState({ isBusy: true });
        this.RClickPosition = pos;

        if (this.state.passTagToProtocolHandler) {
            var info = await this.getHitTestInfo(pos);
            if (info && info.uTags.length > 0) {
                var b64 = btoa(info.uTags[0].uTag);
                window.open(`pvlaunch:${b64}`, '_blank', 'noreferrer');
            }
        } else {
            await this.onClone(event);
        }

        this.setState({ isBusy: false });

    }.bind(this);

    doKeyUp = function (e) {
        //if (!e.shiftKey) {
        //    this.setState({
        //        isBusy: true,
        //        isSmartMouseEnabled: false
        //    });
        //}
        return;
    }.bind(this);

    doKeyDown = function (e) {
        //if (e.shiftKey) {
        //    this.setState({
        //        isBusy: true,
        //        isSmartMouseEnabled: true
        //    });
        //}
        return;
    }.bind(this);

    onExportData = async function (e) {
        var hitTestInfo = await this.getHitTestInfo(this.RClickPosition);
        if (hitTestInfo) {
            this.uTags = hitTestInfo.uTags;
            this.exportStart = hitTestInfo.start;
            this.exportEnd = hitTestInfo.end;
            this.setExportDialogIsOpen = true;
        }
    }.bind(this);

    handleExportCancel = function (e) {
        this.setExportDialogIsOpen = false;
    }.bind(this);

    onExportDataChanged = function (e) {
        this.setState({ exportData: e });
    }.bind(this);

    handleExportOK = async function (e) {

        try {

            this.setState({
                inProgress: true
            });

            this.setExportDialogIsOpen = false;
            var edata = this.state.exportData;
            var start = JSON.stringify(edata.startDate);
            var end = JSON.stringify(edata.endDate);
            var tags = edata.uTags.map(ut => ut.uTag);

            var result = await this.displayService.ExportTagData(
                start.substring(1, start.length - 1),
                end.substring(1, end.length - 1),
                edata.step,
                tags,
                edata.includeTag,
                edata.includeValue,
                edata.includeQuality,
                edata.includeTimestamp,
                this.props.sessionId,
                this.state.displayId);

            if (result) {

                var values = JSON.parse(result);
                if (values) {

                    var sorted = values.sort((a, b) => (new Date(a.timestamp) < new Date(b.timestamp)) ? 1 : -1);
                    var tt = sorted.slice(0, tags.length);

                    var csvContent = "data:text/csv;charset=utf-8,";
                    csvContent += `,${tt.map(t => t.uTag).join(",")}\r\n`;
                    csvContent += `,${tt.map(t => t.simpleUTag).join(",")}\r\n`;
                    csvContent += `,${tt.map(t => t.description).join(",")}\r\n`;
                    csvContent += `,${tt.map(t => t.units).join(",")}\r\n`;

                    let index = 0;
                    while (index < sorted.length) {

                        var row = [];
                        row.push(sorted[index].timestamp);
                        for (let i = 0; i < tags.length; ++i) {
                            var tag = sorted[index];
                            row.push(tag.value);
                            index += 1;
                        }

                        csvContent += `${row.join(',')}\r\n`;
                    }
                    if (edata.format == "CSV") {

                        var link = document.getElementById("exportLink");
                        if (link) {
                            var encodedUri = encodeURI(csvContent);
                            link.setAttribute("href", encodedUri);
                            link.setAttribute("download", `${edata.uTag}.csv`);
                            link.click();
                        }
                    } else {

                        var qr = await navigator.permissions.query({ name: "clipboard-write" });
                        if (qr.state == "granted" || qr.state == "prompt") {
                            await navigator.clipboard.writeText(csvContent);
                        }
                    }
                }
            }

        } catch (ex) {
            debugger;
        } finally {
            this.setState({
                inProgress: false
            });
        }
    }.bind(this);

    handleDrop = async function (e) {

        this.setState({
            inProgress: true
        });

        var stopped = new Date();

        var data = e.dataTransfer.getData("utag");
        e.dataTransfer.clearData();

        var drag = document.getElementById("drag");
        drag.innerHTML = "";

        if (data) {
            var utags = JSON.parse(data);
            if (utags) {

                var pos = getMousePos(e);
                var hti = await this.getHitTestInfo(pos);
                if (hti && (hti.type == HitTestTypes.WPFTrend || hti.type == HitTestTypes.GridPanel)) {

                    if (!Array.isArray(utags)) {
                        utags = [utags];
                    }

                    for (var uTag of utags.map(r => r.uTag)) {
                        await this.displayService.ModifyTrendTags(pos.x, pos.y, uTag, false, this.props.sessionId, this.state.displayId);
                    }

                    this.acontext.clearRect(0, 0, this.acanvas.width, this.acanvas.height);
                    this.clearTrendCaches();
                }
            }
        }

        this.setState({
            inProgress: false
        });

    }.bind(this);

    handleDragStart = async function (e) {

        if (!e.ctrlKey && !e.shiftKey) {

            e.persist();

            var started = new Date();

            var drag = document.getElementById("drag");
            drag.innerHTML = "";
            e.dataTransfer.setDragImage(drag, -16, 0);

            const timeout = 5000;
            var started = new Date();

            while (this.state.isHitTesting && (new Date() - started) < timeout) {
                await this.sleep(5);
            }

            var selInfo = this.state.selectInfo;
            if (selInfo && selInfo.uTags && selInfo.uTags.length > 0) {

                if (selInfo.type == HitTestTypes.WPFTrend) {
                    e.preventDefault();
                }

                drag.innerHTML = selInfo.uTags[0].uTag;
                e.dataTransfer.setData("utag", JSON.stringify(selInfo.uTags[0]));
            } else {
                e.preventDefault();
            }
        }
        else {
            e.preventDefault();
        }

    }.bind(this);

    handlePinchStart = function (e) {
        var start = getMousePos({
            clientX: e.center.x,
            clientY: e.center.y,
        });

        this.setState({
            inProgress: true,
            zoomStart: start,
            isPinching: true
        });

    }.bind(this);

    handlePinchIn = function (e) {
        this.setState({ isPinchIn: true });
    }.bind(this);

    handlePinchOut = function (e) {
        this.setState({ isPinchIn: false });
    }.bind(this);

    handlePinchEnd = async function (e) {

        if (this.zoomHandle) {
            clearTimeout(this.zoomHandle);
        }

        if (!this.state.isClientSideGraphicMode) {
            this.zoomHandle = setTimeout(async () => {

                var amount = this.state.isPinchIn
                    ? (e.distance * 0.07) * -1
                    : (e.distance * 0.07);

                await this.displayService.MouseWheelZoom(
                    this.state.zoomStart.x,
                    this.state.zoomStart.y,
                    amount,
                    this.props.sessionId,
                    this.state.displayId);

                this.setState({
                    inProgress: false,
                    isPinching: false,
                    lmap: new Map()
                });

            }, 500);
        }
    }.bind(this);

    handlePanStart = function (e) {
        if (!this.state.isClientSideGraphicMode) {
            if (!e.srcEvent.shiftKey) {

                if (!this.state.isPinching && this.state.dragType == DragTypes.None) {
                    var start = getMousePos(e.srcEvent);
                    this.acanvas.style.cursor = "move";
                    this.setState({
                        inProgress: true,
                        isPanning: true,
                        panStart: start
                    });
                }
            }
        }
    }.bind(this);

    handlePanEnd = async function (e) {
        if (!this.state.isPinching && this.state.isPanning) {

            if (this.panHandle) {
                clearTimeout(this.panHandle);
            }

            var end = getMousePos(e.srcEvent);
            this.setState({ panEnd: end });

            this.panHandle = setTimeout(async () => {
                await this.displayService.Pan(
                    this.state.panStart.x,
                    this.state.panStart.y,
                    this.state.panEnd.x,
                    this.state.panEnd.y,
                    this.props.sessionId,
                    this.state.displayId);
                this.acanvas.style.cursor = "default";
                this.setState({
                    inProgress: false,
                    isPanning: false,
                    lmap: new Map()
                });

            }, 500);
        }
    }.bind(this);

    onTouchStart = async function (e) {
        if (this.state.isPinching || this.state.isPanning) {
            return false;
        }

        var pos = getMousePos(e.targetTouches[0]);

        this.setState({
            selectInfo: {
                isEnabled: false,
                showNew: false,
                display: "",
                type: "",
                start: pos,
                rect: { x: 0, y: 0, w: 0, h: 0 },
            }
        });

        var info = await this.getHitTestInfo(pos);
        if (!info) {
            this.clearTags();
            return;
        }

        var rect = info.boundingRect;

        this.setState({
            selectInfo: {
                isEnabled: info.isEnabled,
                showNew: info.showNew,
                display: info.displayPath,
                type: info.type,
                uTags: info.uTags,
                start: pos,
                rect: {
                    x: parseFloat(rect.x),
                    y: parseFloat(rect.y),
                    w: parseFloat(rect.width),
                    h: parseFloat(rect.height)
                },
            }
        });

        if (info.isEnabled) {
            if (info.type == HitTestTypes.HiddenCombo) {
                this.handleTimespanClickOpen();
            }
            else if (info.type == HitTestTypes.DateTextBlock) {
                this.dateRangeStart = info.start.substr(0, 10);
                this.dateRangeEnd = info.end.substr(0, 10);
                this.handleDateRangeClickOpen();
            }
            else if (info.type == HitTestTypes.Button) {
                this.drawRect(
                    this.state.selectInfo.rect.x,
                    this.state.selectInfo.rect.y,
                    this.state.selectInfo.rect.w,
                    this.state.selectInfo.rect.h,
                    true);
            }
        }

    }.bind(this);

    onTouchEnd = async function (e) {
        if (this.state.isPinching || this.state.isPanning) {
            return false;
        }

        var endPos = getMousePos(e.changedTouches[0]);

        if (this.state.selectInfo) {

            if (this.state.selectInfo.type == HitTestTypes.ErrorCloseButton) {
                this.state.lmap = new Map();
            }

            var display = this.state.selectInfo.display;
            if (display) {

                try {

                    var target = this.state.selectInfo.showNew ? "_blank" : "_self";

                    if (display.includes("://")) {
                        window.open(display, target, 'noreferrer, location=1');
                    } else {

                        var nfile = display.substring(display.lastIndexOf('/') + 1);
                        var npath = display.substr(0, display.lastIndexOf('/') + 1);

                        var current = this.state.filepath;
                        var domain = current.substring(0, current.indexOf('/'));

                        var start = current.indexOf('/');
                        var end = current.lastIndexOf('/') + 1;

                        var cpath = current.substr(start, end - start);
                        var part = npath ? npath : cpath;

                        var path = `/display/${domain}${part}${encodeURIComponent(nfile)}`;
                        if (nfile.includes("%")) {
                            path = `/display/${domain}${part}${nfile}`
                        }

                        //var path = `/display/${domain}${part}${encodeURIComponent(nfile)}`;
                        if (path) {
                            window.open(path, target, 'noreferrer');
                        }
                    }

                } catch (ex) {
                    console.error(ex);
                }
            }
            else {
                if (this.state.selectInfo.type != HitTestTypes.ErrorMoreDetails) {
                    await this.displayService.performMouseClick(endPos.x, endPos.y, this.props.sessionId, this.state.displayId);
                }
            }

            this.acontext.clearRect(0, 0, this.acanvas.width, this.acanvas.height);
            //if (this.state.selectInfo && this.state.selectInfo.type !== HitTestTypes.DateTextBlock || this.state.selectInfo.type !== HitTestTypes.HiddenCombo) {
            //    this.setState({ selectInfo: null });
            //}
        }

    }.bind(this);


    openInParcView = async function () {

        if (this.state.passTagToProtocolHandler) {
            var b64 = btoa(this.state.selectedTag.uTag);
            window.open(`pvlaunch:${b64}`, '_blank', 'noreferrer');
        } else {
            this.displayService.GetPVLaunchGuid(this.state.filepath, this.state.sessionId, this.state.displayId).then(url => {
                window.open(`pvlaunch:${url}`, '_blank', 'noreferrer');
            });
        }
    }.bind(this);

    render() {
        const { t, classes, browser, ...rest } = this.props;

        return (
            <React.Fragment>

                <Dialog open={this.setTimespanDialogIsOpen} aria-labelledby="form-dialog-title">
                    <DialogContent>
                        <TextField autoFocus margin="dense" id="outlined-basic" label={t("Timespan")} type="text" defaultValue={this.timespan} fullWidth onChange={this.handleTimespanChanged} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleTimespanCancel} color="primary">
                            {t("Cancel")}
                        </Button>
                        <Button onClick={this.handleTimespanOK} color="primary">
                            {t("Ok")}
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={this.setDateRangeDialogIsOpen} aria-labelledby="form-dialog-title">
                    <DialogContent>
                        <TextField autoFocus margin="dense" id="outlined-basic" label={t("StartDate")} type="datetime" defaultValue={this.dateRangeStart} fullWidth onChange={this.handleDateRangeStartChanged} />
                        <TextField autoFocus margin="dense" id="outlined-basic" label={t("EndDate")} type="datetime" defaultValue={this.dateRangeEnd} fullWidth onChange={this.handleDateRangeEndChanged} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleDateRangeCancel} color="primary">
                            {t("Cancel")}
                        </Button>
                        <Button onClick={this.handleDateRangeOK} color="primary">
                            {t("Ok")}
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={this.setAddTagDialogIsOpen} aria-labelledby="form-dialog-title">
                    <DialogContent>
                        <TextField autoFocus margin="dense" id="tagToAdd" label="Tag" type="text" fullWidth />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleAddTagCancel} color="primary">
                            {t("Cancel")}
                        </Button>
                        <Button onClick={this.handleAddTagOK} color="primary">
                            {t("Ok")}
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={this.setRemoveTagDialogIsOpen} aria-labelledby="form-dialog-title">
                    <DialogContent>
                        <InputLabel id="label">Select a Tag to remove.</InputLabel>
                        <Select id="tagToRemove"  value={this.state.selectedTag} onChange={this.onSelectedTagToRemoveChanged} fullWidth>
                            {this.state.selectedTags.map((tag) => {
                                if (tag) {
                                    return <MenuItem label={tag.uTag} value={tag.uTag}>{tag.uTag}</MenuItem>;
                                }
                            })}
                        </Select>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleRemoveTagCancel} color="primary">
                            {t("Cancel")}
                        </Button>
                        <Button onClick={this.handleRemoveTagOK} color="primary">
                            {t("Ok")}
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog maxWidth="lg" open={this.setTagInfoDialogIsOpen} aria-labelledby="form-dialog-title">
                    <DialogContent>
                        <TagInfo {...this.props} uTagInfo={this.uTagInfo} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseTagInfoDialog} variant="outlined" color="primary">
                            {t("Close")}
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog maxWidth="lg" open={this.setExportDialogIsOpen} aria-labelledby="form-dialog-title">
                    <DialogContent>
                        <ExportView {...this.props} uTags={this.uTags} start={this.exportStart} end={this.exportEnd} parentCallback={this.onExportDataChanged} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleExportCancel} color="primary">
                            {t("Ok")}
                        </Button>
                        <Button onClick={this.handleExportOK} color="primary">
                            {t("Export")}
                        </Button>
                    </DialogActions>
                </Dialog>

                {!this.state.embedded && this.state.isClientSideGraphicMode && (
                        <div id="rootContainer"
                            onDrop={e => this.handleDrop(e)}
                            onDragOver={e => e.preventDefault()}
                            onDragEnter={e => e.preventDefault()}>

                            <a id="exportLink" style={{
                                position: "absolute",
                                top: "-1000px"
                            }} />

                            <div id="drag" style={{
                                position: "absolute",
                                padding: "2px",
                                top: "-1000px",
                                fontSize: "12px",
                                color: "black",
                                width: "auto",
                                background: "white",
                                borderRadius: "3px"
                            }} />

                            <canvas id="annotationCanvas"
                                draggable="true"
                                onDragStart={e => this.handleDragStart(e)}
                                width={this.state.displayWidth}
                                height={this.state.displayHeight}
                                style={{
                                    marginTop: this.state.hideBreadcrumbs ? "0px" : "25px",
                                    position: "absolute"
                                }} />

                            <canvas id="displayCanvas"
                                width={this.state.displayWidth}
                                height={this.state.displayHeight}
                                style={{
                                    marginTop: this.state.hideBreadcrumbs ? "0px" : "25px",
                                }} />

                        </div>
                )}

                {!this.state.embedded && !this.state.isClientSideGraphicMode && (
                    <Hammer onPinchStart={this.handlePinchStart}
                        onPinchEnd={this.handlePinchEnd}
                        onPinchIn={this.handlePinchIn}
                        onPinchOut={this.handlePinchOut}
                        onPanStart={this.handlePanStart}
                        onPanEnd={this.handlePanEnd}
                        options={{
                            recognizers: {
                                pinch: { enable: true }
                            }
                        }}>

                        <div id="rootContainer"
                            onDrop={e => this.handleDrop(e)}
                            onDragOver={e => e.preventDefault()}
                            onDragEnter={e => e.preventDefault()}>

                            <a id="exportLink" style={{
                                position: "absolute",
                                top: "-1000px"
                            }} />

                            <div id="drag" style={{
                                position: "absolute",
                                padding: "2px",
                                top: "-1000px",
                                fontSize: "12px",
                                color: "black",
                                width: "auto",
                                background: "white",
                                borderRadius: "3px"
                            }} />

                            <canvas id="annotationCanvas"
                                draggable="true"
                                onDragStart={e => this.handleDragStart(e)}
                                width={this.state.displayWidth}
                                height={this.state.displayHeight}
                                style={{
                                    marginTop: this.state.hideBreadcrumbs ? "0px" : "25px",
                                    position: "absolute"
                                }} />

                            <canvas id="displayCanvas"
                                width={this.state.displayWidth}
                                height={this.state.displayHeight}
                                style={{
                                    marginTop: this.state.hideBreadcrumbs ? "0px" : "25px",
                                }} />

                        </div>
                    </Hammer>)}

                {this.state.embedded && this.state.isClientSideGraphicMode && (
                        <div id="rootContainer"
                            draggable="true"
                            onDrop={e => this.handleDrop(e)}
                            onDragOver={e => this.handleOnDragOver(e)}
                            onDragStart={e => this.handleDragStart(e)}>

                            <canvas id="annotationCanvas"
                                draggable="true"
                                width={this.state.displayWidth}
                                height={this.state.displayHeight}
                                style={{ position: "absolute" }}
                            />

                            <canvas id="displayCanvas"
                                width={this.state.displayWidth}
                                height={this.state.displayHeight}
                                draggable="true"
                            />

                        </div>

                )}

                {this.state.embedded && !this.state.isClientSideGraphicMode && (

                    <Hammer onPinchStart={this.handlePinchStart}
                        onPinchEnd={this.handlePinchEnd}
                        onPinchIn={this.handlePinchIn}
                        onPinchOut={this.handlePinchOut}
                        onPanStart={this.handlePanStart}
                        onPanEnd={this.handlePanEnd}
                        options={{
                            recognizers: {
                                pinch: { enable: true }
                            }
                        }}>

                        <div id="rootContainer"
                            draggable="true"
                            onDrop={e => this.handleDrop(e)}
                            onDragOver={e => this.handleOnDragOver(e)}
                            onDragStart={e => this.handleDragStart(e)}>

                            <canvas id="annotationCanvas"
                                draggable="true"
                                width={this.state.displayWidth}
                                height={this.state.displayHeight}
                                style={{ position: "absolute" }}
                            />

                            <canvas id="displayCanvas"
                                width={this.state.displayWidth}
                                height={this.state.displayHeight}
                                draggable="true"
                            />

                        </div>

                    </Hammer>)}

                {this.state.inProgress && (
                    <CircularProgress className={classes.displayProgress} />
                )}

            </React.Fragment>
        );
    }
}

export default connect(state => state.user)(
    withStyles(displayPageStyle)(withTranslation()(Display))
);

export function getMousePos(event) {
    var canvas = document.getElementById("displayCanvas");
    var rect = canvas.getBoundingClientRect();

    return {
        x: Math.round(((event.clientX - rect.left) / (rect.right - rect.left)) * canvas.width),
        y: Math.round(((event.clientY - rect.top) / (rect.bottom - rect.top)) * canvas.height),
    }
}

export function translatePosition(x1, y1, x2, y2) {
    return {
        x: x1,
        y: y1
    }
}