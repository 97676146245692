import { actionCreators } from "../store/ServerSettings";
import { actionCreators as errorActionCreators } from "../store/Errors";
import { store } from "index";
import { storagePrefix } from "helpers";
const fetchRetry = require("fetch-retry");
const baseUrl = process.env.PUBLIC_URL + "/api";

export const serverSettingsService = {
    hasDefaultLogin,
    getRootPath,
    getSafePaths,
    getAuthenticationOptions,
    getProviderOptions,
    getRefreshTokenDateTime,
    getDisplayResolutionBounds,
    fetchServerSettings,
    refreshServerSettings,
    getStoredServerSettings,
    isEmbedded,
    hideClientBreadcrumbs,
    hideClientHamburgerMenu,
    getUseServiceWorkers,
    launchInPARCviewEnabled,
    passTagToProtocolHandler,
};

var storage = sessionStorage;

function hasDefaultLogin() {
    return getServerSettings().then(settings => {
        return settings.hasDefaultLogin;
    });
}

function isEmbedded() {
    return getServerSettings().then(settings => {
        return settings.isEmbedded;
    });
}

function launchInPARCviewEnabled() {
    return getServerSettings().then(settings => {
        return settings.launchInPARCviewEnabled;
    });
}

function passTagToProtocolHandler() {
    return getServerSettings().then(settings => {
        return settings.passTagToProtocolHandler;
    });
}

function hideClientBreadcrumbs() {
    return getServerSettings().then(settings => {
        return settings.hideClientBreadcrumbs;
    });
}

function hideClientHamburgerMenu() {
    return getServerSettings().then(settings => {
        return settings.hideClientHamburgerMenu;
    });
}

function getRootPath() {
    return getServerSettings().then(settings => {
        return settings.rootPath;
    });
}

function getSafePaths() {
    return getServerSettings().then(settings => {
        return settings.safePaths;
    });
}

function getAuthenticationOptions() {
    return getServerSettings().then(settings => {
        return settings.authenticationOptions;
    });
}

async function getProviderOptions() {
    return await getServerSettings().then(settings => {
        return settings.providerOptions;
    });
}

function getRefreshTokenDateTime() {
    return getServerSettings().then(settings => {
        return settings.refreshAfterDateTime;
    });
}

function getDisplayResolutionBounds() {
    return getServerSettings().then(settings => {
        return settings.displayResolution;
    });
}

function getUseServiceWorkers() {
    return getServerSettings().then(settings => {
        return settings.useServiceWorkers;
    });
}

async function getServerSettings() {
    var serverSettings = await getStoredServerSettings();
    serverSettings = (serverSettings === null)
        ? await fetchServerSettings()
        : serverSettings;

    return serverSettings;
}

function getStoredServerSettings() {
    return JSON.parse(storage.getItem(storagePrefix() + "serverSettings"));
}

async function refreshServerSettings() {
    return fetchServerSettings()
        .then(serverSettings => {
            store.dispatch(actionCreators.setServerSettings(serverSettings));
            return serverSettings;
        });
}

function fetchServerSettings() {

    var codes = [503, 401, 404, 403, 500, 502];

    const requestOptions = {
        method: "GET",
        retries: 1440,
        retryDelay: 5000,
        retryOn: function (attempt, error, response) {

            var includes = codes.includes(response.status);
            if (includes) {
                store.dispatch(errorActionCreators.serverError(`Failed to retrieve Server Settings`));
            }

            return includes;
        }
    };

    return fetchRetry(`${baseUrl}/server/serversettings`, requestOptions)
        .then(handleResponse)
        .then(serverSettings => {
            if (serverSettings) {
                storage.setItem(storagePrefix() + "serverSettings", JSON.stringify(serverSettings));
            }
            //component.props.history.push("/displays");
            return serverSettings;
        });
}

function handleResponse(response) {
    if (response === "error") {
        return false;
    }
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        return data;
    });
}
