import React from "react";

import Layout from "./Layout.jsx";
import EmbeddedLayout from "./EmbeddedLayout.jsx";
import { withRouter } from "react-router";
import { matchPath } from 'react-router-dom'

import queryString from "query-string";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators as serverSettingsActions } from "store/ServerSettings";
import { actionCreators as userActions } from "store/User";

class LayoutControl extends React.Component {
    constructor(props) {
        super(props);
        const queryObj = queryString.parse(window.location.search);
  
        this.state = {
            embedded: queryObj.embedded == null ? false : queryObj.embedded,
            topush: null,
            isDisplayPage: this.isDisplayPage(this.props.history.location.pathname),
            isTagBrowserPage: this.isTagBrowserPage(this.props.history.location.pathname),
        };
        this.props.history.listen((location, action) => {
         
            this.setState({ isDisplayPage: this.isDisplayPage(location.pathname)});
           ;
        });
      
    
    }
    
    isTagBrowserPage(path) {
        let match = matchPath(path, {
            path: '/tags/:location?/:source+',
            exact: false,
            strict: false
        });

        if (!match) {
            match = matchPath(path, {
                path: '/tags',
                exact: false,
                strict: false
            });
        }

        return match != null
    }

    isDisplayPage(path) {
        let match = matchPath(path, {
            path: '/display/:filepath+',
            exact: false,
            strict: false
        });

        if (!match) {
            match = matchPath(path, {
                path: '/clone',
                exact: false,
                strict: false
            });
        }

        return match != null
    }
    componentDidMount() {
        const { classes } = this.props;
    }
    
    render() {
        return (
            <React.Fragment>
                {
                    this.props.serverSettings !== null && !this.state.embedded && !this.props.serverSettings.isEmbedded && (
                        <Layout isDisplayPage={this.state.isDisplayPage} isTagBrowserPage={this.state.isTagBrowserPage}>
                            {this.props.children}
                        </Layout>
                    )
                }
                {
                    this.props.serverSettings !== null && (this.state.embedded || this.props.serverSettings.isEmbedded) && (
                        <EmbeddedLayout>
                            {this.props.children}
                        </EmbeddedLayout>
                    )
                }
            </React.Fragment >
        );
    }
}
export default withRouter(
    connect(
        store => {
            const { user, serverSettings } = store;
            return { ...user, ...serverSettings };
        },
        dispatch =>
            bindActionCreators({ ...serverSettingsActions, ...userActions }, dispatch)
    )(LayoutControl)
);
