import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
// @material-ui/icons
import Menu from "@material-ui/icons/Menu";
// core components
import headerStyle from "assets/jss/material-kit-react/components/headerStyle.jsx";
import RoutedBreadCrumbs from "components/RoutedBreadCrumbs/RoutedBreadCrumbs.jsx";
import OnlyLoggedIn from "components/OnlyLoggedIn/OnlyLoggedIn.jsx";

import { Route } from "react-router-dom";

class Header extends React.Component {
    constructor(props) {
        super(props);


        var hb = false;
        var hh = false;
        if (this.props.serverSettings) {
            hb = this.props.serverSettings.hideClientBreadcrumbs;
            hh = this.props.serverSettings.hideClientHamburgerMenu;
        }

        this.state = {
            hideBreadcrumbs: hb,
            hideHamburger: hh,
            mobileOpen: false
        };

        this.handleDrawerToggle = this.handleDrawerToggle.bind(this);
        this.headerColorChange = this.headerColorChange.bind(this);
     
    }
    handleDrawerToggle() {
        this.setState({ mobileOpen: !this.state.mobileOpen });
    }
    componentDidMount() {
        if (this.props.changeColorOnScroll) {
            window.addEventListener("scroll", this.headerColorChange);
        }
    }

    headerColorChange() {
        const { classes, color, changeColorOnScroll } = this.props;
        const windowsScrollTop = window.pageYOffset;
        if (windowsScrollTop > changeColorOnScroll.height) {
            document.body
                .getElementsByTagName("header")[0]
                .classList.remove(classes[color]);
            document.body
                .getElementsByTagName("header")[0]
                .classList.add(classes[changeColorOnScroll.color]);
        } else {
            document.body
                .getElementsByTagName("header")[0]
                .classList.add(classes[color]);
            document.body
                .getElementsByTagName("header")[0]
                .classList.remove(classes[changeColorOnScroll.color]);
        }
    }
    componentWillUnmount() {
        if (this.props.changeColorOnScroll) {
            window.removeEventListener("scroll", this.headerColorChange);
        }
    }
    render() {
        const {
            classes,
            color,
            rightLinks,
            leftLinks,
            brand,
            fixed,
            absolute
        } = this.props;
        const appBarClasses = classNames({
            [classes.appBar]: true,
            [classes[color]]: color,
            [classes.absolute]: absolute,
            [classes.fixed]: fixed
        });
        const appBarDisplayClasses = classNames({
            [classes.appBarDisplayPage]: true,
            [classes[color]]: color,
            [classes.absolute]: absolute,
            [classes.fixed]: fixed
        });
        const brandComponent = <Button className={classes.title}>{brand}</Button>;

        if (this.props.isDisplayPage && this.state.hideBreadcrumbs) {
            return (<AppBar className={!this.props.isDisplayPage ? appBarClasses : appBarDisplayClasses}></AppBar>);
        }
        else {
            return (
                <AppBar className={!this.props.isDisplayPage ? appBarClasses : appBarDisplayClasses}>

                    <Toolbar className={!this.props.isDisplayPage ? classes.container : classes.containerDisplayPage}>
                        {leftLinks !== undefined ? brandComponent : null}
                        {!this.props.isDisplayPage && (
                            <React.Fragment>
                                <Hidden smDown implementation="css" className={classes.flex}>
                                    {leftLinks !== undefined ? (
                                        <Hidden smDown implementation="css">
                                            {leftLinks}
                                        </Hidden>
                                    ) : (
                                            brandComponent
                                        )}
                                </Hidden>
                                <Hidden smDown implementation="css">
                                    {rightLinks}
                                </Hidden>
                                <Hidden mdUp className={classes.flex}>
                                    <OnlyLoggedIn>
                                        <RoutedBreadCrumbs />
                                    </OnlyLoggedIn>
                                    {!this.state.hideHamburger && (<IconButton
                                        color="inherit"
                                        aria-label="open drawer"
                                        onClick={this.handleDrawerToggle}
                                        className={classes.button}
                                    >
                                        <Menu />
                                    </IconButton>)}
                                </Hidden>
                            </React.Fragment>)}
                        {this.props.isDisplayPage && (
                            <React.Fragment>
                                <OnlyLoggedIn>
                                    <RoutedBreadCrumbs />
                                </OnlyLoggedIn>
                                {!this.state.hideHamburger && (<IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    onClick={this.handleDrawerToggle}
                                    className={classes.button}
                                >
                                    <Menu />
                                </IconButton>)}
                            </React.Fragment>)}


                    </Toolbar>
                    {!this.props.isDisplayPage && !this.state.hideHamburger && (
                        <Hidden mdUp implementation="css">
                            <Drawer
                                variant="temporary"
                                anchor={"right"}
                                open={this.state.mobileOpen}
                                classes={{
                                    paper: classes.drawerPaper
                                }}
                                onClose={this.handleDrawerToggle}
                                onClick={this.handleDrawerToggle}
                            >
                                <div className={classes.appResponsive}>
                                    {leftLinks}
                                    {rightLinks}
                                </div>
                            </Drawer>
                        </Hidden>
                    )}
                    {this.props.isDisplayPage && !this.state.hideHamburger && (

                        <Drawer
                            variant="temporary"
                            anchor={"right"}
                            open={this.state.mobileOpen}
                            classes={{
                                paper: classes.drawerPaper
                            }}
                            onClose={this.handleDrawerToggle}
                            onClick={this.handleDrawerToggle}
                        >
                            <div className={classes.appResponsive}>
                                {leftLinks}
                                {rightLinks}
                            </div>
                        </Drawer>
                    )}

                </AppBar>
            );
        }
    }
}

Header.defaultProp = {
    color: "white"
};

Header.propTypes = {
    classes: PropTypes.object.isRequired,
    color: PropTypes.oneOf([
        "primary",
        "info",
        "success",
        "warning",
        "danger",
        "transparent",
        "white",
        "rose",
        "dark",
        "cobalt"
    ]),
    rightLinks: PropTypes.node,
    leftLinks: PropTypes.node,
    brand: PropTypes.string,
    fixed: PropTypes.bool,
    absolute: PropTypes.bool,
    // this will cause the sidebar to change the color from
    // this.props.color (see above) to changeColorOnScroll.color
    // when the window.pageYOffset is heigher or equal to
    // changeColorOnScroll.height and then when it is smaller than
    // changeColorOnScroll.height change it back to
    // this.props.color (see above)
    changeColorOnScroll: PropTypes.shape({
        height: PropTypes.number.isRequired,
        color: PropTypes.oneOf([
            "primary",
            "info",
            "success",
            "warning",
            "danger",
            "transparent",
            "white",
            "rose",
            "dark",
            "cobalt"
        ]).isRequired
    })
};

export default withStyles(headerStyle)(Header);
