import React from "react";

import { Breadcrumbs } from "material-ui-breadcrumbs";
import { displayListService } from "services/index.js";

const breadcrumbStyle = {
  height: "16px"
};
const breadcrumbItemStyle = {
  whiteSpace: "nowrap",
  color: "white",
  backgroundColor: "transparent",
  fontSize: "10pt"
};
const breadcrumbSeparatorStyle = {
  whiteSpace: "nowrap",
  color: "white",
  backgroundColor: "transparent"
};

export let instance = null;

class DisplayPathBreadCrumbs extends React.Component {
  constructor(props) {
      super(props);
      instance = this;
    this.state = {
      listinfo: this.props.match.params.listinfo,
      isRestricted: this.props.isRestricted,
      breadcrumbList: [{ label: "", uri: "javascript: void(0)" }]
    };
  }

  componentDidMount() {
    if (this.state.isRestricted || this.state.listinfo !== null) {
      this.refreshBreadCrumbs();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.state.listinfo !== this.props.match.params.listinfo ||
      this.state.isRestricted != this.props.isRestricted
    ) {
      this.setState({ listinfo: this.props.match.params.listinfo });
      this.setState({ isRestricted: this.props.isRestricted });
    }
    if (
      this.state.listinfo !== prevState.listinfo ||
      this.state.isRestricted != prevState.isRestricted
    ) {
      this.refreshBreadCrumbs();
    }
  }

    refreshBreadCrumbs() {
        if (!this.state.isRestricted) {
            displayListService
                .setupBreadcrumbs(this.state.listinfo)
                .then(result => this.setState({ breadcrumbList: result }));
        } else {
            displayListService
                .setupRestrictedBreadcrumbs(
                    this.state.listinfo,
                    this.props.match.params.id
                )
                .then(result => this.setState({ breadcrumbList: result }));
        }
    };

  // console.log(index); //returns the value from the folders_list

  render() {
    const separatorElement = (
      <div>
        <strong>></strong>
      </div>
    );
    return (
      <Breadcrumbs
        style={breadcrumbStyle}
        itemStyle={breadcrumbItemStyle}
        transparentBackground={true}
        steps={this.state.breadcrumbList}
        separator={separatorElement}
        separatorStyle={breadcrumbSeparatorStyle}
      />
    );
  }
}
export default DisplayPathBreadCrumbs;
