import React from "react";
import Display from "components/Display/Display.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import { displayService } from "services/display.service";

import displayListPageStyle from "assets/jss/material-kit-react/views/displayListPage.jsx";
class RestrictedDisplayPage extends React.Component {
  constructor(props) {
    super(props);

    // we use this to make the card to appear after the page has been rendered
  }

  render() {
    return (
      <React.Fragment>
        <Display
          {...this.props}
          displayService={new displayService().setRestricted(
            this.props.match.params.restrictedType,
            this.props.match.params.id
          )}
        />
      </React.Fragment>
    );
  }
}
export default withStyles(displayListPageStyle)(RestrictedDisplayPage);
