import { userService } from "services/user.service";

export async function authHeader() {
  // return authorization header with basic auth credentials
  let user = userService.getCurrentUser();
  if (user && user.token) {
    let refresh_time = userService.getRefreshTokenDateTime();

    var jwt = require("jsonwebtoken");
    var now_time = Math.floor(Date.now() / 1000);
    var decoded = jwt.decode(user.token);
    var refresh_datetime = decoded.nbf + refresh_time;
    //Check if the now is the time to refresh the token

    if (now_time > refresh_datetime) {
      userService.refreshUserToken();
    }

    return { ParcMobileAuthorization: "Bearer " + user.token };
  } else {
    return {};
  }
}
