
const displayMenuStyle = {
    root: {
        marginLeft: "10px",
        marginTop: "5px",
        marginBottom: "5x",
        fontSize: "10px"
    },
    icons: {
        width: "20px",
        height: "20px",
        marginRight: "5px",
        alignSelf: "center",
    },
};

export default displayMenuStyle;
