import React from "react";

import { SelectableMenuList } from "SML.js";
import withStyles from "@material-ui/core/styles/withStyles";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import RoutedBreadCrumbs from "components/RoutedBreadCrumbs/RoutedBreadCrumbs.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CircularProgress from "@material-ui/core/CircularProgress";
import Hidden from "@material-ui/core/Hidden";
import displayListPageStyle from "assets/jss/material-kit-react/views/displayListPage.jsx";
import { isNullOrUndefined } from "util";

class DisplayList extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden"
    };
  }

  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    setTimeout(
      function() {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );
  }

  render() {
    const { classes, browser, ...rest } = this.props;
    const index = this.props.location ? this.props.location.pathname : "/";

    return (
      <GridItem xs={12} sm={12} md={12} lg={12}>
        <Card className={classes[this.state.cardAnimaton]}>
          <form className={classes.form}>
            <Hidden smDown implementation="css">
              <CardHeader color="primary" className={classes.cardHeader}>
                <RoutedBreadCrumbs />
              </CardHeader>
            </Hidden>
            <CardBody id="Card" className={classes.menuList}>
              {this.props.inProgress && (
                <div style={{ textAlign: "center" }}>
                  <CircularProgress className={classes.progress} />
                </div>
              )}

              {!this.props.inProgress && this.props.folderList.length > 0 && (
                            <SelectableMenuList
                                items={this.props.folderList}
                                onIndexChange={this.props.handleChange}
                                index={index}
                                className={classes.selectableMenuList}
                />
              )}
              {(isNullOrUndefined(this.props.folderList.length) ||
                this.props.folderList.length === 0) && (
                <div
                  style={{ margin: "15px 15px 15px 15px", fontWeight: "bold" }}
                >
                  No PARCmobile compatible files in this folder.
                </div>
              )}
            </CardBody>
          </form>
        </Card>
      </GridItem>
    );
  }
}

export default withStyles(displayListPageStyle)(DisplayList);
