import React from "react";

import withStyles from "@material-ui/core/styles/withStyles";
import Display from 'components/Display/Display.jsx';
import DisplayMenu from 'components/DisplayMenu/DisplayMenu.jsx';
import { instance as DisplayInstance } from 'components/Display/Display.jsx';
import { displayService } from "services/display.service";
import displayPageStyle from "assets/jss/material-kit-react/views/displayPage.jsx";
import { serverSettingsService } from "services/serversettings.service";

class DisplayPage extends React.Component {
    constructor(props) {
        super(props);
        // we use this to make the card to appear after the page has been rendered
    }

    render() {

        function handleEvent(e) {
            e.preventDefault();
            e.persist();
            DisplayInstance.onHandleContextMenu(e);
        }

        return (
            <div onContextMenu={handleEvent}>
                <a id="link"></a>
                <Display {...this.props} serverSettings={serverSettingsService.getStoredServerSettings()} displayService={new displayService()} />
                <DisplayMenu />
            </div>
        );
    }
}
export default withStyles(displayPageStyle)(DisplayPage);
