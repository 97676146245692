import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "store/User";

class OnlyLoggedIn extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    if (this.props.user !== null) {
      return <React.Fragment>{this.props.children}</React.Fragment>;
    } else {
      return null;
    }
  }
}

export default connect(
  state => state.user,
  dispatch => bindActionCreators(actionCreators, dispatch)
)(OnlyLoggedIn);
