import { authHeader } from "helpers";
import { fetchService } from "services/fetch.service";
import { actionCreators } from "../store/User";
import { actionCreators as errorActionCreators } from "../store/Errors";
import { store } from "index";
import {storagePrefix } from "helpers";
import { history } from "index";
import Keycloak from "keycloak-js";

const baseUrl = process.env.PUBLIC_URL + "/api";
const fetchRetry = require("fetch-retry");

export const userService = {
    login,
    logout,
    loginDefaultUser,
    getAll,
    isLoggedIn,
    getCurrentUser,
    getRootPath,
    isRestricted,
    getRefreshTokenDateTime,
    refreshUserToken,
    hasDefaultLogin,
    getRawRootPath,
    keycloak: null,
    keycloakEnabled: null,
    initKeycloak
};

function login(username, password, authentication, provider, querystring) {
  let formData = new FormData();
  formData.append("username", username);
  formData.append("password", password);
  formData.append("authentication", authentication);
  formData.append("provider", provider);
  const requestOptions = {
    method: "POST",
    body: formData
  };

  return fetchService
    .fetchWithErrorHandling(`${baseUrl}/users/authenticate`, requestOptions)
    .then(handleResponse)
    .then(user => {
      // login successful if there's a user in the response
      if (user) {
        // store user details and basic auth credentials in local storage
        // to keep user logged in between page refreshes
        user.authdata = window.btoa(username + ":" + password);
        setUser(user);

        if (querystring.toLowerCase().startsWith("?returnurl")) {
          const returnUri = querystring.slice(11);
          history.push(returnUri);
        } else {
          history.push("/");
        }
      }
      return user;
    });
}

async function logout(component) {
  //send logout notification to back end to log off of ua server and remove app domain
  const requestOptions = {
    method: "POST",
    headers: await authHeader()
  };

  return await fetch(`${baseUrl}/users/logout`, requestOptions).then(
    handleResponse
  );

  function handleResponse(response) {
    // remove user from local storage to log user out
    removeUser();
    //show login screen

    //history.push("/signin");

    return response.ok;
  }
}

function loginDefaultUser() {

    var codes = [503, 401, 404, 403, 500, 502];
  const requestOptions = {
    method: "POST",
    retries: 1440,
      retryDelay: 60000,
      retryOn: function (attempt, error, response) {

          var includes = codes.includes(response.status);
          if (includes) {
              store.dispatch(errorActionCreators.serverError(`Login with default user failed.`));
          }
          
          return includes;
      },
      //retryOn: [503, 401, 404, 403, 500, 502]
  };

  return fetchRetry(`${baseUrl}/users/authenticate_defaultuser`, requestOptions)
    .then(handleResponse)
    .then(user => {
      // login successful if there's a user in the response
      if (user) {
        // store user details and basic auth credentials in local storage
        // to keep user logged in between page refreshes
        //user.authdata = window.btoa(username + ":" + password);
        setUser(user);
        //component.props.history.push("/displays");
      }
      return user;
    });
}
function setUser(user) {
    localStorage.setItem(storagePrefix() + "user", JSON.stringify(user));
  store.dispatch(actionCreators.setUser(user));
}
function removeUser() {
    localStorage.removeItem(storagePrefix() + "user");
  store.dispatch(actionCreators.removeUser());
}

function getAll() {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  return fetchService
    .fetchWithErrorHandling(`${baseUrl}/users`, requestOptions)
    .then(handleResponse);
}

function handleResponse(response) {
  if (response === "error") {
    return Promise.resolve(false);
  }

  return response.text().then(text => {
    const data = text && JSON.parse(text);

    return data;
  });
}

async function isLoggedIn() {
  var user = await getCurrentUser();
  return user !== null;
}

function isRestricted() {
  var user = getCurrentUserNonAsync();
  if (user != null) {
    return getCurrentUserNonAsync().isRestricted;
  } else {
    return null;
  }
}
function getCurrentUserNonAsync() {
    const user = JSON.parse(localStorage.getItem(storagePrefix() + "user"));
  if (user === null) {
    return null;
  }
  return user;
}

async function hasDefaultLogin() {
  var state = store.getState();
  return state.serverSettings.serverSettings.hasDefaultLogin;
}

function getCurrentUser() {
    return JSON.parse(localStorage.getItem(storagePrefix() + "user"));
}

function getRootPath() {
  const user = getCurrentUser();
  return user.rootPath
    .replace("\\\\", "")
    .replace(/\\/g, "/")
    .toLowerCase();
}

function getRawRootPath() {
    const user = getCurrentUser();
    return user.rootPath;
}

function getRefreshTokenDateTime() {
  var state = store.getState();

  return state.serverSettings.serverSettings.refreshAfterDateTime;
}

function refreshUserToken() {
  let formData = new FormData();
  const requestOptions = {
    method: "POST"
  };

  return fetch(`${baseUrl}/users/authenticate_refresh`, requestOptions)
    .then(handleRefreshUserToken)
    .then(user => {
      // authenticate_refresh returns user with a updated token
      if (user) {
        setUser(user);
      }
      return user;
    });

  function handleRefreshUserToken(response) {
    if (!response.ok) {
      logout();
      return Promise.resolve(false);
    }
    return response;
  }
}

async function initKeycloak(onAuthenticatedCallback) {
  const config = await fetch(`${baseUrl}/KeyCloak/GetKeyCloakConfiguration`).then(x => x.json());
  if (config.keyCloakEnabled === "true") {
    userService.keycloakEnabled = true;
    const kc = userService.keycloak = new Keycloak({
      url: config.url,
      realm: 'dataPARC',
      clientId: 'dataPARC.Nexus.UI'
    });
    const authenticated = await kc.init({
        onLoad: 'login-required',
        pkceMethod: 'S256'
    });
    if (!authenticated) {
      kc.login();
    }
    else {
      onAuthenticatedCallback();
    }
  }
  else {
    userService.keycloakEnabled = false;
    onAuthenticatedCallback();
  }
}