import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import TagGridStyle from "assets/jss/material-kit-react/components/tagGridStyle.jsx";
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import Button from '@material-ui/core/Button';
import CircularProgress from "@material-ui/core/CircularProgress";
import { Info, Timeline, MultilineChart, Sync } from "@material-ui/icons";
import { TagBrowserTabs } from "helpers/enums.js";
import { withTranslation } from "react-i18next";

class TagGrid extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selected: [],
            selectionMode: 'radio',
        }
    }

    componentDidMount = function () {
        window.addEventListener("keydown", this.onKeyDown, false);
        window.addEventListener("keyup", this.onKeyUp, false);
    }

    onKeyDown = function (e) {
        if (e.ctrlKey && this.state.selectionMode == 'radio') {
            this.setState({
                selectionMode: 'checkbox'
            });
        }
    }.bind(this);

    onKeyUp = function (e) {
        if (!e.ctrlKey && this.state.selectionMode == 'checkbox') {
            this.setState({
                selectionMode: 'radio'
            });
        }
    }.bind(this);

    onRefresh = function (e) {
        this.props.onRefresh();
    }.bind(this);

    onDragStart = function(e) {
        e.persist();
        var drag = document.getElementById("drag");
        e.dataTransfer.setDragImage(drag, -16, 0);
        drag.innerHTML = this.state.selected.map(s => s.uTag).join(' ');

        var uTagArrayCopy = structuredClone(this.state.selected);

        for (var i = 0; i < uTagArrayCopy.length; i++) {
            uTagArrayCopy[i].uTag = uTagArrayCopy[i].uTag.replace("/", "//");
        }

        e.dataTransfer.setData("utag", JSON.stringify(uTagArrayCopy));
    }.bind(this);

    onRowDoubleClicked = function (e, row, rowIndex) {
        this.props.onRowDoubleClicked(row);
    }.bind(this);

    onScroll = function (e) {
        var div = document.getElementById("scroll");
        if (div.offsetHeight + div.scrollTop >= div.scrollHeight) {
            this.props.onScrollEnd(e);
        }
    }.bind(this);

    onSearch = function (event) {
        if (this.searchTimeout !== undefined) {
            clearTimeout(this.searchTimeout);
        }

        this.setState({ search: event.target.value });

        if (event.target) {
            this.searchTimeout = setTimeout(() => this.props.onSearch(this.state.search), 500);
        }

    }.bind(this);

    render() {

        const { t,  classes } = this.props;

        const sortCaret = (order, column) => {
            if (!order) return (<span>&nbsp;&nbsp;&#x2193;&#x2191;</span>);
            else if (order === 'asc') return (<span>&nbsp;&nbsp;&#x2193;<font color="black">&#x2191;</font></span>);
            else if (order === 'desc') return (<span>&nbsp;&nbsp;<font color="black">&#x2193;</font>&#x2191;</span>);
            return null;
        }

        const select = {
            mode: this.state.selectionMode,
            clickToSelect: true,
            hideSelectColumn: true,
            onSelect: this.props.selectionChanged,
            style: {
                backgroundColor: 'cornflowerblue',
                color: 'white',
            }
        }

        const headerStyle = {
            fontWeight: 'bold',
            fontSize: 'small',
            padding: "3px",
            paddingLeft: "5px",
            backgroundColor: "gray",
            color: "white"
        };

        const columnStyle = {
            fontWeight: 'normal',
            fontSize: 'small',
            overflow: "hidden",
            overflowWrap: "break-word",
            textOverflow: "ellipsis",
            padding: "3px",
            whiteSpace: "nowrap",
            direction: "rtl",

        };

        const inputStyle = {
            marginTop: ".2rem",
            marginLeft: "6px",
            fontSize: "small",
            minWidth: "265px",
            width: "auto"
        };

        const columns = [{
            dataField: 'displayName',
            text: t("Tag"),
            sort: true,
            style: columnStyle,
            headerStyle: headerStyle,
            sortCaret: sortCaret,
        }, {
            dataField: 'description',
            text: t("Description"),
            sort: true,
            style: columnStyle,
            headerStyle: headerStyle,
            sortCaret: sortCaret,
        }, {
            dataField: 'units',
            text: t("Units"),
            sort: true,
            style: columnStyle,
            headerStyle: headerStyle,
            sortCaret: sortCaret,
        }, {
            dataField: 'process',
            text: t("Process"),
            sort: true,
            style: columnStyle,
            headerStyle: headerStyle,
            sortCaret: sortCaret,
        }, {
            dataField: 'plotMin',
            text: t("PlotMin"),
            sort: true,
            style: columnStyle,
            headerStyle: headerStyle,
            sortCaret: sortCaret,
        }, {
            dataField: 'plotMax',
            text: t("PlotMax"),
            sort: true,
            style: columnStyle,
            headerStyle: headerStyle,
            sortCaret: sortCaret,
        }];

        if (this.props.selectedTab == TagBrowserTabs.Master) {
            columns.push({
                dataField: 'source',
                text: t('Source'),
                sort: true,
                style: columnStyle,
                headerStyle: headerStyle,
                sortCaret: sortCaret,
            });
        }

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                console.log(`clicked on row with index: ${rowIndex}`);

                var selected = this.state.selected;

                if (this.state.selectionMode == "checkbox") {
                    if (selected.includes(row)) {
                        selected = selected.filter(r => r === row);
                    } else {
                        selected.push(row);
                    }
                } else {
                    selected = [row];
                }

                this.setState({
                    focusedTag: row,
                    selected: selected
                });
            },
            onDoubleClick: this.onRowDoubleClicked,
            onMouseEnter: (e, row, rowIndex) => {
                this.setState({ focusedTag: row });
                var parent = e.target.parentElement;
                if (parent) {
                    parent.setAttribute("draggable", "true");
                }
            },
            onDragStart: this.onDragStart,
        };

        const { SearchBar } = Search;

        return (
            <React.Fragment>

                <ToolkitProvider
                    keyField="uTag"
                    data={this.props.tags}
                    columns={columns}
                    search>
                    {
                        props => (
                            <div className={this.props.className}>

                                <div className={classes.searchContainer}>

                                    {this.props.selectedTab == TagBrowserTabs.Source && (<SearchBar {...props.searchProps} className={classes.search} placeholder={t("Search")} />)}
                                    {this.props.selectedTab == TagBrowserTabs.Master && (<input className="form-control" type="text" style={inputStyle} placeholder={t("Search")} onChange={this.onSearch} />)}
                                    
                                    <Button className={classes.refresh} color="success" onClick={this.onRefresh} disabled={this.props.isBusy}>
                                        <Sync className={classes.icons} />
                                    </Button>
                                </div>

                                <div id="scroll" className={classes.table} onScroll={this.onScroll}>
                                    <BootstrapTable
                                        bootstrap4
                                        rowEvents={rowEvents}
                                        selectRow={select}
                                        {...props.baseProps}
                                    />
                                </div>
                            </div>
                        )
                    }
                </ToolkitProvider>

                {this.props.isBusy && (<CircularProgress className={classes.progress} />)}

            </React.Fragment>
        );
    };
}

export default withStyles(TagGridStyle)(withTranslation()(TagGrid));