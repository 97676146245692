import React from "react";

import DisplayPathBreadCrumbs from "components/DisplayPathBreadCrumbs/DisplayPathBreadCrumbs.jsx";

import { userService } from "services/user.service";
import { PrivateRoute } from "components/PrivateRoute/PrivateRoute.jsx";
import { Route, Redirect } from "react-router";

class RoutedBreadCrumbs extends React.Component {
  render() {
    return (
      <React.Fragment>
        {userService.isRestricted() === false && (
          <React.Fragment>
            <PrivateRoute exact path="/" component={DisplayPathBreadCrumbs} />
            <PrivateRoute
              path="/displays/:listinfo*"
              component={DisplayPathBreadCrumbs}
            />
            <PrivateRoute
              path="/display/:listinfo+"
              component={DisplayPathBreadCrumbs}
            />
          </React.Fragment>
        )}

        {userService.isRestricted() === true && (
          <React.Fragment>
            <Route
              exact
              path="/"
              render={props => (
                <DisplayPathBreadCrumbs {...props} isRestricted="true" />
              )}
            />
            <Route
              path="/restricteddisplays/:id?/:listinfo*"
              render={props => (
                <DisplayPathBreadCrumbs {...props} isRestricted="true" />
              )}
            />
            <Route
              path="/restricteddisplay/:restrictedType/:id/:listinfo+"
              render={props => (
                <DisplayPathBreadCrumbs {...props} isRestricted="true" />
              )}
            />
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}
export default RoutedBreadCrumbs;
