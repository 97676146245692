import React from "react";

import DisplayList from "components/DisplayList/DisplayList.jsx";
import withStyles from "@material-ui/core/styles/withStyles";

import displayListPageStyle from "assets/jss/material-kit-react/views/displayListPage.jsx";

import { userService } from "services/user.service";
import { displayListService } from "services/index.js";
import { connect } from "react-redux";

import { withRouter } from "react-router";

const styles = {
  body_header: {
    margin: "0px",
    padding: "10px"
  },
  responsive_toggler: {
    width: 250,
    paddingTop: 15
  }
};

class DisplaysPage extends React.Component {
  constructor(props) {
    super(props);

    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden",
      listInfo: this.props.match.params.listinfo,
      folderList: [{ value: "", primaryText: "" }],
      inProgress: true
    };

    this.children = props.children;
    this.refreshDisplayListInfo = this.refreshDisplayListInfo.bind(this);
  }

  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    setTimeout(
      function() {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );

    if (
      this.state.listInfo === "" ||
      this.state.listInfo === "/" ||
      this.state.listInfo === undefined
    ) {
        const root = userService.getRootPath();
      this.props.history.push("/displays/" + root);
    } else {
      this.refreshDisplayListInfo();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.match.params.listinfo != this.props.match.params.listinfo) {
      this.setState({
        listInfo: this.props.match.params.listinfo
      });
    }
    if (prevState.listInfo !== this.state.listInfo) {
      this.refreshDisplayListInfo();
    }
  }

  refreshDisplayListInfo() {
    if (this.state.listInfo != null) {
        this.setState({ inProgress: true });
        displayListService.getDisplayList(this.state.listInfo, userService.getRawRootPath().includes('\\\\')).then(result => {
        if (result) {
          this.setState({ folderList: result, inProgress: false });
          document.getElementById("Card").scrollTo(0, 0);
        }
      });
    }
  }
    handleChange = (event, index) => {

        var newLocation = encodeURIComponent(index.name);

        if (index.shortcutTarget !== undefined && index.shortcutTarget !== "") {
            newLocation = "/display/" + index.shortcutTarget.replace("\\\\", "");
        } else if (index.fileType == "Folder") {
            if (!this.props.location.pathname.endsWith("/")) {
                newLocation = this.props.location.pathname + "/" + encodeURIComponent(index.name);
            }
        } else {
            newLocation = "/display/" + this.state.listInfo + "/" + encodeURIComponent(index.name);
        }

        this.setState({ inProgress: true });
        this.props.history.push(newLocation);//.replace("%", "‰"));
    };

  render() {
    const { classes, browser, ...rest } = this.props;
    const index = this.props.location ? this.props.location.pathname : "/";

    return (
      <React.Fragment>
        <DisplayList
          {...this.props}
          handleChange={this.handleChange}
          folderList={this.state.folderList}
          inProgress={this.state.inProgress}
        />
      </React.Fragment>
    );
  }
}

export default withRouter(
  connect(store => store)(withStyles(displayListPageStyle)(DisplaysPage))
);
