import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import TagBrowserSectionStyle from "assets/jss/material-kit-react/components/tagBrowserSectionStyle.jsx";
import { TagBrowserTabs } from "helpers/enums.js"
import { withTranslation } from "react-i18next";

const placeholder = '--------';
class TagBrowserSection extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            filterColumn: props.filterColumn,
            filterRegex: props.filterRegex,
            selectedTab: props.selectedTab,
            isLoaded: false,
        }
    }

    async componentDidMount() {

    }

    locationChanged = async function (e) {

        var location = e.target.value;
        if (location) {
            this.props.locationChanged(location);
        }

    }.bind(this);

    sourceChanged = async function (e) {

        var source = e.target.value;
        if (source) {

            var parts = source.split('|');
            if (parts.length == 2) {
                this.props.sourceChanged(parts[0], parts[1]);
            } else {
                this.props.sourceChanged(parts[0]);
            }
        }

    }.bind(this);

    onFilterChecked = function (e) {

        this.setState({
            [e.target.name]: e.target.checked
        });

        this.props.onFilterChecked({
            name: e.target.name,
            checked: e.target.checked
        });

    }.bind(this);

    render() {

        const { t, classes } = this.props;

        function GetSourceOptions(sources, source) {
            if (sources.length > 0) {

                var results = sources.map((s, i) => {

                    var parts = s.split(';');
                    var name = parts[0];
                    var id = parts[1];
                    var key = `${id}|${name}`;

                    return (name === source)
                        ? <option value={key} selected>{name}</option>
                        : <option value={key}>{name}</option>;
                });

                if (!source) {
                    results.splice(0, 0, <option value="" selected disabled>{placeholder}</option>);
                } else {
                    results.splice(0, 0, <option value="" disabled>{placeholder}</option>);
                }

                return results;
            }
        }

        function GetLocationOptions(locations, location) {
            if (locations.length > 0) {
                return locations.map((name, i) => {
                    return (location === name)
                        ? <option value={name} selected>{name}</option>
                        : <option value={name}>{name}</option>;
                });
            }
        }


        const isMaster = this.props.selectedTab == TagBrowserTabs.Master;
        const isRecent = this.props.selectedTab == TagBrowserTabs.Recent;

        return (
            <React.Fragment>
                <div className={this.props.className}>

                    <div className={isMaster ? classes.sectionContainerModified : classes.sectionContainer}>

                        <div className={isRecent ? classes.sectionRowHidden : classes.sectionRow}>
                            <span className={classes.sectionSpan}>{t("Location")}</span>

                            <select className={classes.selects}
                                onChange={this.locationChanged}
                                disabled={this.props.locationsLoading}>

                                {GetLocationOptions(this.props.locations, this.props.location)}

                            </select>

                        </div>

                        <div className={isMaster || isRecent ? classes.sectionRowHidden : classes.sectionRow}>
                            <span className={classes.sectionSpan}>{t("Source")}</span>

                            <select className={classes.selects}
                                onChange={this.sourceChanged}
                                disabled={this.props.sourcesLoading}>

                                {GetSourceOptions(this.props.sources, this.props.source)}

                            </select>

                        </div>

                        <span className={isRecent ? classes.sectionHeaderModified : classes.sectionHeader}>{isRecent ? "Most recently used Tags." : t("SourceOptions") }</span>

                    </div>

                    <div className={classes.sectionContainer}>
                        <div className={classes.sectionRowModified}>
                            <input className={classes.sectionSpan}
                                type="checkbox"
                                name="filterColumn"
                                checked={this.state.filterColumn}
                                onClick={this.onFilterChecked}
                            />

                            <span className={classes.sectionSpan}>{t("FilterByColumn")}</span>
                        </div>
                        <div className={classes.sectionRowModified}>
                            <input className={classes.sectionSpan}
                                type="checkbox"
                                name="filterRegex"
                                checked={this.state.filterRegex}
                                onClick={this.onFilterChecked}
                            />
                            <span className={classes.sectionSpan}>{t("AdvancedFiltering")}</span>
                        </div>
                        <span className={classes.sectionHeader}>{t("Filtering")}</span>
                    </div>

                </div>
            </React.Fragment>
        );
    };
}

export default withStyles(TagBrowserSectionStyle)(withTranslation()(TagBrowserSection));