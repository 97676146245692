import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Header from "components/Header/Header.jsx";
import navbarsStyle from "assets/jss/material-kit-react/views/componentsSections/navbarsStyle.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import { serverSettingsService } from "services/serversettings.service";

const dashboardRoutes = [];

class NavMenu extends React.Component {
    render() {
        const { classes, ...rest } = this.props;
        return (
            <Header
                color="cobalt"
                routes={dashboardRoutes}
                brand="PARCmobile"
                isDisplayPage={this.props.isDisplayPage}
                serverSettings={serverSettingsService.getStoredServerSettings()}
                rightLinks={<HeaderLinks />}
                fixed
                {...rest}
            />
        );
    }
}
export default withStyles(navbarsStyle)(NavMenu);
