import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import aboutPageStyle from "assets/jss/material-kit-react/views/aboutPage.jsx";
import { serverSettingsService } from "services/serversettings.service";
import { withTranslation } from 'react-i18next';

class AboutPage extends React.Component {
    constructor(props) {
        super(props);

        var ss = serverSettingsService.getStoredServerSettings();
        this.state = {
            version: ss.version
        };
    }

    render() {
        const { t, classes } = this.props;
        return (
            <div className={classes.version}>PARCMobile {t("Version")}: {this.state.version}</div>
        );
    }
}
export default withStyles(aboutPageStyle)(withTranslation()(AboutPage));
