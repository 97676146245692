import { userService } from "services/user.service";
import { isNullOrUndefined } from "util";
import { storagePrefix } from "helpers";

const uuidv4 = require("uuid/v4");
const login = "LOGIN";
const logout = "LOGOUT";
const loginDefaultUser = "LOGIN_DEFAULT_USER";
const setUser = "SET_USER";
const removeUser = "REMOVE_USER";
const unauthorizedError = "UNAUTHORIZED_ERROR";

const initialState = {
  user: userService.getCurrentUser(),
  sessionId: sessionId()
};

export const actionCreators = {
  login: (username, password, authentication, provider, querystring) => ({
    type: login,
    username: username,
    password: password,
    authentication: authentication,
    provider: provider,
    querystring: querystring
  }),
  setUser: user => ({ type: setUser, user: user }),
  removeUser: () => ({ type: removeUser }),
  loginDefaultUser: () => ({ type: loginDefaultUser }),
  logout: () => ({ type: logout })
};

export const reducer = (state, action) => {
  state = state || initialState;

  if (action.type === login) {
    userService.login(
      action.username,
      action.password,
      action.authentication,
      action.provider,
      action.querystring
    );
    return { ...state };
  }
  if (action.type === setUser) {
    return { ...state, user: action.user };
  }
  if (action.type === removeUser) {
    return { ...state, user: null };
  }

  if (action.type === loginDefaultUser) {
    userService.loginDefaultUser();
    return { ...state };
  }
  if (action.type === logout) {
    userService.logout();
    return { ...state };
    }

    if (action.type === unauthorizedError) {
        if (!userService.user) {
            userService.logout();
            return { ...state };
        }
    }

  return state;
};

function sessionId() {
    if (isNullOrUndefined(sessionStorage.getItem(storagePrefix() + "sessionId"))) {
        sessionStorage.setItem(storagePrefix() + "sessionId", uuidv4());
  }
    return sessionStorage.getItem(storagePrefix() + "sessionId");
}
