import React from "react";

import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

import CircularProgress from "@material-ui/core/CircularProgress";

import Button from "components/CustomButtons/Button.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";

import CardBody from "components/Card/CardBody.jsx";

import CardFooter from "components/Card/CardFooter.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";

import loginPageStyle from "assets/jss/material-kit-react/views/loginPage.jsx";

import { userService } from "services/user.service";
import { connect } from "react-redux";

import { bindActionCreators } from "redux";
import { isNullOrUndefined } from "util";
import { actionCreators as serverSettingsActions } from "store/ServerSettings";

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden",
      authenticationOptions: [],
      providerOptions: [],
      username: "",
      password: "",
      inProgress: false
    };

    this.children = props.children;
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleChange = event => {
    this.props[event.target.name](event.target.value);
  };
  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    setTimeout(
      function() {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );

    var authenticationOptions = this.props.serverSettings.serverSettings.authenticationOptions.map(
      item => {
        return {
          value: item,

          primaryText: item
        };
      }
    );

    this.setState({ authenticationOptions: authenticationOptions });

    var providerOptions = this.props.serverSettings.serverSettings.providerOptions.map(
      item => {
        return {
          value: item.guid,

          primaryText: item.name
        };
      }
    );

    this.setState({ providerOptions: providerOptions });
  }

  handleSubmit() {
    this.setState({ inProgress: true });
    userService
      .login(
        this.state.username,
        this.state.password,
        this.props.serverSettings.selectedAuthentication,
        this.props.serverSettings.selectedProvider,
        this.props.location.search
      )
      .then(() => this.setState({ inProgress: false }));
  }

  render() {
    const { classes, ...rest } = this.props;

    return (
      <GridItem xs={12} sm={12} md={4}>
        <Card className={classes[this.state.cardAnimaton]}>
          <form className={classes.form}>
            <CardBody>
              <CustomSelect
                labelText="Authentication"
                id="authentication"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  name: "setSelectedAuthentication",
                  id: "selectedAuthentication-simple",

                  endAdornment: (
                    <InputAdornment position="end">
                      <Icon className={classes.inputIconsColor}>
                        lock_outline
                      </Icon>
                    </InputAdornment>
                  )
                }}
                value={this.props.serverSettings.selectedAuthentication}
                items={this.state.authenticationOptions}
                handleChange={this.handleChange}
              />
              {this.props.serverSettings.selectedAuthentication ===
              "Username + Password" ? (
                <React.Fragment>
                  <CustomInput
                    labelText="Username"
                    id="username"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      name: "username",
                      onChange: this.handleInputChange,
                      onKeyPress: (event, field) => {
                        if (event.key === "Enter") {
                          event.preventDefault(); // Prevent form submission if button present
                          document.getElementById("pass").focus();
                        }
                      },
                      value: this.state.username,
                      endAdornment: (
                        <InputAdornment position="end">
                          <Icon className={classes.inputIconsColor}>
                            person
                          </Icon>
                        </InputAdornment>
                      )
                    }}
                  />

                  <CustomInput
                    labelText="Password"
                    id="pass"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "password",
                      value: this.state.password,
                      name: "password",
                      onChange: this.handleInputChange,
                      onKeyPress: event => {
                        if (event.key === "Enter") {
                          this.handleSubmit();
                        }
                      },
                      endAdornment: (
                        <InputAdornment position="end">
                          <Icon className={classes.inputIconsColor}>
                            lock_outline
                          </Icon>
                        </InputAdornment>
                      )
                    }}
                  />
                  <CustomSelect
                    labelText="Provider"
                    id="provider"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      name: "setSelectedProvider",
                      id: "selectedProvider-simple"
                    }}
                    value={this.props.serverSettings.selectedProvider}
                    items={this.state.providerOptions}
                    handleChange={this.handleChange}
                  />
                </React.Fragment>
              ) : null}

              {this.state.inProgress && (
                <CircularProgress className={classes.displayProgress} />
              )}
            </CardBody>
            <CardFooter className={classes.cardFooter}>
              <Button
                simple
                color="primary"
                size="lg"
                disabled={this.state.inProgress}
                onClick={this.handleSubmit}
              >
                SignIn
              </Button>
            </CardFooter>
          </form>
        </Card>
      </GridItem>
    );
  }
}

export default connect(
  state => state,
  dispatch => bindActionCreators({ ...serverSettingsActions }, dispatch)
)(withStyles(loginPageStyle)(LoginPage));
