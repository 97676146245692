import { actionCreators } from "../store/Errors";
import { store } from "index";
export const fetchService = {
  fetchWithErrorHandling
};

async function fetchWithErrorHandling(input, init, withErrorUiDispatch = true) {
    return await fetch(input, init).then(handleErrors);
}

function handleErrors(response, withErrorUiDispatch) {  
  if (!response.ok) {
    if (response.status === 500) {
      if(withErrorUiDispatch) {
        store.dispatch(actionCreators.serverError(response.statusText));
      }
      return "error";
    }
    if (response.status === 401) {
      if(withErrorUiDispatch) {
        store.dispatch(actionCreators.unauthorizedError());
      }
      return "error";
    }
  }
  return response;
}
