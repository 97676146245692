const setTimeZone = (timeZone) => {
    localStorage.setItem('timeZone', timeZone);
};

const getTimeZone = () => {
    const tz = localStorage.getItem('timeZone');
    return tz || 'America/New_York';
};

const setI18nextLanguage = (code) => {
    localStorage.setItem('i18nextLng', code);
};

const getI18nextLanguage = () => {
    const languageCode = localStorage.getItem('i18nextLng');
    return languageCode;
};

export const localStorageApi = {
    setTimeZone,
    getTimeZone,
    setI18nextLanguage,
    getI18nextLanguage
};