import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators as errorActions } from "store/Errors";
import { withSnackbar } from "notistack";

import { actionCreators as userActions } from "store/User";

import PropTypes from "prop-types";
class SnackbarErrorHandler extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.serverErrors.length !== this.props.serverErrors.length &&
      (this.props.lastServerErrorDisplayed === null ||
        Date.now() - this.props.lastServerErrorDisplayed > 20000)
    ) {
      this.props.updateServerErrorDisplayed();
      this.props.enqueueSnackbar(
        this.props.serverErrors[this.props.serverErrors.length - 1],
        { variant: "error" }
      );
    }
    if (
      prevProps.unauthorizedErrors !== this.props.unauthorizedErrors &&
      (this.props.lastUnauthorizedDisplayed === null ||
        Date.now() - this.props.lastUnauthorizedDisplayed > 20000)
    ) {
      if (
        this.props.serverSettings !== null &&
        !this.props.serverSettings.hasDefaultLogin
      ) {
        this.props.updateUnauthorizedDisplayed();
        this.props.enqueueSnackbar("Unauthorized", { variant: "warning" });
      }

      if (this.props.user !== null) {
        this.props.logout();
      }
    }
  }
  render() {
    return <React.Fragment />;
  }
}
SnackbarErrorHandler.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired
};
export default connect(
  store => {
    const { user, errors, serverSettings } = store;
    return { ...user, ...errors, ...serverSettings };
  },
  dispatch => bindActionCreators({ ...userActions, ...errorActions }, dispatch)
)(withSnackbar(SnackbarErrorHandler));
