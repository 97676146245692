import React from 'react';
import { connect } from "react-redux";
import Form from 'react-bootstrap/Form';
import DateTimePicker from 'react-datetime-picker';

class Export extends React.Component {

    constructor(props) {
        super(props);
        this.uTags = this.props.uTags;
        //this.alarms = [];

        //if (this.uTagInfo.alarms) {
        //    this.uTagInfo.alarms.forEach(a => this.alarms.push({
        //        uTag: a.alarmUTag,
        //        inAlarm: a.inAlarm,
        //    }));
        //}

        try {

            var rx = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/gm;
            var start = this.props.start.match(rx)[0];
            var end = this.props.end.match(rx)[0];

            this.state = {
                uTags: this.uTags,
                startDate: start,
                endDate: end,
                step: 1,
                includeTag: true,
                includeValue: true,
                includeQuality: true,
                includeTimestamp: true,
                format: 'CSV'
            };

            this.props.parentCallback(this.state);

        } catch (ex) {

        }
    };

    componentDidMount(){ }

    componentDidUpdate(prevProps, prevState, snapshot) { }

    onStartChange = function (e) {
        this.setState({ startDate: e.target.value }, () => {
            this.props.parentCallback(this.state)
        });
    }.bind(this);

    onEndChange = function (e) {
        this.setState({ endDate: e.target.value }, () => {
            this.props.parentCallback(this.state)
        });
    }.bind(this);

    valuesChanged = function (e) {
        this.setState({ [e.target.name]: e.target.value }, () => {
            this.props.parentCallback(this.state)
        });
    }.bind(this);

    render() {

        const gridContainerStyle = {
            display: "inline-grid",
            gridTemplateColumns: "auto auto"
        }
        const sectionStyle = {
            margin: "5px",
            marginBottom: "20px"
        }
        const sectionStyleWide = {
            gridColumn: "1/3"
        }
        const sectionHeader = {
            fontWeight: "bold",
            textDecoration:"underline",
            margin: "auto",
            textAlign: "center",
            width:"100%"
        } 

        return (
            <React.Fragment>

                <Form>
                    <div style={gridContainerStyle}>

                        <div style={sectionStyleWide}>
                            <Form.Label style={sectionHeader}>Date Range</Form.Label>

                            <Form.Group>

                                <div style={{ display: "grid"}}>
                                    <Form.Label style={{ marginTop: "7px", marginBottom: "1px" }}>Start Time</Form.Label>

                                    <input type="datetime-local"
                                        onChange={this.onStartChange}
                                        value={this.state.startDate}
                                        pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}" required
                                    />

                                    {/*<DateTimePicker*/}
                                    {/*    onChange={this.onStartChange}*/}
                                    {/*    value={this.state.startDate}*/}
                                    {/*/>*/}

                                    <Form.Label style={{ marginTop: "7px", marginBottom: "1px" }}>End Time</Form.Label>

                                    <input type="datetime-local"
                                        onChange={this.onEndChange}
                                        value={this.state.endDate}
                                        pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}" required
                                    />

                                    {/*<DateTimePicker*/}
                                    {/*    onChange={this.onEndChange}*/}
                                    {/*    value={this.state.endDate}*/}
                                    {/*/>*/}
                                </div>

                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Step Size</Form.Label>
                                <Form.Label style={{ fontSize:"10px", fontStyle:"italic", fontWeight:"bold", marginLeft:"5px"}}>(Minutes)</Form.Label>
                                <Form.Control id="step" type="number" placeholder="Step Size" min="1" defaultValue={this.state.step} onChange={e => this.valuesChanged({
                                    target: {
                                        name: e.target.id,
                                        value: e.target.value
                                    }
                                })} />
                            </Form.Group>

                        </div>

                        <div style={sectionStyle} onChange={e => this.valuesChanged({ target: { name: e.target.id, value: e.target.checked } })}>
                            <Form.Label style={sectionHeader}>Attributes</Form.Label>
                            <Form.Group>
                                <Form.Check id="includeTag" type="checkbox" defaultChecked={this.state.includeTag} label="Tag Name" />
                                <Form.Check id="includeValue" type="checkbox" defaultChecked={this.state.includeValue} label="Value" />
                                <Form.Check id="includeQuality" type="checkbox" defaultChecked={this.state.includeQuality} label="Quality" />
                                <Form.Check id="includeTimestamp" type="checkbox" defaultChecked={this.state.includeTimestamp} label="Timestamp" />
                            </Form.Group>
                        </div>

                        <div style={sectionStyle} onClick={e => this.valuesChanged({ target: { name: e.target.name, value: e.target.value } })}>
                            <Form.Label style={sectionHeader}>Format</Form.Label>
                            <Form.Group style={sectionStyle}>

                                <Form.Check type="radio"
                                    name="format"
                                    defaultChecked
                                    label="CSV"
                                    value="CSV"
                                />

                                <Form.Check type="radio"
                                    name="format"
                                    label="Clipboard"
                                    value="Clipboard"
                                />

                            </Form.Group>
                        </div>

                    </div>

                </Form>

            </React.Fragment>
        );
    }
}

export default connect(state => state.user)((Export));