import React from "react";
import { Route, Redirect, Switch } from "react-router";
import LayoutControl from "./LayoutControl.jsx";
import DisplayPage from "views/DisplayPage/DisplayPage.jsx";
import DisplaysPage from "views/DisplaysPage/DisplaysPage.jsx";
import RestrictedDisplaysPage from "views/RestrictedDisplaysPage/RestrictedDisplaysPage.jsx";
import RestrictedDisplayPage from "views/RestrictedDisplayPage/RestrictedDisplayPage.jsx";
import LoginPage from "views/LoginPage/LoginPage.jsx";
import AboutPage from "views/AboutPage/AboutPage.jsx";
import LogoutPage from "views/LogoutPage/LogoutPage.jsx";
import BookmarkRedirect from "components/BookmarkRedirect";
import { PrivateRoute } from "components/PrivateRoute/PrivateRoute.jsx";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators as serverSettingsActions } from "store/ServerSettings";
import { actionCreators as userActions } from "store/User";
import { withRouter } from "react-router";
import { isNullOrUndefined } from "util";
import TagBrowserPage from "../views/TagBrowserPage/TagBrowserPage.jsx";

class Routes extends React.Component {
    render() {
        const { classes, ...rest } = this.props;
       
        return (
            <React.Fragment>
                <LayoutControl>
                    <PrivateRoute exact path="/logout" component={LogoutPage} />
                    <Route path="/signin" component={LoginPage} />
                    <Switch>
                        <Route
                            exact
                            path="*/Login.aspx"
                            render={() => <Redirect to={{ pathname: "/signin" }} />}
                        />
                        <Route path="*/*.aspx" component={BookmarkRedirect} />
                    </Switch>

                    {this.props.user && !this.props.user.isRestricted && (
                        <React.Fragment>
                            <PrivateRoute exact path="/" component={DisplaysPage} />
                            <PrivateRoute
                                path="/displays/:listinfo*"
                                component={DisplaysPage}
                            />
                            <PrivateRoute path="/display/:filepath+" component={DisplayPage} />
                            <PrivateRoute path="/clone" component={DisplayPage} />
                            <PrivateRoute path="/tags" component={TagBrowserPage} />
                        </React.Fragment>
                    )}

                    {this.props.user && this.props.user.isRestricted && (
                        <React.Fragment>
                            <PrivateRoute exact path="/" component={RestrictedDisplaysPage} />
                            <PrivateRoute
                                path="/restricteddisplays/:id?/:listinfo*"
                                component={RestrictedDisplaysPage}
                            />
                            <PrivateRoute
                                path="/restricteddisplay/:restrictedType/:id/:filepath+"
                                component={RestrictedDisplayPage}
                            />
                        </React.Fragment>
                    )}
                    {isNullOrUndefined(this.props.user) &&
                        !this.props.serverSettings.hasDefaultLogin && (
                            <React.Fragment>
                                <Route exact path="/" component={LoginPage} />
                                <Route path="/displays/:listinfo*" component={LoginPage} />
                                <Route path="/display/:filepath+" component={LoginPage} />
                            <Route path="/clone/:tagName+" component={LoginPage} />
                            <PrivateRoute path="/tags" component={TagBrowserPage} />

                                <Route
                                    path="/restricteddisplays/:id?/:listinfo*"
                                    component={LoginPage}
                                />
                                <Route
                                    path="/restricteddisplay/:restrictedType/:id/:filepath+"
                                    component={LoginPage}
                                />
                            </React.Fragment>
                        )}
                    <Route path="/about" component={AboutPage} />
                </LayoutControl>
            </React.Fragment>
        );
    }
}
export default withRouter(
    connect(
        store => {
            const { user, serverSettings } = store;
            return { ...user, ...serverSettings };
        },
        dispatch =>
            bindActionCreators({ ...serverSettingsActions, ...userActions }, dispatch)
    )(Routes)
);
