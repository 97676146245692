import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import OnlyLoggedIn from "components/OnlyLoggedIn/OnlyLoggedIn.jsx";
import OnlyLoggedOut from "components/OnlyLoggedOut/OnlyLoggedOut.jsx";
import OnlyLoggedInNotDefaultUser from "components/OnlyLoggedInNotDefaultUser/OnlyLoggedInNotDefaultUser.jsx";
import { AccountCircle, Home, LocalOffer, Info, ExitToApp } from "@material-ui/icons";
import Button from "components/CustomButtons/Button.jsx";
import headerLinksStyle from "assets/jss/material-kit-react/components/headerLinksStyle.jsx";
import { userService } from "services/user.service";
import { withTranslation } from "react-i18next";
class HeaderLinks extends React.Component {
   
    constructor(props) {
        super(props);
    }

    openWindow = function (url, target, title, width, height) {
        const y = window.top.outerHeight / 2 + window.top.screenY - (height / 2);
        const x = window.top.outerWidth / 2 + window.top.screenX - (width / 2);
        var tbw = window.open(url, target, `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=yes, copyhistory=no, width=${width}, height=${height}, top=${y}, left=${x}`);
        tbw.onload = function () { setTimeout(function () { tbw.document.title = title; }, 500); }
        return true;
    }.bind(this);

    openTagBrowser = async function () {

        var loggedIn = await userService.isLoggedIn();
        if (loggedIn) {
            return this.openWindow(`tags`, '_blank', 'Tag Browser', 800, 600);
        }
        
    }.bind(this);

    clearAllCookies = function () {
        const cookies = document.cookie.split(";");
    
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i];
            const eqPos = cookie.indexOf("=");
            const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        }
    }

    render() {
        const { t, classes } = this.props;

        
        return (
            <List className={classes.list}>               
                <OnlyLoggedIn>

                    <ListItem className={classes.listItem}>
                        <Button color="transparent"
                                target="_blank"
                                className={classes.navLink}>

                            <Link to="/">
                                <Home className={classes.icons} /> {t("Displays")}
                            </Link>

                        </Button>
                    </ListItem>

                    <ListItem className={classes.listItem}>
                        <Button color="transparent"
                                target="_blank"
                                onClick={this.openTagBrowser}
                                className={classes.navLink}>

                            <span>
                                <a>
                                    <LocalOffer className={classes.icons} /> {t("TagBrowser")} ({t("Beta")})
                                </a>                                
                            </span>
                            
                        </Button>
                    </ListItem>

                    <ListItem className={classes.listItem}>
                        <Button color="transparent"
                            target="_blank"
                            className={classes.navLink}>

                            <Link to="/About">
                                <Info className={classes.icons} /> {t("About")}
                            </Link>

                        </Button>
                    </ListItem>

                    {userService.keycloakEnabled &&
                        <ListItem className={classes.listItem}>
                            <Button color="transparent"
                                className={classes.navLink}
                                onClick={() => {
                                    localStorage.clear();
                                    sessionStorage.clear();
                                    this.clearAllCookies();
                                    userService.keycloak.logout();
                                }}>

                                <ExitToApp className={classes.icons} /> {t("Logout")}

                            </Button>
                        </ListItem>
                    }

                </OnlyLoggedIn>


                <OnlyLoggedOut>
                    <ListItem className={classes.listItem}>
                        <Button
                            color="transparent"
                            target="_blank"
                            className={classes.navLink}
                            >
                            <Link to="/signin">
                                <AccountCircle className={classes.icons} />{" "}
                                {this.props.user ? this.props.user.name : "SignIn"}
                            </Link>
                        </Button>
                    </ListItem>
                </OnlyLoggedOut>


                <OnlyLoggedInNotDefaultUser>

                    <ListItem className={classes.listItem}>
                        <Button
                            color="transparent"
                            target="_blank"
                            className={classes.navLink}
                        >

                            <Link to="/logout">
                                <AccountCircle className={classes.icons} />{" "}
                                {this.props.user
                                    ? this.props.user.name.includes("|")
                                        ? this.props.user.name.split("|").pop()
                                        : this.props.user.name
                                    : ""}
                            </Link>
                        </Button>
                    </ListItem>

                </OnlyLoggedInNotDefaultUser>
            </List>
        );
    }
}

export default connect(state => state.user)(
    withStyles(headerLinksStyle)(withTranslation()(HeaderLinks))
);
