import { serverSettingsService } from "services/serversettings.service";
import { isNullOrUndefined } from "util";
import { storagePrefix } from "helpers";
const setServerSettings = "SET_SERVERSETTINGS";
const refreshServerSettings = "REFRESH_SERVERSETTINGS";
const setSelectedProvider = "SET_SELECTED_PROVIDER";
const setSelectedAuthentication = "SET_SELECTED_AUTHENTICATION";

const initialState = {
  serverSettings: serverSettingsService.getStoredServerSettings(),
  selectedAuthentication: getSelectedAuthentication(),
  selectedProvider: getSelectedProvider()
};

function getSelectedAuthentication() {
    if (isNullOrUndefined(localStorage.getItem(storagePrefix() + "selectedAuthentication"))) {
    if (isNullOrUndefined(serverSettingsService.getStoredServerSettings())) {
      return "";
    } else {
      return serverSettingsService.getStoredServerSettings()
        .authenticationOptions[0];
    }
  } else {
        return localStorage.getItem(storagePrefix() + "selectedAuthentication");
  }
}
function getSelectedProvider() {

    if (isNullOrUndefined(localStorage.getItem(storagePrefix() + "selectedProvider"))) {
        if (isNullOrUndefined(serverSettingsService.getStoredServerSettings())) {
            return "";
        } else {
            var settings = serverSettingsService.getStoredServerSettings();
            if (settings.providerOptions.length > 0) {
                return serverSettingsService.getStoredServerSettings().providerOptions[0].guid
            } else {
                return "";
            }
        }
    } else {
        return localStorage.getItem(storagePrefix() + "selectedProvider");
    }

}
export const actionCreators = {
  setServerSettings: serverSettings => ({
    type: setServerSettings,
    serverSettings: serverSettings
  }),
  refreshServerSettings: () => ({ type: refreshServerSettings }),
  setSelectedProvider: selectedProvider => ({
    type: setSelectedProvider,
    selectedProvider: selectedProvider
  }),
  setSelectedAuthentication: selectedAuthentication => ({
    type: setSelectedAuthentication,
    selectedAuthentication: selectedAuthentication
  })
};

export const reducer = (state, action) => {
  state = state || initialState;

  if (action.type === setServerSettings) {
    return {
      ...state,
      serverSettings: action.serverSettings,
      selectedAuthentication: getSelectedAuthentication(),
      selectedProvider: getSelectedProvider()
    };
  }
  if (action.type === refreshServerSettings) {
    serverSettingsService.refreshServerSettings();
    return { ...state };
  }
  if (action.type === setSelectedProvider) {
      localStorage.setItem(storagePrefix() + "selectedProvider", action.selectedProvider);
    return { ...state, selectedProvider: action.selectedProvider };
  }
  if (action.type === setSelectedAuthentication) {
      localStorage.setItem(storagePrefix() + 
      "selectedAuthentication",
      action.selectedAuthentication
    );
    return { ...state, selectedAuthentication: action.selectedAuthentication };
  }

  return state;
};
