import React from "react";
import { connect } from "react-redux";

class OnlyLoggedInNotDefaultUser extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        {this.props.user &&
          this.props.serverSettings &&
          !this.props.serverSettings.hasDefaultLogin &&
          this.props.children}
      </React.Fragment>
    );
  }
}

export default connect(store => {
  const { user, serverSettings } = store;
  return { ...user, ...serverSettings };
})(OnlyLoggedInNotDefaultUser);
