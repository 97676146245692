import React from "react";
import LayoutControl from "./components/LayoutControl.jsx";
import EmbeddedLayout from "./components/EmbeddedLayout.jsx";
import { withRouter } from "react-router";
import Routes from "./components/Routes.jsx";
import queryString from "query-string";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Layout from "./components/Layout.jsx";
import CircularProgress from "@material-ui/core/CircularProgress";
import SnackbarErrorHandler from "components/SnackbarErrorHandler/SnackbarErrorHandler";
import { SnackbarProvider } from "notistack";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators as serverSettingsActions } from "store/ServerSettings";
import { actionCreators as userActions } from "store/User";
import "./i18n";
import { localStorageApi } from "./services/localStorageService";
import { withTranslation } from "react-i18next";
import i18next from "i18next";

class App extends React.Component {
    constructor(props) {
        super(props);
        const queryObj = queryString.parse(window.location.search);

        this.state = {
            embedded: queryObj.embedded == null ? false : queryObj.embedded,
            topush: null,
            loadingI18n: true,
        };
    }

    componentWillMount() {
        if (this.props.serverSettings === null) {
            this.props.refreshServerSettings();
        }
        if (
            this.props.user === null &&
            this.props.serverSettings !== null &&
            this.props.serverSettings.hasDefaultLogin
        ) {
            this.props.loginDefaultUser();
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            this.props.user === null &&
            this.props.serverSettings !== null &&
            this.props.serverSettings.hasDefaultLogin
        ) {
            this.props.loginDefaultUser();
        }
    }
    componentDidMount() {
        document.title = "PARCmobile";
        if (this.state.topush !== null) {
            this.props.history.push(this.state.topush);
        }

        const languageCode = localStorageApi.getI18nextLanguage();
        i18next
            .changeLanguage(languageCode)
            .then((res) => {
                this.setState({ loadingI18n: false });
                console.log("Changing a load", languageCode);
            })
            .catch((e) => {
                this.setState({ loadingI18n: false });
                console.warn("error changing the language");
            });
    }

    render() {
        const { t } = this.props;
        return (
            <React.Fragment>
                {this.props.loadingI18n && <div>Loading...</div>}
                {(!this.props.loadingI18n && !this.props.serverSettings) ||
                    (!this.props.user &&
                        this.props.serverSettings &&
                        this.props.serverSettings.hasDefaultLogin) ? (
                    <React.Fragment>
                        <Layout>
                            <Card>
                                <CardHeader color="primary">{t("Initializing")}</CardHeader>
                                <CardBody>
                                    <div>
                                        {!this.props.serverSettings && (
                                            <CircularProgress size={23} />
                                        )}
                                        {t("RetrievingServerSettings")}
                                    </div>

                                    {!this.props.user &&
                                        this.props.serverSettings &&
                                        this.props.serverSettings.hasDefaultLogin && (
                                            <div>
                                                <CircularProgress size={23} />
                                                {t("LoggingInWithDefaultUser")}
                                            </div>
                                        )}
                                </CardBody>
                            </Card>
                        </Layout>
                    </React.Fragment>
                ) : (
                    <Routes />
                )}

                <SnackbarProvider maxSnack={3}>
                    <SnackbarErrorHandler />
                </SnackbarProvider>
            </React.Fragment>
        );
    }
}

export default withRouter(
    connect(
        (store) => {
            const { user, serverSettings } = store;
            return { ...user, ...serverSettings };
        },
        (dispatch) =>
            bindActionCreators({ ...serverSettingsActions, ...userActions }, dispatch)
    )(withTranslation()(App))
);
