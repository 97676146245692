import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { instance as DisplayInstance } from 'components/Display/Display.jsx';
import 'react-contexify/dist/ReactContexify.min.css';
import { Menu, Item, Separator, Submenu, theme, animation } from 'react-contexify';
import { contextMenu } from 'react-contexify';
import { Public, Info, Visibility, LocalOffer, Save, Timeline, GetApp, ZoomOutMap, TextFields, MultilineChart, AddCircle, RemoveCircle, Warning, FileCopy, Sync, Poll, Mouse, Repeat, DesktopWindows } from "@material-ui/icons";
import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/core/styles/withStyles";
import displayMenuStyle from "assets/jss/material-kit-react/views/componentsSections/displayMenuStyle.jsx";

export let instance = null;
class DisplayMenu extends React.Component {
    constructor(props) {
        super(props);

        instance = this;

        this.state = {
            filepath: undefined,
            selected: {
                type: '',
                uTags: undefined,
            }
        };
    }

    show = function (file, info, event) {
        this.setState({
            filepath: file,
            selected: info
        });
        contextMenu.show({
            id: 'custom_menu',
            event: event
        });
    }.bind(this);

    onNavigate3rdParty = function (url) {
        window.open(url, '_blank', 'noreferrer');
    }.bind(this);

    render() {

        const { t, classes } = this.props;


        let oipv = undefined;
        if (this.state.filepath) {
            oipv = <Item onClick={DisplayInstance.openInParcView}>
                <DesktopWindows className={classes.icons} />{t("OpeninPARCview")}
            </Item>
        }

        let items = null;
        switch (this.state.selected.type) {
            case "GridPanel":

                items =
                    <div>
                    <Submenu label={t("ApplyToALLTags")}>
                            <Item onClick={DisplayInstance.onToggleAutoScaleAll}>
                                <ZoomOutMap className={classes.icons} />{t("AutoScale")}
                            </Item>
                            <Item onClick={DisplayInstance.onToggleValueTextAll}>
                                <TextFields className={classes.icons} />{t("ShowValueText")}
                            </Item>
                            <Item onClick={DisplayInstance.onToggleShowDotsAll}>
                                <Timeline className={classes.icons} />{t("ShowDots")}
                            </Item>
                            <Item onClick={DisplayInstance.onToggleBadQualityAll}>
                                <Warning className={classes.icons} />{t("BadQuality")}
                            </Item>
                        </Submenu>

                        <Item onClick={DisplayInstance.onAddTag}>
                            <AddCircle className={classes.icons} />{t("AddTag")}
                        </Item>

                        <Item onClick={DisplayInstance.onRemoveTag}>
                            <RemoveCircle className={classes.icons} />{t("DeleteTag")}
                        </Item>

                        <Item onClick={DisplayInstance.onClone}>
                            <FileCopy className={classes.icons} />{t("CloneNoAmpersand")}
                        </Item>

                        <Separator />

                        <Item onClick={DisplayInstance.launchTagBrowser}>
                            <LocalOffer className={classes.icons} />{t("TagBrowser")}
                        </Item>

                        {oipv}

                        <Item onClick={DisplayInstance.onShowTagInfo}>
                            <Info className={classes.icons} /> {t("TagInfo")}
                        </Item>

                        <Item onClick={DisplayInstance.onExportData}>
                            <GetApp className={classes.icons} /> {t("ExportData")}
                        </Item>

                        <Item onClick={DisplayInstance.onSaveAs}>
                            <Save className={classes.icons} /> {t("SaveImage")}
                        </Item>
                    </div>

                break;
            case "WPFTrend":

                items =
                    <div>
                        <Item onClick={DisplayInstance.onSync}>
                            <Sync className={classes.icons} /> {t("Sync")}
                        </Item>

                        <Item onClick={DisplayInstance.onAutoSync}>
                        <Repeat className={classes.icons} /> {t("AutoSyncTrends")}
                        </Item>

                        <Item onClick={DisplayInstance.onToggleQuickStatistics}>
                            <Poll className={classes.icons} /> {t("QuickStatistics")}
                        </Item>

                        <Item onClick={DisplayInstance.onClone}>
                        <FileCopy className={classes.icons} /> {t("CloneNoAmpersand")}
                        </Item>

                        <Item onClick={DisplayInstance.onToggleSmartMouse}>
                            <Mouse className={classes.icons} /> {t("SmartMouse")}
                        </Item>

                        <Submenu label={t("NumberTrends")}>

                            <Grid className={classes.root} container spacing={0}>


                                <Item onClick={() => DisplayInstance.onEditTrendLayout(1, 1)}>1x1</Item>
                                <Item onClick={() => DisplayInstance.onEditTrendLayout(1, 2)}>1x2</Item>
                                <Item onClick={() => DisplayInstance.onEditTrendLayout(1, 3)}>1x3</Item>
                                <Item onClick={() => DisplayInstance.onEditTrendLayout(1, 4)}>1x4</Item>

                                <Item onClick={() => DisplayInstance.onEditTrendLayout(2, 1)}>2x1</Item>
                                <Item onClick={() => DisplayInstance.onEditTrendLayout(2, 2)}>2x2</Item>
                                <Item onClick={() => DisplayInstance.onEditTrendLayout(2, 3)}>2x3</Item>
                                <Item onClick={() => DisplayInstance.onEditTrendLayout(2, 4)}>2x4</Item>

                                <Item onClick={() => DisplayInstance.onEditTrendLayout(3, 1)}>3x1</Item>
                                <Item onClick={() => DisplayInstance.onEditTrendLayout(3, 2)}>3x2</Item>
                                <Item onClick={() => DisplayInstance.onEditTrendLayout(3, 3)}>3x3</Item>
                                <Item onClick={() => DisplayInstance.onEditTrendLayout(3, 4)}>3x4</Item>

                                <Item onClick={() => DisplayInstance.onEditTrendLayout(4, 1)}>4x1</Item>
                                <Item onClick={() => DisplayInstance.onEditTrendLayout(4, 2)}>4x2</Item>
                                <Item onClick={() => DisplayInstance.onEditTrendLayout(4, 3)}>4x3</Item>
                                <Item onClick={() => DisplayInstance.onEditTrendLayout(4, 4)}>4x4</Item>

                            </Grid>

                        </Submenu>

                        <Item onClick={DisplayInstance.onExportData}>
                            <GetApp className={classes.icons} /> {t("ExportData")}
                        </Item>

                        <Separator />

                        <Item onClick={DisplayInstance.launchTagBrowser}>
                            <LocalOffer className={classes.icons} />{t("TagBrowser")} ({t("Beta")})
                        </Item>

                        {/*{oipv}*/}

                        <Item onClick={DisplayInstance.onSaveAs}>
                            <Save className={classes.icons} /> {t("SaveImage")}
                        </Item>
                    </div>

                break;
            default: {

                if (this.state.selected.uTags) {

                    let additional = undefined;
                    const cap = this.state.selected.additionalTagData;
                    const url = this.state.selected.additionalUrlData;

                    if (cap) {
                        const tag = (!url) ? undefined : url + encodeURIComponent(this.state.selected.uTags[0].displayId);
                        additional =
                            <Item onClick={() => this.onNavigate3rdParty(tag)}>
                                <Public className={classes.icons} /> {cap}
                            </Item>
                    }

                    items =
                        <div>

                            {additional}

                            <Item onClick={DisplayInstance.onShowTagInfo}>
                                <Info className={classes.icons} /> {t("TagInfo")}
                            </Item>

                            <Item onClick={DisplayInstance.onToggleTagValue}>
                                <Visibility className={classes.icons} /> {t("TagValue")}
                            </Item>

                            <Item onClick={DisplayInstance.onClone}>
                                <MultilineChart className={classes.icons} /> {t("Trend")}
                            </Item>

                            <Separator />

                            <Item onClick={DisplayInstance.launchTagBrowser}>
                                <LocalOffer className={classes.icons} />{t("TagBrowser")}
                            </Item>

                            {oipv}

                            <Item onClick={DisplayInstance.onSaveAs}>
                                <Save className={classes.icons} /> {t("SaveImage")}
                            </Item>
                        </div>

                }
                else {

                    items =
                        <div>
                            {oipv}
                            <Item onClick={DisplayInstance.onSaveAs}>
                                <Save className={classes.icons} /> {t("SaveImage")}
                            </Item>
                        </div>
                }

                break;
            }
        }

        return (
            <Menu id='custom_menu' style={{ zIndex: 10 }} theme={theme.dark} animation={animation.pop}>
                {items}
            </Menu>
        );
    }
}

export default connect(state => state.user)(
    withStyles(displayMenuStyle)(withTranslation()(DisplayMenu))
);
