import { authHeader } from "helpers";
import { stringify } from "query-string";
import { fetchService } from "services/fetch.service";

const baseUrl = `${process.env.PUBLIC_URL}/api`;
export class tagService {
    constructor() { }

    async getLocations() {

        const requestOptions = {
            method: "Get",
            headers: await authHeader()
        };

        return fetchService
            .fetchWithErrorHandling(
                `${baseUrl}/tag/GetLocations`,
                requestOptions
            )
            .then(handleResponse);

        function handleResponse(response) {
            return response.text().then(text => {
                const data = text;
                if (!response.ok) {
                    const error = (data && data.message) || response.statusText;
                    return Promise.reject(error);
                }

                return data;
            });
        }
    }

    async getSources(location) {

        const params = {
            locationName: location,
        };

        const requestOptions = {
            method: "Get",
            headers: await authHeader()
        };

        return fetchService
            .fetchWithErrorHandling(
                `${baseUrl}/tag/GetSources?${stringify(params)}`,
                requestOptions
            )
            .then(handleResponse);

        function handleResponse(response) {
            return response.text().then(text => {
                const data = text;
                if (!response.ok) {
                    const error = (data && data.message) || response.statusText;
                    return Promise.reject(error);
                }

                return data;
            });
        }
    }

    async getTags(location, source, skip, take, term, useRegEx, id) {

        const params = {
            locationName: location,
            sourceName: source,
            skip: skip,
            take: take,
            searchTerm: term,
            useRegEx: useRegEx,
            id: id,
        };

        const requestOptions = {
            method: "Get",
            headers: await authHeader()
        };

        return fetchService
            .fetchWithErrorHandling(
                `${baseUrl}/tag/GetTags?${stringify(params)}`,
                requestOptions
            )
            .then(handleResponse);

        function handleResponse(response) {
            return response.text().then(text => {
                const data = text;
                if (!response.ok) {
                    const error = (data && data.message) || response.statusText;
                    return Promise.reject(error);
                }

                return data;
            });
        }
    }
}