
const tagBrowserSectionStyle = {
    selects: {
        padding: "2px 2px 5px 2px",
        alignContent: "center",
        textAlign: "left",
        minWidth: "150px",
        fontSize: "small",
        maxWidth: "150px",
    },
    sectionContainer: {
        fontSize: "small",
        width: "fit-content",
        alignContent: "center",
        borderStyle: "solid",
        borderWidth: " 0px 0px 0px 1px",
        display: "grid",
        alignContent: "center",
        minHeight: "102.64px",
        minWidth: "224.67px",
    },
    sectionContainerModified: {
        fontSize: "small",
        width: "fit-content",
        alignContent: "center",
        borderStyle: "solid",
        borderWidth: " 0px 0px 0px 1px",
        display: "grid",
        alignContent: "space-between",
        minHeight: "102.64px",
        minWidth: "224.67px",
    },
    sectionRow: {
        display: "flex",
        margin: "6px",
        justifyContent: "space-between",
        marginTop: "6px",
        marginBottom: "6px",
        marginLeft: "9px",
        marginRight: "9px",
    },
    sectionRowHidden: {
        display: "none",
    },
    sectionRowModified: {
        display: "flex",
        marginTop: "6px",
        marginBottom: "6px",
        marginLeft: "9px",
        marginRight: "9px",
    },
    sectionSpan: {
        marginRight: "4px",
        alignSelf: "center"
    },
    sectionHeader: {
        alignSelf: "center",
        textAlign: "center",
        margin: "4px",
        fontSize: "x-small",
        fontWeight: "bold"
    },
    sectionHeaderModified: {
        alignSelf: "center",
        textAlign: "center",
        margin: "20px",
        fontSize: "x-small",
        fontWeight: "bold"
    },
};

export default tagBrowserSectionStyle;
