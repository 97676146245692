
const tagGridStyle = {
    table: {
        //width: "100%",
        height: "calc(100% - 202px);",
        margin: "5px",
        overflowY: "auto",
        backgroundColor: "lightgray",        
        "& > div > table": {
            border: ".5px solid black",
            tableLayout: "auto",
        },
        "& > div > table > thead > tr > th": {
            border: ".5px solid black"
        },
        "& > div > table > tbody > tr > td": {
            border: ".5px solid gray"
        },
        "& > div > table > tbody > tr > td:nth-child(1)": {
            maxWidth:"150px"
        },
        "& > div > table > tbody > tr > td:nth-child(2)": {
            maxWidth: "150px"
        },
    },
    searchContainer: {
        display: "flex",
        justifyContent: "space-between",
        "& > label": {
            marginBottom: "2px"
        },
    },
    search: {
        marginTop: ".2rem",
        marginLeft:"6px",
        fontSize: "small",
        minWidth: "265px",
    },
    refresh: {
        padding: "0px !important",
        minWidth: "16px",
        minHeight: "16px",
        width: "30px",
        height: "30px",
        alignSelf: "center"
    },
    icons: {
        width: "18px",
        height: "18px"
    },
    progress: {
        position: "absolute",
        top: "68%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12
    }
};

export default tagGridStyle;
