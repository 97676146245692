import i18n from "i18next";
import Backend from "i18next-http-backend";
import Languagedetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";


i18n
    .use(Backend)
    .use(Languagedetector)
    .use(initReactI18next)
    .init({
        backend: {
            loadPath: "i18n/{{lng}}.json",
        },
        fallbackLng: 'en',
        debug: false,
        react: { useSuspense: false, wait: true },
        interpolation: {
            prefixEscaped: false,
            formatSeparator: ",",
        },

    });

export default i18n;