import React from "react";
//import withStyles from "@material-ui/core/styles/withStyles";
//import tagInfoStyle from "assets/jss/material-kit-react/views/tagInfo.jsx";
import { connect } from "react-redux";
import Button from '@material-ui/core/Button';
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import InputLabel from '@material-ui/core/InputLabel';
import { IconFont } from 'react-contexify';
import BootstrapTable from 'react-bootstrap-table-next';

class TagInfo extends React.Component {

    constructor(props) {
        super(props);
        this.uTagInfo = this.props.uTagInfo;

        this.alarms = [];
        if (this.uTagInfo.alarms) {
            this.uTagInfo.alarms.forEach(a => this.alarms.push({
                uTag: a.alarmUTag,
                inAlarm: a.inAlarm,
            }));
        }        
    };

    componentDidMount()
    {
        var s1 = document.querySelector('.react-bootstrap-table table').style;
        s1.tableLayout = "auto";
    }

    componentDidUpdate(prevProps, prevState, snapshot) { }

    onTrendAllClicked = function (e) {

        try {

            var tags = [
                this.uTagInfo,
                { uTag: this.uTagInfo.processUnit.gradeTag },
                { uTag: this.uTagInfo.processUnit.productTag },
                { uTag: this.uTagInfo.processUnit.filterTag },
                { uTag: this.uTagInfo.processUnit.maturityTag },
                { uTag: this.uTagInfo.processUnit.phaseTag },
            ];

            tags = tags.filter(function (el) {
                return el.uTag != undefined;
            });

            var json = JSON.stringify(tags);
            var escaped = escape(json);
            var base64 = btoa(escaped);
            var encoded = encodeURI(base64);

            window.open(`clone?tags=${encoded}`, '_blank', 'noreferrer');
        }
        catch (ex) {
            console.error(ex);
        }
        finally {
            this.setState({
                isBusy: false
            });
        }

    }.bind(this);

    render() {

        const gridContainerStyle = {
            display: "inline-grid",
            gridTemplateColumns: "auto auto"
        }
        const gridItemLabelStyle = {
            paddingRight: "5px",
            textAlign: "left"
        }
        const gridItemValueStyle = {
            paddingLeft: "5px",
            textAlign: "left"
        }
        const inputLabelStyle = {
            fontSize: "13px",
            fontWeight: "bold"
        }
        const inputValueStyle = {
            fontSize: "13px",
            fontWeight: "normal"
        }
        const sectionStyle = {
            margin: "5px",
        }
        const cardMainStyle = {
            height: "320px",
            minWidth: "300px",
        }
        const cardSubStyle = {
            height: "200px",
        }
        const iStyle = {
            fontSize: '14px',
        }
        const lowPad = {
            padding: "10px",
            width:"auto",
        }
        const buttonStyle = {
            marginTop: "15px"
        }
        const tableContainerStyle = {
            overflowY: "auto",
            height:"155px"
        };

        return (
            <React.Fragment>
                <div style={gridContainerStyle}>

                    <div style={sectionStyle}>

                        <Card style={cardMainStyle}>
                            <CardHeader>Tag Attributes</CardHeader>
                            <CardBody style={lowPad}>

                                <div style={gridContainerStyle}>

                                    <div style={gridItemLabelStyle}>
                                        <InputLabel style={inputLabelStyle}>Tag:</InputLabel>
                                    </div>
                                    <div style={gridItemValueStyle}>
                                        <InputLabel style={inputValueStyle}>{this.uTagInfo.uTag}</InputLabel>
                                    </div>


                                    <div style={gridItemLabelStyle}>
                                        <InputLabel style={inputLabelStyle}>Active Tag:</InputLabel>
                                    </div>
                                    <div style={gridItemValueStyle}>
                                        <InputLabel style={inputValueStyle}>{this.uTagInfo.activeUTag}</InputLabel>
                                    </div>


                                    <div style={gridItemLabelStyle}>
                                        <InputLabel style={inputLabelStyle}>Display ID:</InputLabel>
                                    </div>
                                    <div style={gridItemValueStyle}>
                                        <InputLabel style={inputValueStyle}>{this.uTagInfo.displayId}</InputLabel>
                                    </div>


                                    <div style={gridItemLabelStyle}>
                                        <InputLabel style={inputLabelStyle}>Description:</InputLabel>
                                    </div>
                                    <div style={gridItemValueStyle}>
                                        <InputLabel style={inputValueStyle}>{this.uTagInfo.description}</InputLabel>
                                    </div>


                                    <div style={gridItemLabelStyle}>
                                        <InputLabel style={inputLabelStyle}>Units:</InputLabel>
                                    </div>
                                    <div style={gridItemValueStyle}>
                                        <InputLabel style={inputValueStyle}>{this.uTagInfo.units}</InputLabel>
                                    </div>


                                    <div style={gridItemLabelStyle}>
                                        <InputLabel style={inputLabelStyle}>Plot Min:</InputLabel>
                                    </div>
                                    <div style={gridItemValueStyle}>
                                        <InputLabel style={inputValueStyle}>{this.uTagInfo.plotMin}</InputLabel>
                                    </div>


                                    <div style={gridItemLabelStyle}>
                                        <InputLabel style={inputLabelStyle}>Plot Max:</InputLabel>
                                    </div>
                                    <div style={gridItemValueStyle}>
                                        <InputLabel style={inputValueStyle}>{this.uTagInfo.plotMax}</InputLabel>
                                    </div>


                                    <div style={gridItemLabelStyle}>
                                        <InputLabel style={inputLabelStyle}>Interpolate Type:</InputLabel>
                                    </div>
                                    <div style={gridItemValueStyle}>
                                        <InputLabel style={inputValueStyle}>{this.uTagInfo.interpolateType}</InputLabel>
                                    </div>


                                    <div style={gridItemLabelStyle}>
                                        <InputLabel style={inputLabelStyle}>UTagId:</InputLabel>
                                    </div>
                                    <div style={gridItemValueStyle}>
                                        <InputLabel style={inputValueStyle}>{this.uTagInfo.uTagId}</InputLabel>
                                    </div>


                                    <div style={gridItemLabelStyle}>
                                        <InputLabel style={inputLabelStyle}>Loop:</InputLabel>
                                    </div>
                                    <div style={gridItemValueStyle}>
                                        <InputLabel style={inputValueStyle}>{this.uTagInfo.loop}</InputLabel>
                                    </div>


                                    <div style={gridItemLabelStyle}>
                                        <InputLabel style={inputLabelStyle}>External ID:</InputLabel>
                                    </div>
                                    <div style={gridItemValueStyle}>
                                        <InputLabel style={inputValueStyle}>{this.uTagInfo.externalId}</InputLabel>
                                    </div>

                                </div>

                            </CardBody>
                        </Card>

                        <Card style={cardSubStyle}>
                            <CardHeader>Source Info</CardHeader>
                            <CardBody style={lowPad}>

                                <div style={gridContainerStyle}>

                                    <div style={gridItemLabelStyle}>
                                        <InputLabel style={inputLabelStyle}>Source:</InputLabel>
                                    </div>
                                    <div style={gridItemValueStyle}>
                                        <InputLabel style={inputValueStyle}>{this.uTagInfo.sourceInfo.source}</InputLabel>
                                    </div>


                                    <div style={gridItemLabelStyle}>
                                        <InputLabel style={inputLabelStyle}>Connection Type:</InputLabel>
                                    </div>
                                    <div style={gridItemValueStyle}>
                                        <InputLabel style={inputValueStyle}>{this.uTagInfo.sourceInfo.connectionType}</InputLabel>
                                    </div>


                                    <div style={gridItemLabelStyle}>
                                        <InputLabel style={inputLabelStyle}>Source URI:</InputLabel>
                                    </div>
                                    <div style={gridItemValueStyle}>
                                        <InputLabel style={inputValueStyle}>{this.uTagInfo.sourceInfo.uri}</InputLabel>
                                    </div>


                                    <div style={gridItemLabelStyle}>
                                        <InputLabel style={inputLabelStyle}>Source Type:</InputLabel>
                                    </div>
                                    <div style={gridItemValueStyle}>
                                        <InputLabel style={inputValueStyle}>{this.uTagInfo.sourceInfo.type}</InputLabel>
                                    </div>


                                    <div style={gridItemLabelStyle}>
                                        <InputLabel style={inputLabelStyle}>Source Time Zone:</InputLabel>
                                    </div>
                                    <div style={gridItemValueStyle}>
                                        <InputLabel style={inputValueStyle}>{this.uTagInfo.sourceInfo.timeZone}</InputLabel>
                                    </div>


                                    <div style={gridItemLabelStyle}>
                                        <InputLabel style={inputLabelStyle}>Historian Time Zone:</InputLabel>
                                    </div>
                                    <div style={gridItemValueStyle}>
                                        <InputLabel style={inputValueStyle}>{this.uTagInfo.sourceInfo.historianTimeZone}</InputLabel>
                                    </div>

                                </div>

                            </CardBody>
                        </Card>

                    </div>

                    <div style={sectionStyle}>

                        <Card style={cardMainStyle}>
                            <CardHeader>Process</CardHeader>
                            <CardBody style={lowPad}>
                                <div style={gridContainerStyle}>

                                    <div style={gridItemLabelStyle}>
                                        <InputLabel style={inputLabelStyle}>Process:</InputLabel>
                                    </div>
                                    <div style={gridItemValueStyle}>
                                        <InputLabel style={inputValueStyle}>{this.uTagInfo.processUnit.processName}</InputLabel>
                                    </div>


                                    <div style={gridItemLabelStyle}>
                                        <InputLabel style={inputLabelStyle}>Grade Tag:</InputLabel>
                                    </div>
                                    <div style={gridItemValueStyle}>
                                        <InputLabel style={inputValueStyle}>{this.uTagInfo.processUnit.gradeTag}</InputLabel>
                                    </div>


                                    <div style={gridItemLabelStyle}>
                                        <InputLabel style={inputLabelStyle}>Product Tag:</InputLabel>
                                    </div>
                                    <div style={gridItemValueStyle}>
                                        <InputLabel style={inputValueStyle}>{this.uTagInfo.processUnit.productTag}</InputLabel>
                                    </div>


                                    <div style={gridItemLabelStyle}>
                                        <InputLabel style={inputLabelStyle}>Filter Tag:</InputLabel>
                                    </div>
                                    <div style={gridItemValueStyle}>
                                        <InputLabel style={inputValueStyle}>{this.uTagInfo.processUnit.filterTag}</InputLabel>
                                    </div>


                                    <div style={gridItemLabelStyle}>
                                        <InputLabel style={inputLabelStyle}>Maturity Tag:</InputLabel>
                                    </div>
                                    <div style={gridItemValueStyle}>
                                        <InputLabel style={inputValueStyle}>{this.uTagInfo.processUnit.maturityTag}</InputLabel>
                                    </div>


                                    <div style={gridItemLabelStyle}>
                                        <InputLabel style={inputLabelStyle}>Phase Tag:</InputLabel>
                                    </div>
                                    <div style={gridItemValueStyle}>
                                        <InputLabel style={inputValueStyle}>{this.uTagInfo.processUnit.phaseTag}</InputLabel>
                                    </div>


                                    <div style={gridItemLabelStyle}>
                                        <InputLabel style={inputLabelStyle}>Grade Type:</InputLabel>
                                    </div>
                                    <div style={gridItemValueStyle}>
                                        <InputLabel style={inputValueStyle}>{this.uTagInfo.processUnit.gradeType}</InputLabel>
                                    </div>


                                    <div style={gridItemLabelStyle}>
                                        <InputLabel style={inputLabelStyle}>Current Grade:</InputLabel>
                                    </div>
                                    <div style={gridItemValueStyle}>
                                        <InputLabel style={inputValueStyle}>{this.uTagInfo.processUnit.currentGrade}</InputLabel>
                                    </div>

                                    <Button size="small" variant="outlined" color="primary" style={buttonStyle} onClick={this.onTrendAllClicked}>
                                        <IconFont style={iStyle} className="fa fa-line-chart" />Trend All
                                    </Button>

                                </div>
                            </CardBody>
                        </Card>

                        <Card style={cardSubStyle}>
                            <CardHeader>Current Alarms</CardHeader>
                            <CardBody style={lowPad}>

                                <div style={tableContainerStyle}>

                                    <BootstrapTable
                                        bootstrap4
                                        keyField='uTag'
                                        style={{ width: "10px", height: "10px" }}
                                        data={this.alarms}
                                        selectRow={{ mode: 'checkbox', clickToSelect: true, hideSelectColumn: true, bgColor: '#00BFFF' }}
                                        columns={[{ dataField: 'uTag', text: 'Alarm Tag' }]}
                                    />

                                </div>

                            </CardBody>
                        </Card>

                    </div>

                </div>
            </React.Fragment>
        );
    }
}
export default connect(state => state.user)((TagInfo));

//export default connect(state => state.user)(
//    withStyles(tagInfoStyle)(TagInfo)
//);