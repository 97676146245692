
const tagBrowserPageStyle = {
    root: {
        width: "100vw",
        height: "100vh",
        position: "absolute",
        backgroundColor: "transparent",
        /*backgroundImage: "url(/static/media/Splash_Background_Picture.afcb44a4.png)",*/
        "& > nav > a": {
            backgroundColor: "Gainsboro !important",
            color: "black !important",
            fontWeight: "normal",
        },
        "& > nav > a.active": {
            backgroundColor: "white !important",
            color: "black !important",
            fontWeight: "bold",
        },
        "& > nav > a.disabled": {
            backgroundColor: "lightgray !important",
            color: "gray !important",
        },
        "& > nav": {
            marginBottom: "0.5rem !important",
            backgroundColor: "gray !important",
            fontSize: "small",
        },
        "& > div": {
            width: "100%",
            //height: "fit-content",
        }
    },
    tabContent: {
        width: "100%",
        height: "100%",
        paddingLeft: "0.5rem !important",
        paddingRight: "0.5rem !important",
        //paddingBottom: "10.6rem !important",
    },
    selects: {
        padding: "2px 2px 5px 2px",
        alignContent: "center",
        textAlign: "left",
        minWidth: "150px",
        fontSize: "small"
    },
    section: {
        fontSize: "small",
        width: "fit-content",
        alignContent: "center",
        borderStyle: "solid",
        borderWidth: " 0px 1px 1px 0px",
        display: "flex",
        alignContent: "center",
        justifyContent: "space-evenly",
        marginBottom: "4px",
    },
    contentArea: {
        //width: "100%",
        height: "100% !important",
    },
    drag: {
        position: "absolute",
        padding: "2px",
        top: "-1000px",
        fontSize: "12px",
        color: "black",
        width: "auto !important",
        height: "auto !important",
        background: "white",
        borderRadius: "3px"
    }
};

export default tagBrowserPageStyle;
