import { container } from "assets/jss/material-kit-react.jsx";

const displayListPageStyle = {
    container: {
        ...container,
        zIndex: "2",
        position: "relative",

        "@media (min-width: 960px)": {
            paddingTop: "90px"
        },
        "@media (max-width: 960px)": {
            paddingLeft: "0",
            paddingRight: "0",
            marginLeft: "0",
            marginRight: "0",
            paddingTop: "0",
            height: "100%",
            width: "100%",
            maxWidth: "100% !important"
        },

        color: "#FFFFFF"
    },
    displayContainer: {
        ...container,
        zIndex: "2",
        position: "relative",


        paddingLeft: "0",
        paddingRight: "0",
        marginLeft: "0",
        marginRight: "0",
        paddingTop: "0",
        height: "100%",
        width: "100%",
        maxWidth: "100% !important",


        color: "#FFFFFF"
    },
    cardHidden: {
        opacity: "0",
        transform: "translate3d(0, -60px, 0)"
    },
    pageHeader: {
        minHeight: "100vh",
        height: "auto",
        display: "inherit",
        position: "relative",
        margin: "0",
        padding: "0",
        border: "0",
        alignItems: "center",
        "&:before": {
            background: "rgba(0, 0, 0, 0.5)"
        },
        "&:before,&:after": {
            position: "absolute",
            zIndex: "1",
            width: "100%",
            height: "100%",
            display: "block",
            left: "0",
            top: "0",
            content: '""'
        },
        "& footer li a,& footer li a:hover,& footer li a:active": {
            color: "#FFFFFF"
        },
        "& footer": {
            position: "absolute",
            bottom: "0",
            width: "100%"
        }
    },
    form: {
        margin: "0"
    },
    cardHeader: {
        width: "auto",
        textAlign: "center",
        marginLeft: "20px",
        marginRight: "20px",
        marginTop: "-40px",
        padding: "20px 0",
        marginBottom: "15px"
    },
    socialIcons: {
        maxWidth: "24px",
        marginTop: "0",
        width: "100%",
        transform: "none",
        left: "0",
        top: "0",
        height: "100%",
        lineHeight: "41px",
        fontSize: "20px"
    },
    divider: {
        marginTop: "30px",
        marginBottom: "0px",
        textAlign: "center"
    },
    cardFooter: {
        paddingTop: "0rem",
        border: "0",
        borderRadius: "6px",
        justifyContent: "center !important"
    },
    socialLine: {
        marginTop: "1rem",
        textAlign: "center",
        padding: "0"
    },
    inputIconsColor: {
        color: "#495057"
    },

    displayCanvas: {
        width: "100%",
        cursor: "default",
        margin: "0"
    },

    html: {

        height: "100%",
        width: "100vw"

    },
    body: {

        height: "100%",
        width: "100vw",
        overflow: "hidden"

    },
    cardBody: {
        "@media (max-width: 960px)": {
            height: "100%",
            width: "100%",
            padding: "0 0 0 0",
            marginBottom: "0"
        }
    },

    menuList: {
        "& div > div > span > div > div > img": {
            marginTop: "0px !important",
            marginLeft: "10px !important"
        },
        "& div > div > span > div > div": {
            paddingTop: "5px !important",
            paddingBottom: "5px !important",
            paddingLeft: "50px !important"
        },
        padding: "0px 0px !important",
        "& div > div > span > div > div > span": {
            fontFamily: "Segoe UI",
            fontSize: "14px",
            fontWeight: "600"
        },
        "@media(max-width: 960px)": {
            maxHeight: "calc(100vh - 85px)"
        },
        "@media(min-width: 960px)": {
            maxHeight: "calc(100vh - 155px)"
        },
        overflow:"auto"


    },
    selectableMenuList: {
        overflow:"hidden"
    },

    card: {
        "@media (max-width: 960px)": {
            marginTop: "5",
            marginBottom: "0"
        }
    },
    displayProgress: {
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12
    }
};

export default displayListPageStyle;
