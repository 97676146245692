import React from "react";

import withStyles from "@material-ui/core/styles/withStyles";

import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";

import Button from "components/CustomButtons/Button.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";

import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";

import loginPageStyle from "assets/jss/material-kit-react/views/loginPage.jsx";

import { userService } from "services/user.service";

class LogoutPage extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden",
      inProgress: false
    };
    this.children = props.children;
    this.handleLogout = this.handleLogout.bind(this);
  }

  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    setTimeout(
      function() {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );
  }

  handleLogout() {
    this.setState({ inProgress: true });
    userService.logout(this).then(() => this.setState({ inProgress: false }));
  }

  render() {
    const { classes, ...rest } = this.props;
    return (
      <GridItem xs={12} sm={12} md={4}>
        <Card className={classes[this.state.cardAnimaton]}>
          <form className={classes.form}>
            <CardHeader color="primary">
              {this.props.user
                ? this.props.user.name.includes("|")
                  ? this.props.user.name.split("|").pop()
                  : this.props.user.name
                : ""}
            </CardHeader>
            <CardBody>
              <div style={{ textAlign: "center" }}>
                {" "}
                <Button
                  simple
                  color="primary"
                  size="lg"
                  onClick={this.handleLogout}
                  disabled={this.inProgress}
                >
                  Logout
                </Button>
              </div>
            </CardBody>

            {this.state.inProgress && (
              <CircularProgress className={classes.displayProgress} />
            )}
          </form>
        </Card>
      </GridItem>
    );
  }
}

export default connect(store => store.user)(
  withStyles(loginPageStyle)(LogoutPage)
);
